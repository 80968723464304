import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './inboxtab.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';



function InBoxTab() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (

        <div className={styles.tableRow}>
      
            <table id="events" class="events-table" cellspacing="0" width="100%">
                <tbody>
                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>
                        <td className={styles.from}>Dave Brock</td>
                        <td className={styles.message}>Hello  </td>
                        <td className={styles.date}>12 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>
                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>
                        <td className={styles.name}><strong><p>Nik Turner</p></strong></td>
                        <td className={styles.message}><strong><p>Message</p></strong></td>
                        <td className={styles.date}><strong><p>10 Apr 2024</p></strong></td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>
                        <td class="from">Huw Lloyd-Langton</td>
                        <td class="message">Message</td>
                        <td class="date">9 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>
                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>
                        <td class="from">Simon House</td>
                        <td class="message">Message</td>
                        <td class="date">8 Apr 2024</td>
                    </tr>

                    <tr>
                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from"><strong><p>Paul Rudolph</p></strong></td>
                        <td class="message"><strong><p>Message</p></strong></td>
                        <td class="date"><strong><p>7 Apri 2024</p></strong></td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Keith Hale</td>
                        <td class="message">Message</td>
                        <td class="date">6 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td className={styles.name}><strong>Harvey Bainbridge</strong></td>
                        <td className={styles.message}><strong>Message</strong></td>
                        <td class="date"><strong>6 Apr 2024</strong></td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td className={styles.name}>Robert Calvert</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>5 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td className={styles.name}>Del Dettmar</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>4 Apr 2024</td>


                    </tr>







                </tbody>
            </table>
        </div>
    );
}

export default InBoxTab;
