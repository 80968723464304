// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyDF5KnrCDvn_hLfP09x2KeLTEJwSwq7IHE",
//     authDomain: "dover-tas-portal.firebaseapp.com",
//     projectId: "dover-tas-portal",
//     storageBucket: "dover-tas-portal.appspot.com",
//     messagingSenderId: "734831057327",
//     appId: "1:734831057327:web:fd0b5b94b0d8941a93375a",
//     measurementId: "G-4DKK571QM8"
//   };

//   const app = initializeApp(firebaseConfig);

//   export const auth = getAuth(app);
//   export const firestore = getFirestore(app);


import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { SiteConfig } from './site-config';

const firebaseConfig = SiteConfig.currentPreset.firebaseConfig;
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);