import { configureStore, createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    selectedMenuItem: '',
  },
  reducers: {
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },
  },
});

export const { setSelectedMenuItem } = menuSlice.actions;

const store = configureStore({
  reducer: {
    menu: menuSlice.reducer,
  },
});

export default store;