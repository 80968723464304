import React, { useEffect, useState } from 'react';
import { collection, doc, deleteDoc, addDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import styles from './scenarios-panel.module.css';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Modal from './add-scenario-modal.js';

function ScenariosPanel({ setScenarioId, scenarioId, setScenario, setIsScenarioRoadBlocksOpen, setIsAddScenarioOpen, setIsEditScenarioOpen, siteConfig }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scenarios, setScenarios] = useState([]);
    const [description, setDescription] = useState('');

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const selectScenario = (scenario) => {
        setScenario(scenario);
        setScenarioId(scenario.id);
    }

    const addScenario = () => {
        const newScenario = {
            description: description
        }

        const accidentRef = collection(firestore, 'scenarios');
        addDoc(accidentRef, newScenario);
    }

    const deleteScenario = async (id) => {
        const scenarioRef = doc(firestore, 'scenarios', id);
        await deleteDoc(scenarioRef);
    }

    const handleMoreClick = (scenario) => {
        setIsScenarioRoadBlocksOpen(true); 
    }

   

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(firestore, 'scenarios'), (snapshot) => {
            const dbScenarios = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setScenarios(dbScenarios);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className={styles.scenariosListContainer}>
            <h1>{siteConfig.currentPreset.features.tas.nameText} Scenarios</h1>

            

            <row onClick={() => setIsAddScenarioOpen(true)} className={styles.addScenario}>
                <AddCircleOutlineOutlinedIcon />
                <p>Add scenario</p>
            </row>
            {/* <row>

            <row>
                DEMO GOES HERE
            </row>
            <row>
                <column>
                    <label htmlFor="description">Enter description</label>
                    <input
                        type="text"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </column>
            </row>
            <button onClick={addScenario} className={styles.addButton}>
                Add Scenario
            </button> */}
            <ul className={styles.scenariosContainer}>
                {scenarios.map((scenario) => (
                    <li key={scenario.id} className={`${styles.scenarioRow} ${scenario.id === scenarioId ? styles.selectedScenario : ''}`} onClick={() => selectScenario(scenario)}>
                        
                        <div className={styles.scenarioContent}>
                            {scenario.name}
                        </div>
                        <DeleteOutlineOutlinedIcon className={styles.scenarioIcon} onClick={() => deleteScenario(scenario.id)} />
                        <EditIcon className={styles.scenarioIcon} onClick={() => setIsEditScenarioOpen(true)} /> 
                        <MoreVertOutlinedIcon className={styles.scenarioIcon} onClick={() => setIsScenarioRoadBlocksOpen(true)} /> 
                    </li>
                ))}
            </ul>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
              
            </Modal>
        </div>
    );
}

export { ScenariosPanel };
