import L from 'leaflet';
import serviceIconUrl from './truck_old.png';

const TruckIcon = new L.Icon({
  iconUrl: serviceIconUrl,
  iconRetinaUrl: serviceIconUrl, 
  iconAnchor: [16, 40],
  popupAnchor: [0, -36],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(25, 40),
  className: 'leaflet-div-icon truckicon',
});

export { TruckIcon };


