import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ivs2.module.css";
import { firestore } from "../../../firesbase.js";
import { collection, getDocs } from "firebase/firestore";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function Matrix2({ isOpen, onClose, callback, initialSelectedCells = [] }) {
  const [scenarios, setScenarios] = useState([]);
  const [adjList, setAdjList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getScenarios();
    }
  }, [isOpen]);

  useEffect(() => {
    if (scenarios.length > 0) {
    
      const updatedAdjList = Array(scenarios.length).fill().map(() => Array(scenarios.length).fill(false));
      initialSelectedCells.forEach(cellName => {
        const [rowLabel, colLabel] = cellName.split(' to ');
        const rowIndex = scenarios.findIndex(scenario => scenario.data.name === rowLabel);
        const colIndex = scenarios.findIndex(scenario => scenario.data.name === colLabel);
        if (rowIndex !== -1 && colIndex !== -1) {
          updatedAdjList[rowIndex][colIndex] = true;
        }
      });
      setAdjList(updatedAdjList);
    }
  }, [scenarios, initialSelectedCells]);

  const getScenarios = async () => {
    const querySnapshot = await getDocs(collection(firestore, "scenarios"));
    const data = [];
    querySnapshot.forEach((doc) =>
      data.push({
        id: doc.id,
        data: doc.data(),
      })
    );
    setScenarios(data);
    // Initialize adjList to a matrix of false values
    setAdjList(Array(data.length).fill().map(() => Array(data.length).fill(false)));
  };

  const handleClose = () => {
    onClose();
  };


  const getCellName = (i, j) => `${scenarios[i]?.data?.name} to ${scenarios[j]?.data?.name}`;

  const handleCellSelection = (i, j) => {
    if (adjList[i] && adjList[i][j] !== undefined) {
      const newList = adjList.map((row, rowIndex) =>
        row.map((cell, cellIndex) =>
          rowIndex === i && cellIndex === j ? !cell : cell
        )
      );

      setAdjList(newList);

      // Generate selected cells list with descriptive names
      const selectedCells = newList.flatMap((row, rowIndex) =>
        row.map((cell, cellIndex) =>
          cell ? getCellName(rowIndex, cellIndex) : null
        )
      ).filter(Boolean);

      if (typeof callback === "function") {
        callback(selectedCells);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Select transition
          <CloseOutlinedIcon onClick={onClose} className={styles.closeIcon} />
        </settings-row>
        <div
          className={styles.matrix}
          style={{
            gridTemplateColumns: `repeat(${scenarios.length + 1}, 1fr)`,
            width: "100%",
          }}
        >
          <div className={styles.empty}></div>

          {scenarios.map((obj) => (
            <div key={obj.id} className={styles.matrixHeading}>
              {obj?.data?.name}
            </div>
          ))}

          {scenarios.map((obj, i) => (
            <React.Fragment key={i}>
              <div className={styles.matrixHeading}>{obj?.data?.name}</div>
              {scenarios.map((_, j) =>
                i === j ? (
                  <div key={j} className={styles.empty}></div>
                ) : (
                  <label key={j} className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={adjList[i][j] || false}
                      onChange={() => handleCellSelection(i, j)}
                    />
                    <span
                      className="checkbox-checkmark"
                      style={{ position: "relative" }}
                    ></span>
                  </label>
                )
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

Matrix2.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  initialSelectedCells: PropTypes.array,
};

export default Matrix2;
