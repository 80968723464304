import React, { useState } from 'react';
import styles from './starredtab.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';


function StarredTab() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (




        <div className={styles.tableRow}>

            <table id="events" class="events-table" cellspacing="0" width="100%">

                <tbody>
                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>

                        <td className={styles.name}>Dave Brock</td>
                        <td clasName={styles.message}>Hello - </td>
                        <td classname={styles.date}>12 Apr 2024</td>


                    </tr>

                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>
                        <td className={styles.name}>Nik Turner</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>10 Apr 2024</td>


                    </tr>
                    <tr>

                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>

                        <td class="from">Huw Lloyd-Langton</td>
                        <td class="message">Message</td>
                        <td class="date">9 Apr 2024</td>


                    </tr>
                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>

                        <td class="from">Simon House</td>
                        <td class="message">Message</td>
                        <td class="date">8 Apr 2024</td>


                    </tr>

                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>

                        <td class="from">Paul Rudolph</td>
                        <td class="message">Message</td>
                        <td class="date">7 Apr 2024</td>


                    </tr>
                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />

                            </div>

                        </td>

                        <td class="from">Keith Hale</td>
                        <td class="message">Message</td>
                        <td class="date">6 Apr 2024</td>


                    </tr>
                    <tr>


                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />
                            </div>
                        </td>
                        <td class="from">Harvey Bainbridge</td>
                        <td class="message">Message</td>
                        <td class="date">6 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>
                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />
                            </div>
                        </td>
                        <td class="from">Robert Calvert</td>
                        <td class="message">Message</td>
                        <td class="date">5 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarIcon />
                            </div>
                        </td>
                        <td class="from">Del Dettmar</td>
                        <td class="message">Message</td>
                        <td class="date">4 Apr 2024</td>
                    </tr>
                </tbody>
            </table>
        </div>

    );
}

export default StarredTab;
