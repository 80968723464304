import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
// import "lrm-graphhopper";
import { TruckIcon } from '../map-icons/truck-icon.js';
import { GlobalVariables } from '../../utils/global-variables.js';

// const createRoutineMachineLayer = ({waypoints, lineOptions, markerOptions}) => {
//   const instance = L.Routing.control({
//     waypoints: waypoints.map((waypoint) => L.latLng(waypoint.lat, waypoint.lng)),
//     lineOptions: {
//       styles: [{ color: "#6FA1EC", weight: 4 }],
//       ...lineOptions,
//     },
//     show: false,
//     addWaypoints: false,
//     routeWhileDragging: true,
//     draggableWaypoints: true,
//     fitSelectedRoutes: true,
//     showAlternatives: false
//   });

//   return instance;
// };

// const RoutingControl = createControlComponent(createRoutineMachineLayer);

// export default RoutingControl;
class RoutingMachine extends L.Routing.Control {
    createMarker(i, waypoint, n) {
        const marker = L.marker(waypoint.latLng, {
          draggable: true,
          bounceOnAdd: false,
          bounceOnAddOptions: {
            duration: 1000,
            height: 800,
          },
          icon: TruckIcon,
        });
    
        marker.bindPopup(`
          <div>
            <p>Marker ${i}</p>
            <button class="delete-marker-button" data-marker-index="${i}">Delete</button>
          </div>
        `);
    
        marker.on('popupopen', () => {
          const deleteButton = marker.getPopup().getElement().querySelector('.delete-marker-button');
          deleteButton.addEventListener('click', () => {
            this.spliceWaypoints(i, 1);
          });
        });
    
        return marker;
      }
    
}

class RoutingControl extends L.Control {
    constructor(props) {
        super(props);
        this.props = props;
        this.routingMachine = null;
    }

    createRoutingMachineOptions() {
        return {
            waypoints: this.props.waypoints || [],
            lineOptions: this.props.lineOptions || {
                styles: [{ color: "#6FA1EC", weight: 4 }],
            },
            show: this.props.show !== undefined ? this.props.show : false,
            addWaypoints: this.props.addWaypoints !== undefined ? this.props.addWaypoints : false,
            routeWhileDragging: this.props.routeWhileDragging !== undefined ? this.props.routeWhileDragging : true,
            draggableWaypoints: this.props.draggableWaypoints !== undefined ? this.props.draggableWaypoints : true,
            fitSelectedRoutes: this.props.fitSelectedRoutes !== undefined ? this.props.fitSelectedRoutes : true,
            showAlternatives: this.props.showAlternatives !== undefined ? this.props.showAlternatives : false,
            addWaypoints: this.props.addWaypoints !== undefined ? this.props.addWaypoints : true,
            createMarker: (i, waypoint, n) => this.routingMachine.createMarker(i, waypoint, n),
            // serviceUrl: GlobalVariables.routeServerBaseUrl
            // router: L.Routing.graphHopper('', {
            //     serviceUrl: GlobalVariables.routeServerBaseUrl || 'http://localhost:8989/route',
            //     urlParameters: {
            //       vehicle: 'car',
            //     //   algorithm: 'alternative_route',
            //     //   max_paths: 3,
            //     //   weighting: 'shortest',
            //     //   ...this.props.urlParameters,
            //     },
            //   }),
        
        };
    }

    onAdd(map) {
        if (!this.routingMachine) {
            const options = this.createRoutingMachineOptions();
            this.routingMachine = new RoutingMachine(options);
        }
        return this.routingMachine.onAdd(map);
    }

    onRemove(map) {
        if (this.routingMachine) {
            this.routingMachine.onRemove(map);
        }
    }

    setWaypoints(waypoints) {
        if (this.routingMachine) {
            this.routingMachine.setWaypoints(waypoints);
        }
    }

    getWaypoints() {
      if (this.routingMachine) {
        return this.routingMachine.getWaypoints();
      }
      return [];
    }

    addWaypoint(waypoint, index) {
        if (this.routingMachine) {
            this.routingMachine.spliceWaypoints(index, 0, waypoint);
        }
    }

    getRoutePoints() {
        if (this.routingMachine && this.routingMachine._selectedRoute) {
          const coordinates = this.routingMachine._selectedRoute.coordinates;
          return coordinates.map(coord => ({ lat: coord.lat, lng: coord.lng }));
        }
        return [];
      }
    
}

const createRoutingControl = (props) => {
    return new RoutingControl(props);
};

export default createControlComponent(createRoutingControl);
