import { auth } from '../firesbase';


class ScheduleService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async schedule(latitude, longitude, destinationId, isDummy, journeySettings) {
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`${this.baseUrl}/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify({
                    currentLocation: {
                        latitude: latitude,
                        longitude: longitude,
                    },
                    destinationId: destinationId,
                    isDummy: isDummy,
                    hazardousLoad: journeySettings.hazardousLoad,
                    perishableLoad: journeySettings.perishableLoad,
                    wideOrLongLoad: journeySettings.wideOrLongLoad,
                    registrationNumber: journeySettings.registrationNumber,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to schedule task: ${await response.text()}`);
            }

            const jsonData = await response.json();
            return {
                ...jsonData.schedule,
                id: jsonData.schedule.id,
            };
        } catch (e) {
            console.error('Error scheduling task:', e);
            throw e;
        }
    }

    async getScheduleState(latitude, longitude, destinationId, isDummy, journeySettings) {
        try {
            const idToken = await auth.currentUser.getIdToken();

            // Get the local timezone offset in minutes
            const timezoneOffset = new Date().getTimezoneOffset();

            const response = await fetch(`${this.baseUrl}/scheduleState`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify({ timezoneOffset }),
            });

            if (!response.ok) {
                throw new Error(`Failed to get schedule state: ${await response.text()}`);
            }

            const jsonData = await response.json();

            // Transform the scheduleState object into an array
            const scheduleStateArray = Object.entries(jsonData.scheduleState).map(([key, value]) => ({
                id: key,
                ...value
            }));

            return scheduleStateArray;
        } catch (e) {
            console.error('Error getting schedule state:', e);
            throw e;
        }
    }


    // async getScheduleState(latitude, longitude, destinationId, isDummy, journeySettings) {
    //     try {
    //         const idToken = await auth.currentUser.getIdToken();

    //         const response = await fetch(`${this.baseUrl}/scheduleState`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': idToken,
    //             },
    //             body: JSON.stringify({}),
    //         });

    //         if (!response.ok) {
    //             throw new Error(`Failed to schedule task: ${await response.text()}`);
    //         }

    //         const jsonData = await response.json();

    //         // Transform the scheduleState object into an array
    //         const scheduleStateArray = Object.entries(jsonData.scheduleState).map(([key, value]) => ({
    //             id: key,
    //             ...value
    //         }));

    //         return scheduleStateArray;

    //         // return {
    //         //     ...jsonData.scheduleState
    //         // };
    //     } catch (e) {
    //         console.error('Error scheduling task:', e);
    //         throw e;
    //     }
    // }

    async resetSchedule() {
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`${this.baseUrl}/resetschedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                throw new Error(`Failed to schedule task: ${await response.text()}`);
            }

            // const jsonData = await response.json();

            // // Transform the scheduleState object into an array
            // const scheduleStateArray = Object.entries(jsonData.scheduleState).map(([key, value]) => ({
            //     id: key,
            //     ...value
            // }));

            return true;

            // return {
            //     ...jsonData.scheduleState
            // };
        } catch (e) {
            console.error('Error scheduling task:', e);
            throw e;
        }
    }

    resetschedule


    // async fetchRoute(currentLocation, portalPoint) {
    //   const requestBody = {
    //     currentLocation: {
    //         longitude: currentLocation.longitude,
    //         latitude: currentLocation.latitude
    //     },
    //     'destinationId': journeySettings.destinationId,
    //     'hazardousLoad': journeySettings.hazardousLoad,
    //     'perishableLoad': journeySettings.perishableLoad,
    //     'wideOrLongLoad': journeySettings.wideOrLongLoad,
    //     'registrationNumber': journeySettings.registrationNumber,
    //   };

    //   try {
    //     const response = await fetch(`${this.baseUrl}/route`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify(requestBody)
    //     });

    //     if (!response.ok) {
    //       return null;
    //       // throw new Error('Network response was not ok');
    //     }

    //     const data = await response.json();
    //     return data.paths[0]; // Assuming the API returns a list of paths and we take the first one
    //   } catch (error) {
    //     console.error('Failed to fetch route:', error);
    //     return null;
    //     // throw error;
    //   }
    // }

}

export { ScheduleService };