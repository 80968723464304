// import L from 'leaflet';
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import ReactDOMServer from 'react-dom/server';


// // Function to convert MUI icon to data URL
// function muiIconToDataURL(icon) {
//   const svgString = ReactDOMServer.renderToStaticMarkup(icon);
//   const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
//   const url = URL.createObjectURL(svgBlob);
//   return url;
// }

// // Create the Leaflet icon instance using the data URL
// const ServiceIcon = new L.Icon({
//   iconUrl: muiIconToDataURL(<LocalShippingOutlinedIcon />),
//   iconRetinaUrl: muiIconToDataURL(<LocalShippingOutlinedIcon />),
//   iconAnchor: null,
//   popupAnchor: [-0, -0],
//   shadowUrl: null,
//   shadowSize: null,
//   shadowAnchor: null,
//   iconSize: new L.Point(32, 32),
//   className: 'leaflet-div-icon'
// });

// export { ServiceIcon };


import L from 'leaflet';
import serviceIconUrl from './truck-stop.svg';



const ServiceIcon = new L.Icon({
  iconUrl: serviceIconUrl,
  iconRetinaUrl: serviceIconUrl, 
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(28, 28),
//   className: 'leaflet-div-icon',
className: 'leaflet-div-icon'

});

export { ServiceIcon };

