import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InAppMessagesContentTab from "./contenttab/contenttab.js";
import InAppMessagesRowsTab from "./rowstab/rowstab.js";
import ContentArea from "./ContentArea";
// import SelectAudience from "../audience/selectaudience.js";
import SelectAudience2 from "../ivs2/selectaudience2.js";
import { createTheme } from "@mui/material";
import { blockVariants, getDefaultContent } from "./inappcontent";
import "./inappmessages.css";
import { renderToStaticMarkup } from "react-dom/server";
import {
  dbCreateNewInAppMessage,
  dbGetAllMessagesByType,
  dbUpdateInAppMessage,
} from "../../utils/db.js";

// eslint-disable-next-line import/no-webpack-loader-syntax
import inappCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./inappmessages.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
import indexCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!../../../index.css';

function CustomTabPanel({ children, value, index, ...other }) {
  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="simpleTabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ textTransform: "none" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function InAppMessages() {
  const location = useLocation();
  const navigate = useNavigate();
  const editMessage = location.state?.editMessage;

  const [value, setValue] = useState(0);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedChildIndex, setSelectedChildIndex] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState("now");
  const [blocks, setBlocks] = useState(editMessage?.blocks ? JSON.parse(editMessage.blocks) : []);
  const [messageName, setMessageName] = useState(editMessage?.name || editMessage?.messageName || "");
  const [messageTitle, setMessageTitle] = useState(editMessage?.messageTitle || "");
  const [preheaderText, setPreheaderText] = useState(editMessage?.preheaderText || "");
  const [showPrevBlocks, setShowPrevBlocks] = useState(false);
  const [prevBlocks, setPrevBlocks] = useState([]);
  const [previewWidth, setPreviewWidth] = useState("calc(100% - 40px)");
  const [sendToEveryone, setSendToEveryone] = useState(editMessage?.sendToEveryone || false);
  const [includeAudienceIds, setIncludeAudienceIds] = useState(editMessage?.includeAudienceIds || []);
  const [excludeAudienceIds, setExcludeAudienceIds] = useState(editMessage?.excludeAudienceIds || []);

  useEffect(() => {
    if (editMessage) {
      setBlocks(JSON.parse(editMessage.blocks || '[]'));
      setMessageName(editMessage.name || editMessage.messageName || "");
      setMessageTitle(editMessage.messageTitle || "");
      setPreheaderText(editMessage.preheaderText || "");
      setDeliveryTime(editMessage.deliveryTime || "now");
      // Set other fields as needed
    }
  }, [editMessage]);

  useEffect(() => {
    console.log(blocks);
  }, [blocks]);

  const handleSendToEveryoneChange = (e) => {
    setSendToEveryone(e.target.checked);
  };

  const handleIncludeAudienceChange = (audienceIds) => {
    setIncludeAudienceIds(audienceIds);
  };

  const handleExcludeAudienceChange = (audienceIds) => {
    setExcludeAudienceIds(audienceIds);
  };

  const tabHeadings = ["Content", "Rows", "Settings"];

  const blockVariant = (id) => ({
    variant: id,
    children: Array(blockVariants[id].maxLength).fill(null),
    values: Array(blockVariants[id].maxLength).fill({}),
  });

  const rowCallback = (variantId) => {
    setBlocks((prev) => [...prev, blockVariant(variantId)]);
  };

  const genericSetBlock = (key, value, blockIndex, childIndex) => {
    setBlocks((prev) =>
      prev.with(blockIndex, {
        ...prev[blockIndex],
        [key]: prev[blockIndex][key].with(
          childIndex,
          typeof value === "function"
            ? value(prev[blockIndex][key][childIndex])
            : value,
        ),
      }),
    );
  };

  const setBlockChild = (value, blockIndex, childIndex) => {
    genericSetBlock("children", value, blockIndex, childIndex);
    setBlockValue(
      defaultContent[value].getDefaultValues(),
      blockIndex,
      childIndex,
    );
  };

  const setBlockValue = (value, blockIndex, childIndex) => {
    genericSetBlock("values", value, blockIndex, childIndex);
  };

  const getBlockValues = (blockIndex, childIndex) =>
    blocks[blockIndex].values[childIndex];

  const getSelectedChild = () =>
    selectedChildIndex !== null
      ? defaultContent[
      blocks[selectedChildIndex[0]].children[selectedChildIndex[1]]
      ] ?? null
      : null;

  const defaultContent = getDefaultContent(getBlockValues, setBlockValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSettingsInputs = () =>
    Object.entries(getSelectedChild()?.getSettings() ?? {}).map(
      ([valueName, { label, type, props = {}, ...others }]) => {
        const isCheckbox = type === "checkbox";
        const callback = (e) => {
          setBlockValue(
            (prev) => ({
              ...prev,
              [valueName]: isCheckbox ? !prev[valueName] : e.target.value,
            }),
            ...selectedChildIndex,
          );
        };
        const { [valueName]: value } = getBlockValues(...selectedChildIndex);
        const getInput = () => {
          switch (type) {
            case "select":
              return (
                <select onChange={callback} value={value}>
                  {others.options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              );
            case "textarea":
              return (
                <textarea
                  class="contentParagraph"
                  style={{ marginLeft: 8 }}
                  onChange={callback}
                  value={value}
                  {...props}
                ></textarea>
              );
            case "checkbox":
              return (
                <input
                  type="checkbox"
                  style={{ margin: 0 }}
                  onClick={callback}
                  checked={value}
                  {...props}
                />
              );
            default:
              return (
                <input
                  type={type}
                  style={{ margin: 0 }}
                  onChange={callback}
                  value={value}
                  {...props}
                />
              );
          }
        };
        return (
          <settings-row>
            <label>{label}</label>
            {getInput()}
          </settings-row>
        );
      },
    );

  const getDeliveryTimeForm = () => {
    switch (deliveryTime) {
      case "time":
        return <input type="time" style={{ margin: 0 }} />;
      case "app":
        return (
          <>
            <label>Select the trigger:</label>
            <select>
              <option>trigger 1</option>
              <option>trigger 2</option>
            </select>
          </>
        );
    }
    return null;
  };

  const showPreviousMessages = async () => {
    const items = await dbGetAllMessagesByType("global");
    setPrevBlocks(Object.assign(...(items.length === 0 ? [{}] : items)));
    setShowPrevBlocks(true);
  };

  const loadBlocks = (id) => {
    setBlocks(JSON.parse(prevBlocks[id].blocks));
    setShowPrevBlocks(false);
    setIsPreview(false);
    setSelectedChildIndex(null);
  };

  const saveMessage = () => {
    const htmlBody = renderToStaticMarkup(
      <ContentArea
        blocks={blocks}
        isPreview={true}
        defaultContent={defaultContent}
      />,
    );
    const htmlStyle = `<style>${indexCss + "\n" + inappCss}</style>`;
    const html = `
    <!DOCTYPE html>
    <html>
    <head>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    ${htmlStyle}
    </head>
    <body>${htmlBody}</body>
    </html>
    `;
    const formData = new FormData(document.inapp_form);
    // const data = {
    //   sendToEveryone2: sendToEveryone,
    //   includeAudienceIds,
    //   excludeAudienceIds,
    //   blocks: JSON.stringify(blocks),
    //   html,
    //   ...Object.fromEntries(formData.entries()),
    // };

    const data = {
      name: messageName,
      messageTitle: messageTitle,
      preheaderText: preheaderText,
      sendToEveryone,
      includeAudienceIds,
      excludeAudienceIds,
      blocks: JSON.stringify(blocks),
      html,
      deliveryTime,
      type: 'global'
      // Add any other relevant state variables here
    };


    if (editMessage) {
      dbUpdateInAppMessage(editMessage.id, data);
    } else {
      dbCreateNewInAppMessage(data);
    }
    navigate('/schedule');
  };

  return (
    <form name="inapp_form">
      <row>
        <double-column>
          <row>
            <h1>{editMessage ? "Edit in-app message" : "New in-app message"}</h1>
            <row>
              <column>
                <label htmlFor="messageName">Message name</label>
                <input
                  type="text"
                  id="messageName"
                  name="name"
                  value={messageName}
                  onChange={(e) => setMessageName(e.target.value)}
                />
              </column>
              <column></column>
              <column></column>
            </row>

            <row>
              <column>
                <dashboard-container>
                  <SelectAudience2
                    sendToEveryone={sendToEveryone}
                    onSendToEveryoneChange={handleSendToEveryoneChange}
                    includeAudienceIds={includeAudienceIds}
                    excludeAudienceIds={excludeAudienceIds}
                    onIncludeAudienceChange={handleIncludeAudienceChange}
                    onExcludeAudienceChange={handleExcludeAudienceChange}
                  />
                </dashboard-container>
              </column>
            </row>
            <dashboard-container>
              <row>
                <column>
                  <h3>Message header</h3>

                  <label for="emailSubject">Subject</label>
                  <input
                    type="text"
                    id="emailSubject"
                    name="messageTitle"
                    value={messageTitle}
                    onChange={(e) => setMessageTitle(e.target.value)}
                  />

                  <label for="preheaderText">Preheader text</label>
                  <input
                    type="text"
                    id="preheaderText"
                    name="preheaderText"
                    value={preheaderText}
                    onChange={(e) => setPreheaderText(e.target.value)}
                  />
                </column>
                <column></column>
                <column></column>
              </row>
            </dashboard-container>

            <row>
              <half-column>
                <dashboard-container>
                  <row>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: 8,
                      }}
                    >
                      <button
                        type="button"
                        className="button whitebutton"
                        style={{ flex: 1 }}
                        onClick={() => showPreviousMessages()}
                      >
                        Load Message
                      </button>
                      <button
                        type="button"
                        className="button whitebutton"
                        style={{ flex: 1 }}
                        onClick={() => setIsPreview((prev) => !prev)}
                      >
                        Toggle Preview
                      </button>
                    </div>
                    {showPrevBlocks && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          maxHeight: 100,
                          border: "1px solid #e5e8eb",
                          width: "100%",
                          overflow: "auto",
                        }}
                      >
                        {Object.entries(prevBlocks).map(
                          ([id, { messageTitle }]) => (
                            <div onClick={() => loadBlocks(id)}>
                              {messageTitle ?? `Title - ${id}`}
                            </div>
                          ),
                        )}
                      </div>
                    )}
                    <Box
                      sx={{ borderBottom: 0, borderColor: "", width: "100%" }}
                    >
                      <Tabs
                        indicatorColor="transparent"
                        className="tabs"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        {tabHeadings.map((heading, index) => (
                          <Tab
                            className={`tab ${value === index ? "activeTab" : ""}`}
                            label={heading}
                            id={`simple-tab-${index}`}
                            ariaControls={`simple-tabpanel-${index}`}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <InAppMessagesContentTab />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <InAppMessagesRowsTab callback={rowCallback} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      {getSelectedChild() === null ? (
                        <div style={{ textAlign: "center" }}>None</div>
                      ) : (
                        <row>
                          <column>
                            <settings-row style={{ margin: 0 }}>
                              <column>{getSettingsInputs()}</column>
                            </settings-row>
                          </column>
                        </row>
                      )}
                      <hr />
                      <div>
                        <settings-row>
                          <label>Change Size</label>
                          <select
                            onChange={(e) => setPreviewWidth(e.target.value)}
                          >
                            <option value="initial">Default</option>
                            <option value="375px">iPhone SE</option>
                          </select>
                        </settings-row>
                      </div>
                    </CustomTabPanel>
                  </row>
                </dashboard-container>
              </half-column>
              <column style={{ alignItems: "center" }}>
                <dashboard-container
                  id="content-area-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "normal",
                    width: isPreview ? previewWidth : "calc(100% - 40px)",
                  }}
                >
                  <ContentArea
                    blocks={blocks}
                    isPreview={isPreview}
                    selectedChildIndex={selectedChildIndex}
                    setSelectedChildIndex={setSelectedChildIndex}
                    setBlocks={setBlocks}
                    setBlockChild={setBlockChild}
                    defaultContent={defaultContent}
                  />
                  {blocks.length === 0 && (
                    <div className="gridBlock">
                      <div
                        className="blockChild"
                        style={{ textAlign: "center" }}
                      >
                        Add a new row by clicking in the column
                      </div>
                    </div>
                  )}
                </dashboard-container>
              </column>
            </row>

            <row>
              <column>
                <dashboard-container>
                  <row>
                    <h3>Delivery schedule</h3>
                  </row>
                  <row>
                    <row>
                      <p>When should this message start sending?</p>
                    </row>
                    <column>
                      <label
                        class="radio-container"
                        onClick={() => setDeliveryTime("now")}
                      >
                        Immediately
                        <input
                          type="radio"
                          name="schedule"
                          value="immediately"
                          checked={deliveryTime === "now"}
                        />
                        <span class="checkmark"></span>
                      </label>

                      <label
                        class="radio-container"
                        onClick={() => setDeliveryTime("time")}
                      >
                        Specific time
                        <input
                          type="radio"
                          name="schedule"
                          value="specificTime"
                          checked={deliveryTime === "time"}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label
                        class="radio-container"
                        onClick={() => setDeliveryTime("app")}
                      >
                        App trigger
                        <input
                          type="radio"
                          name="schedule"
                          value="appTrigger"
                          checked={deliveryTime === "app"}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </column>
                  </row>
                  <row>{getDeliveryTimeForm()}</row>
                </dashboard-container>

                <bottom-row>
                  {/* <button
                    type="button"
                    class="button bluebutton"
                    onClick={() => {
                      // Implement review and send functionality
                      console.log("Review and send clicked");
                    }}
                  >
                    Review and send
                  </button> */}
                  <button
                    class="button whitebutton"
                    type="button"
                    onClick={saveMessage}
                  >
                    {editMessage ? "Update" : "Save"}
                  </button>
                  <button className="button whitebutton" type="button" onClick={() => navigate('/schedule')}>
                    Cancel
                  </button>
                </bottom-row>
              </column>
            </row>
          </row>
        </double-column>
      </row>
    </form>
  );
}

export default InAppMessages;



























// import React, { useEffect, useState } from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import InAppMessagesContentTab from "./contenttab/contenttab.js";
// import InAppMessagesRowsTab from "./rowstab/rowstab.js";
// import ContentArea from "./ContentArea";

// import SelectAudience from "../audience/selectaudience.js";

// import { createTheme } from "@mui/material";
// import { blockVariants, getDefaultContent } from "./inappcontent";
// import "./inappmessages.css";
// import { renderToStaticMarkup } from "react-dom/server";
// import {
//   dbCreateNewInAppMessage,
//   dbGetAllMessagesByType,
// } from "../../utils/db.js";

// // eslint-disable-next-line import/no-webpack-loader-syntax
// import inappCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./inappmessages.css';
// // eslint-disable-next-line import/no-webpack-loader-syntax
// import indexCss from '!!css-loader?{"sourceMap":false,"exportType":"string"}!../../../index.css';

// function CustomTabPanel({ children, value, index, ...other }) {
//   const theme = createTheme({
//     components: {
//       MuiButtonBase: {
//         defaultProps: {
//           disableRipple: true,
//         },
//       },
//     },
//   });

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       className="simpleTabpanel"
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 0 }}>
//           <Typography sx={{ textTransform: "none" }}>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function InAppMessages() {
//   const [value, setValue] = useState(0);
//   const [isPreview, setIsPreview] = useState(false);
//   const [selectedChildIndex, setSelectedChildIndex] = useState(null);
//   const [deliveryTime, setDeliveryTime] = useState("now");

//   // [
//   //   {
//   //     variant: number,
//   //     children: string[],
//   //     values: [
//   //       {
//   //         {value: string, isCenter: bool},
//   //         {values: string[], selectedIndex: number},
//   //         ...
//   //       },
//   //       ...
//   //     ]
//   //   },
//   //   ...
//   // ]
//   const [blocks, setBlocks] = useState([]);
//   const [showPrevBlocks, setShowPrevBlocks] = useState(false);
//   const [prevBlocks, setPrevBlocks] = useState([]);
//   const [previewWidth, setPreviewWidth] = useState("calc(100% - 40px)");

//   useEffect(() => {
//     console.log(blocks);
//   }, [blocks]);

//   const tabHeadings = ["Content", "Rows", "Settings"];

//   const blockVariant = (id) => ({
//     variant: id,
//     children: Array(blockVariants[id].maxLength).fill(null),
//     values: Array(blockVariants[id].maxLength).fill({}),
//   });

//   const rowCallback = (variantId) => {
//     setBlocks((prev) => [...prev, blockVariant(variantId)]);
//   };

//   const genericSetBlock = (key, value, blockIndex, childIndex) => {
//     setBlocks((prev) =>
//       prev.with(blockIndex, {
//         ...prev[blockIndex],
//         [key]: prev[blockIndex][key].with(
//           childIndex,
//           typeof value === "function"
//             ? value(prev[blockIndex][key][childIndex])
//             : value,
//         ),
//       }),
//     );
//   };

//   const setBlockChild = (value, blockIndex, childIndex) => {
//     genericSetBlock("children", value, blockIndex, childIndex);
//     setBlockValue(
//       defaultContent[value].getDefaultValues(),
//       blockIndex,
//       childIndex,
//     );
//   };

//   const setBlockValue = (value, blockIndex, childIndex) => {
//     genericSetBlock("values", value, blockIndex, childIndex);
//   };

//   const getBlockValues = (blockIndex, childIndex) =>
//     blocks[blockIndex].values[childIndex];

//   const getSelectedChild = () =>
//     selectedChildIndex !== null
//       ? defaultContent[
//           blocks[selectedChildIndex[0]].children[selectedChildIndex[1]]
//         ] ?? null
//       : null;

//   const defaultContent = getDefaultContent(getBlockValues, setBlockValue);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const getSettingsInputs = () =>
//     Object.entries(getSelectedChild()?.getSettings() ?? {}).map(
//       ([valueName, { label, type, props = {}, ...others }]) => {
//         const isCheckbox = type === "checkbox";
//         const callback = (e) => {
//           setBlockValue(
//             (prev) => ({
//               ...prev,
//               [valueName]: isCheckbox ? !prev[valueName] : e.target.value,
//             }),
//             ...selectedChildIndex,
//           );
//         };
//         const { [valueName]: value } = getBlockValues(...selectedChildIndex);
//         const getInput = () => {
//           switch (type) {
//             case "select":
//               return (
//                 <select onChange={callback} value={value}>
//                   {others.options.map((option) => (
//                     <option value={option.value}>{option.label}</option>
//                   ))}
//                 </select>
//               );
//             case "textarea":
//               return (
//                 <textarea
//                   class="contentParagraph"
//                   style={{ marginLeft: 8 }}
//                   onChange={callback}
//                   value={value}
//                   {...props}
//                 ></textarea>
//               );
//             case "checkbox":
//               return (
//                 <input
//                   type="checkbox"
//                   style={{ margin: 0 }}
//                   onClick={callback}
//                   checked={value}
//                   {...props}
//                 />
//               );
//             default:
//               return (
//                 <input
//                   type={type}
//                   style={{ margin: 0 }}
//                   onChange={callback}
//                   value={value}
//                   {...props}
//                 />
//               );
//           }
//         };
//         return (
//           <settings-row>
//             <label>{label}</label>
//             {getInput()}
//           </settings-row>
//         );
//       },
//     );

//   const getDeliveryTimeForm = () => {
//     switch (deliveryTime) {
//       case "time":
//         return <input type="time" style={{ margin: 0 }} />;
//       case "app":
//         return (
//           <>
//             <label>Select the trigger:</label>
//             <select>
//               <option>trigger 1</option>
//               <option>trigger 2</option>
//             </select>
//           </>
//         );
//     }
//     return null;
//   };

//   const showPreviousMessages = async () => {
//     // TODO: Change to inapp not global
//     const items = await dbGetAllMessagesByType("global");
//     setPrevBlocks(Object.assign(...(items.length === 0 ? [{}] : items)));
//     setShowPrevBlocks(true);
//   };

//   const loadBlocks = (id) => {
//     setBlocks(JSON.parse(prevBlocks[id].blocks));
//     setShowPrevBlocks(false);
//     setIsPreview(false);
//     setSelectedChildIndex(null);
//   };

//   const saveMessage = () => {
//     const htmlBody = renderToStaticMarkup(
//       <ContentArea
//         blocks={blocks}
//         isPreview={true}
//         defaultContent={defaultContent}
//       />,
//     );
//     const htmlStyle = `<style>${indexCss + "\n" + inappCss}</style>`;
//     const html = `
//     <!DOCTYPE html>
//     <html>
//     <head>
//     <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
//     ${htmlStyle}
//     </head>
//     <body>${htmlBody}</body>
//     </html>
//     `;
//     const formData = new FormData(document.inapp_form);
//     const data = {
//       blocks: JSON.stringify(blocks),
//       html,
//       ...Object.fromEntries(formData.entries()),
//     };
//     dbCreateNewInAppMessage(data);
//   };

//   return (
//     <form name="inapp_form">
//       <row>
//         <double-column>
//           <row>
//             <h1>New in-app message</h1>
//             <row>
//               <column>
//                 <label htmlFor="messageName">Message name</label>
//                 <input type="text" id="messageName" name="name" />
//               </column>
//               <column></column>
//               <column></column>
//             </row>

//             <row>
//               <column>
//                 <dashboard-container>
//                   <SelectAudience></SelectAudience>
//                 </dashboard-container>
//               </column>
//             </row>
//             <dashboard-container>
//               <row>
//                 <column>
//                   <h3>Message header</h3>

//                   <label for="emailSubject">Subject</label>
//                   <input type="text" id="emailSubject" name="messageTitle" />

//                   <label for="preheaderText">Preheader text</label>
//                   <input type="text" id="preheaderText" name="preheaderText" />
//                 </column>
//                 <column></column>

//                 <column></column>
//               </row>
//             </dashboard-container>

//             <row>
//               <half-column>
//                 <dashboard-container>
//                   <row>
//                     <div
//                       style={{
//                         display: "flex",
//                         width: "100%",
//                         gap: 8,
//                       }}
//                     >
//                       <button
//                         type="button"
//                         className="button whitebutton"
//                         style={{ flex: 1 }}
//                         onClick={() => showPreviousMessages()}
//                       >
//                         Load Message
//                       </button>
//                       <button
//                         type="button"
//                         className="button whitebutton"
//                         style={{ flex: 1 }}
//                         onClick={() => setIsPreview((prev) => !prev)}
//                       >
//                         Toggle Preview
//                       </button>
//                     </div>
//                     {showPrevBlocks && (
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           maxHeight: 100,
//                           border: "1px solid #e5e8eb",
//                           width: "100%",
//                           overflow: "auto",
//                         }}
//                       >
//                         {Object.entries(prevBlocks).map(
//                           ([id, { messageTitle }]) => (
//                             <div onClick={() => loadBlocks(id)}>
//                               {messageTitle ?? `Title - ${id}`}
//                             </div>
//                           ),
//                         )}
//                       </div>
//                     )}
//                     <Box
//                       sx={{ borderBottom: 0, borderColor: "", width: "100%" }}
//                     >
//                       <Tabs
//                         indicatorColor="transparent"
//                         className="tabs"
//                         value={value}
//                         onChange={handleChange}
//                         aria-label="basic tabs example"
//                       >
//                         {tabHeadings.map((heading, index) => (
//                           <Tab
//                             className={`tab ${value === index ? "activeTab" : ""}`}
//                             label={heading}
//                             id={`simple-tab-${index}`}
//                             ariaControls={`simple-tabpanel-${index}`}
//                           />
//                         ))}
//                       </Tabs>
//                     </Box>
//                     <CustomTabPanel value={value} index={0}>
//                       <InAppMessagesContentTab />
//                     </CustomTabPanel>
//                     <CustomTabPanel value={value} index={1}>
//                       <InAppMessagesRowsTab callback={rowCallback} />
//                     </CustomTabPanel>
//                     <CustomTabPanel value={value} index={2}>
//                       {getSelectedChild() === null ? (
//                         <div style={{ textAlign: "center" }}>None</div>
//                       ) : (
//                         <row>
//                           <column>
//                             <settings-row style={{ margin: 0 }}>
//                               <column>{getSettingsInputs()}</column>
//                             </settings-row>
//                           </column>
//                         </row>
//                       )}
//                       <hr />
//                       <div>
//                         <settings-row>
//                           <label>Change Size</label>
//                           <select
//                             onChange={(e) => setPreviewWidth(e.target.value)}
//                           >
//                             <option value="initial">Default</option>
//                             <option value="375px">iPhone SE</option>
//                           </select>
//                         </settings-row>
//                       </div>
//                     </CustomTabPanel>
//                   </row>
//                 </dashboard-container>
//               </half-column>
//               <column style={{ alignItems: "center" }}>
//                 <dashboard-container
//                   id="content-area-container"
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     lineHeight: "normal",
//                     width: isPreview ? previewWidth : "calc(100% - 40px)",
//                   }}
//                 >
//                   <ContentArea
//                     blocks={blocks}
//                     isPreview={isPreview}
//                     selectedChildIndex={selectedChildIndex}
//                     setSelectedChildIndex={setSelectedChildIndex}
//                     setBlocks={setBlocks}
//                     setBlockChild={setBlockChild}
//                     defaultContent={defaultContent}
//                   />
//                   {blocks.length === 0 && (
//                     <div className="gridBlock">
//                       <div
//                         className="blockChild"
//                         style={{ textAlign: "center" }}
//                       >
//                         Add a new row by clicking in the column
//                       </div>
//                     </div>
//                   )}
//                 </dashboard-container>
//               </column>
//             </row>

//             <row>
//               <column>
//                 <dashboard-container>
//                   <row>
//                     <h3>Delivery schedule</h3>
//                   </row>
//                   <row>
//                     <row>
//                       <p>When should this message start sending?</p>
//                     </row>
//                     <column>
//                       <label
//                         class="radio-container"
//                         onClick={() => setDeliveryTime("now")}
//                       >
//                         Immediately
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="immediately"
//                           checked={deliveryTime === "now"}
//                         />
//                         <span class="checkmark"></span>
//                       </label>

//                       <label
//                         class="radio-container"
//                         onClick={() => setDeliveryTime("time")}
//                       >
//                         Specific time
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="specificTime"
//                           checked={deliveryTime === "time"}
//                         ></input>
//                         <span class="checkmark"></span>
//                       </label>
//                       <label
//                         class="radio-container"
//                         onClick={() => setDeliveryTime("app")}
//                       >
//                         App trigger
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="appTrigger"
//                           checked={deliveryTime === "app"}
//                         ></input>
//                         <span class="checkmark"></span>
//                       </label>
//                     </column>
//                   </row>
//                   <row>{getDeliveryTimeForm()}</row>
//                 </dashboard-container>

//                 <bottom-row>
//                   <button
//                     type="button"
//                     class="button bluebutton"
//                     href="sign-in.html"
//                   >
//                     Review and send
//                   </button>
//                   <button
//                     class="button whitebutton"
//                     type="button"
//                     onClick={saveMessage}
//                   >
//                     Save
//                   </button>
//                 </bottom-row>
//               </column>
//             </row>
//           </row>
//         </double-column>
//       </row>
//     </form>
//   );
// }

// export default InAppMessages;
