const fr = ([str]) =>
  str
    .split(" ")
    .map((s) => `minmax(0, ${s})`)
    .join(" ");

export const blockVariants = {
  0: { maxLength: 1, layout: fr`1fr` },
  1: { maxLength: 2, layout: fr`1fr 3fr` },
  2: { maxLength: 2, layout: fr`1fr 2fr` },
  3: { maxLength: 2, layout: fr`1fr 1fr` },
  4: { maxLength: 2, layout: fr`2fr 1fr` },
  5: { maxLength: 2, layout: fr`3fr 1fr` },
  6: { maxLength: 3, layout: fr`1fr 1fr 1fr` },
  7: { maxLength: 3, layout: fr`1fr 1fr 2fr` },
  8: { maxLength: 3, layout: fr`1fr 2fr 1fr` },
  9: { maxLength: 3, layout: fr`2fr 1fr 1fr` },
  10: { maxLength: 4, layout: fr`1fr 1fr 1fr 1fr` },
  11: { maxLength: 6, layout: fr`1fr 1fr 1fr 1fr 1fr 1fr` },
};

// {
//   contentName: {
//     getElement: funcion(int, int): JSX,
//     getPreview: funcion(int, int): JSX,
//     getDefaultValues: funcion(): { valueName: any, ... },
//     getSettings: funcion(): {
//       valueName: {label: string, type: string, [optional others]},
//       ...
//     },
//   },
//   ...
// }
export const getDefaultContent = (getBlockValues, setBlockValue) => ({
  divider: {
    getElement: (blockIndex, childIndex) => {
      const { width } = getBlockValues(blockIndex, childIndex);
      return (
        <hr className="contentDivider" style={{ width: width + "%" }} />
      );
    },
    getPreview: function (blockIndex, childIndex) {
      return this.getElement(blockIndex, childIndex);
    },
    getDefaultValues: () => ({ width: "100" }),
    getSettings: () => ({
      width: { label: "Width", type: "range", props: { min: 0, max: 100 } },
    }),
  },
  spacer: {
    getElement: (blockIndex, childIndex) => {
      const { height } = getBlockValues(blockIndex, childIndex);
      return (
        <hr
          className="contentSpacer"
          style={{ height: height + "px" }}
        />
      );
    },
    getPreview: function (blockIndex, childIndex) {
      return this.getElement(blockIndex, childIndex);
    },
    getDefaultValues: () => ({ height: "20" }),
    getSettings: () => ({
      height: { label: "Height", type: "number", props: { min: 5, step: 5 } },
    }),
  },
  title: {
    getElement: (blockIndex, childIndex) => {
      const { value } = getBlockValues(blockIndex, childIndex);
      return (
        <input
          type="text"
          className="contentTitle"
          onInput={({ target }) => {
            setBlockValue(
              (prev) => ({ ...prev, value: target.value }),
              blockIndex,
              childIndex,
            );
          }}
          value={value}
          key={`title-${blockIndex}-${childIndex}`}
        />
      );
    },
    getPreview: (blockIndex, childIndex) => {
      const { value, headingType, alignment, isUnderlined } = getBlockValues(
        blockIndex,
        childIndex,
      );
      const Heading = `h${headingType}`;
      return (
        <Heading
          style={{
            textDecoration: isUnderlined ? "underline" : "none",
            textAlign: alignment,
            width: "100%",
          }}
        >
          {value}
        </Heading>
      );
    },
    getDefaultValues: () => ({
      value: "",
      headingType: "1",
      alignment: "center",
      isUnderlined: false,
    }),
    getSettings: () => ({
      headingType: {
        label: "Heading Type",
        type: "select",
        options: [
          { value: 1, label: "H1" },
          { value: 2, label: "H2" },
          { value: 3, label: "H3" },
          { value: 4, label: "H4" },
          { value: 5, label: "H5" },
        ],
      },
      alignment: {
        label: "Alignment",
        type: "select",
        options: [
          { value: "left", label: "Left" },
          { value: "center", label: "Center" },
          { value: "right", label: "Right" },
        ],
      },
      isUnderlined: { label: "Underline", type: "checkbox" },
    }),
  },
  paragraph: {
    getElement: (blockIndex, childIndex) => {
      const { value, height } = getBlockValues(blockIndex, childIndex);
      return (
        <textarea
          className="contentParagraph"
          onInput={function ({ target }) {
            const newHeight = target.scrollHeight - 8 + "px";
            target.style.height = "";
            target.style.height = newHeight;
            setBlockValue(
              { value: target.value, height: newHeight },
              blockIndex,
              childIndex,
            );
          }}
          value={value}
          style={{ height }}
          key={`paragraph-${blockIndex}-${childIndex}`}
        ></textarea>
      );
    },
    getPreview: (blockIndex, childIndex) => {
      const { value, alignment } = getBlockValues(blockIndex, childIndex);
      return (
        <pre
          style={{
            width: "100%",
            fontFamily: "unset",
            textAlign: alignment,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {value}
        </pre>
      );
    },
    getDefaultValues: () => ({ value: "", height: null, alignment: "left" }),
    getSettings: () => ({
      alignment: {
        label: "Alignment",
        type: "select",
        options: [
          { value: "left", label: "Left" },
          { value: "center", label: "Center" },
          { value: "right", label: "Right" },
        ],
      },
    }),
  },
  html: {
    getElement: (blockIndex, childIndex) => {
      const { value, height } = getBlockValues(blockIndex, childIndex);
      return (
        <textarea
          className="contentParagraph"
          onInput={({ target }) => {
            const newHeight = target.scrollHeight - 8 + "px";
            target.style.height = "";
            target.style.height = newHeight;
            setBlockValue(
              { value: target.value, height: newHeight },
              blockIndex,
              childIndex,
            );
          }}
          value={value}
          style={{ height }}
          key={`html-${blockIndex}-${childIndex}`}
        ></textarea>
      );
    },
    getPreview: (blockIndex, childIndex) => {
      const { value } = getBlockValues(blockIndex, childIndex);
      return (
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      );
    },
    getDefaultValues: () => ({ value: "", height: null }),
    getSettings: () => ({}),
  },
  list: {
    getElement: (blockIndex, childIndex) => {
      const { values } = getBlockValues(blockIndex, childIndex);
      return (
        <ul
          style={{
            width: "calc(100% - 40px)",
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
          className="contentList"
        >
          {values.map((value, index) => (
            <li>
              <input
                type="text"
                style={{ margin: 0 }}
                value={value}
                autoFocus
                onInput={({ target }) => {
                  setBlockValue(
                    (prev) => ({
                      ...prev,
                      values: prev.values.with(index, target.value),
                    }),
                    blockIndex,
                    childIndex,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    setBlockValue(
                      (prev) => ({
                        ...prev,
                        selectedIndex:
                          prev.selectedIndex === 0 ? 0 : prev.selectedIndex - 1,
                      }),
                      blockIndex,
                      childIndex,
                    );
                  } else if (e.key === "ArrowDown") {
                    setBlockValue(
                      (prev) => ({
                        ...prev,
                        selectedIndex:
                          prev.selectedIndex === prev.values.length - 1
                            ? prev.selectedIndex
                            : prev.selectedIndex + 1,
                      }),
                      blockIndex,
                      childIndex,
                    );
                  } else if (e.key === "Enter") {
                    setBlockValue(
                      (prev) => ({
                        ...prev,
                        values: [...prev.values, ""],
                        selectedIndex: prev.values.length,
                      }),
                      blockIndex,
                      childIndex,
                    );
                  } else if (e.key === "Backspace") {
                    setBlockValue(
                      (prev) => {
                        let newValue = prev.values;
                        if (prev.values[index].length === 0) {
                          newValue = prev.values.toSpliced(index, 1);
                          e.preventDefault();
                        }
                        return { ...prev, values: newValue };
                      },
                      blockIndex,
                      childIndex,
                    );
                  }
                }}
              />
            </li>
          ))}
        </ul>
      );
    },
    getPreview: (blockIndex, childIndex) => {
      const { values } = getBlockValues(blockIndex, childIndex);
      return (
        <ul
          style={{
            width: "calc(100% - 40px)",
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          {values.map((value) => (
            <li>{value}</li>
          ))}
        </ul>
      );
    },
    getDefaultValues: () => ({ values: [""], selectedIndex: 0 }),
    getSettings: () => ({}),
  },
  button: {
    getElement: (blockIndex, childIndex) => {
      const { text } = getBlockValues(blockIndex, childIndex);
      return (
        <button
          class="button bluebutton"
          style={{ width: "100%" }}
          key={`button-${blockIndex}-${childIndex}`}
        >
          {text}
        </button>
      );
    },
    getPreview: function (blockIndex, childIndex) {
      const { href } = getBlockValues(blockIndex, childIndex);
      return (
        <a style={{ width: "100%" }} href={href}>
          {this.getElement(blockIndex, childIndex)}
        </a>
      );
    },
    getDefaultValues: () => ({ href: "#", text: "Button" }),
    getSettings: () => ({
      href: { label: "URL", type: "text" },
      text: { label: "Text", type: "text" },
    }),
  },
  image: {
    getElement: (blockIndex, childIndex) => {
      const { src } = getBlockValues(blockIndex, childIndex);
      return (
        <img
          className="contentImage"
          src={src}
          key={`image-${blockIndex}-${childIndex}`}
        />
      );
    },
    getPreview: function (blockIndex, childIndex) {
      return this.getElement(blockIndex, childIndex);
    },
    getDefaultValues: () => ({ src: "https://placehold.co/600x400" }),
    getSettings: () => ({
      src: { label: "URL", type: "text" },
    }),
  },
  video: {
    getElement: (blockIndex, childIndex) => (
      <div>Enter video embed html in the settings pane</div>
    ),
    getPreview: (blockIndex, childIndex) => {
      const { html } = getBlockValues(blockIndex, childIndex);
      return (
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      );
    },
    getDefaultValues: () => ({ html: "" }),
    getSettings: () => ({
      html: {
        label: "HTML",
        type: "textarea",
        props: { placeholder: "Enter HTML here...", rows: 12 },
      },
    }),
  },
});
