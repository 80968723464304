import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './unread.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';




function UnreadTab() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (

        <div className={styles.tableRow}>
         


            <table id="events" class="events-table" cellspacing="0" width="100%">
                <tbody>
                    <tr>
                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>

                        </td>
                        <td className={styles.from}>Dave Brock</td>
                        <td className={styles.message}>Hello - </td>
                        <td className={styles.date}>17 Apr 2024</td>
                    </tr>

                    <tr>
                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>
                        <td className={styles.from}>Richard Chadwick</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>16 Apr 2024</td>
                    </tr>
                    <tr>

                        <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>
                        <td className={styles.from}>Magnus Martin</td>
                        <td className={styles.message}>Message</td>
                        <td class="date">15 Apr 2024</td>
                    </tr>
                    <tr>
                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>
                        <td className={styles.from}>Simon House</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>15 Apr 2024</td>
                    </tr>

                    <tr>
                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>
                        <td className={styles.from}>Terry Ollis </td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>15 Apr 2024</td>
                    </tr>
                    <tr>

                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>
                        <td class="from">Keith Hale</td>
                        <td class="message">Message</td>
                        <td class="date">13 Apr 2024</td>
                    </tr>

                    <tr>
                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>

                        <td class="from">John Harrison </td>
                        <td class="message">Message</td>
                        <td class="date">12 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>

                        <td class="from">Robert Calvert</td>
                        <td class="message">Message</td>
                        <td class="date">5 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                        <div className={styles.iconsRow}>
                          
                          <label className={styles.emailCheckboxContainer}>
                              <input type="checkbox" name="checkbox"></input>
                              <span className={styles.checkboxCheckmark}></span>
                          </label>
                          <StarBorderOutlinedIcon/>
                    
                      </div>
                        </td>

                        <td class="from">Del Dettmar</td>
                        <td class="message">Message</td>
                        <td class="date">4 Apr 2024</td>


                    </tr>







                </tbody>
            </table>
        </div>
    );
}

export default UnreadTab;
