import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import styles from './dashboard.module.css';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';

const Dashboard = () => {
  const doverFlowChartRef = useRef(null);
  const leShuttleFlowChartRef = useRef(null);
  const loadChartRef = useRef(null);
  const muaChartRef = useRef(null);
  const [greetingMessage, setGreetingMessage] = useState('');

  var flowxValuesDover = [
    "-2.5", "-2.4", "-2.3", "-2.2", "-2.1",
    "-2.0", "-1.9", "-1.8", "-1.7", "-1.6", "-1.5", "-1.4",
    "-1.3", "-1.2", "-1.1", "-1.0", "-0.9", "-0.8", "-0.7",
    "-0.6", "-0.5", "-0.4", "-0.3", "-0.2", "-0.1", "0.0"
  ];
  var flowyValuesDover = [362, 497, 506, 672, 645, 587, 484, 425, 427, 436, 578, 401, 399, 365, 426, 564, 635, 425, 564, 398, 365, 376, 345, 404, 398, 365];

  var barColorsDover = flowyValuesDover.map(function (value) {
    if (value <= 400) {
      return "#d2e6bdff";
    } else if (value <= 500) {
      return "#f9e4aeff";
    } else {
      return "#f7c7c9ff";
    }
  });

  var flowxValuesShuttle = [
    "-2.5", "-2.4", "-2.3", "-2.2", "-2.1",
    "-2.0", "-1.9", "-1.8", "-1.7", "-1.6", "-1.5", "-1.4",
    "-1.3", "-1.2", "-1.1", "-1.0", "-0.9", "-0.8", "-0.7",
    "-0.6", "-0.5", "-0.4", "-0.3", "-0.2", "-0.1", "0.0"
  ];
  var flowyValuesShuttle = [460, 387, 406, 572, 445, 487, 384, 325, 327, 336, 468, 403, 389, 345, 326, 424, 525, 425, 564, 398, 325, 357, 327, 310, 378, 352];

  var barColorsShuttle = flowyValuesShuttle.map(function (value) {
    if (value <= 400) {
      return "#d2e6bdff";
    } else if (value <= 500) {
      return "#f9e4aeff";
    } else {
      return "#f7c7c9ff";
    }
  });

  var loadxValues = ["Hazardous", "Perishable", 'Live', "Long", "Wide", "Standard",];
  var loadyValues = [55, 107, 144, 84, 96, 289];
  var barColors = [
    "#f7c7c9ff",
    "#ffd7b5",
    "#f9e4aeff",
    "#d8d8d8",
    "#a0e4fd",
    "#d2e6bdff"
  ];

  useEffect(() => {
    const doverFlowChart = new Chart(doverFlowChartRef.current, {
      type: 'bar',
      data: {
        labels: flowxValuesDover,
        datasets: [{
          backgroundColor: barColorsDover,
          data: flowyValuesDover
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        title: {
          display: false,
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 800,
            ticks: {
              stepSize: 200
            }
          }
        }
      }
    });

    const leShuttleFlowChart = new Chart(leShuttleFlowChartRef.current, {
      type: 'bar',
      data: {
        labels: flowxValuesShuttle,
        datasets: [{
          backgroundColor: barColorsShuttle,
          data: flowyValuesShuttle
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        title: {
          display: false,
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 800,
            ticks: {
              stepSize: 200
            }
          }
        }
      }
    });

    const loadChart = new Chart(loadChartRef.current, {

      type: "doughnut",
      data: {
        labels: loadxValues,
        datasets: [{
          backgroundColor: barColors,
          data: loadyValues
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'left',
            align: 'center'
          }
        },
        title: {
          display: false,

        },
        legend: {
          position: 'left'
        }

      }
    });

    const muaxValues = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    const muayValues = [415, 418, 426, 421, 422, 424, 423, 430, 431, 429, 425, 427, 429, 428, 434];

    const muaChart = new Chart(muaChartRef.current, {
      type: "line",
      data: {
        labels: muaxValues,
        datasets: [{
          fill: true,
          lineTension: 0,
          backgroundColor: "#d2e6bdff",
          borderColor: "#bdcfaa",
          data: muayValues
        }]
      },
      options: {
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        legend: { display: false },
        scales: {
          y: {
            display: false,
            ticks: { min: 200, max: 432, display: true },
            stepSize: 5,
          },
          x: {
            display: false,
            ticks: { display: true }
          }
        },
        title: {
          display: false,

        },


      }
    });


    return () => {
      doverFlowChart.destroy();
      leShuttleFlowChart.destroy();
      loadChart.destroy();
      muaChart.destroy();
    };
  }, []);

  useEffect(() => {
    const currentHour = new Date().getHours();
    setGreetingMessage(getGreetingMessage(currentHour));
  }, []);

  function getGreetingMessage(hour) {
    if (hour < 12) {
      return 'Good morning!';
    } else if (hour < 18) {
      return 'Good afternoon!';
    } else {
      return 'Good evening!';
    }
  }


  return (
    // <div class="section" className={styles.section}>

    <double-column>
      <row>
        <h1>{greetingMessage}</h1>
        <row>
          <double-column>
            <div className={styles.brockContainer}>
              <div className={styles.brockRow}>
                <div className={styles.brockIcon}>
                  <CheckCircleIcon />
                </div>
                M20 is open
              </div>
            </div>
          </double-column>
        </row>
        <row>
          <column>
            <dashboard-container>
              <row>
                <h2>Dover - trucks in last 30 minutes</h2>
              </row>
              <notification-row>
                <h1>121</h1>
              </notification-row>
              <row>
                <h2>Real time flow rate/hour</h2>
              </row>
              <row>
                <canvas className={styles.doverflowChart} ref={doverFlowChartRef}></canvas>
              </row>
              <row>
                <h2>Le Shuttle - trucks in last 30 minutes</h2>
              </row>
              <notification-row>
                <h1>87</h1>
              </notification-row>

              <row>
                <h2>Real time flow rate/hour</h2>
              </row>
              <row>
                <canvas className={styles.leshuttleflowChart} ref={leShuttleFlowChartRef}></canvas>
              </row>

            </dashboard-container>
          </column>

          <half-column>
            <dashboard-container>
              <notification-row>
                <h2>Notifications</h2>
              </notification-row>

              <notification-row>
                <row>


                  <div className={styles.warningIcon}>
                    <WarningAmberOutlinedIcon />
                  </div>


                  Flow rate
                </row>
                <row>
                  Flow rate for the Port of Dover is due to exceed the
                  maximum in the next 6 hours
                </row>
              </notification-row>

              <notification-row>
                <row>


                  <div className={styles.messageIcon}>
                    <EmailOutlinedIcon />
                  </div>


                  Message
                </row>
                <row>
                  You have a new message from Ashford International Truckstop
                </row>
              </notification-row>

              <notification-row>
                <row>
                  <div className={styles.warningIcon}>
                    <WarningAmberOutlinedIcon />
                  </div>
                  Weather warning issued
                </row>
                <row>
                  Met Office has issued a Yellow Weather Warning for the
                  Port of Dover
                </row>
              </notification-row>

              <notification-row>
                <row>
                  <div className={styles.warningIcon}>
                    <WarningAmberOutlinedIcon />
                  </div>
                  Truck stop capacity
                </row>
                <row>
                  Stop 24 is at full capacity
                </row>
              </notification-row>

              <notification-row>
                <row>


                  <div className={styles.messageIcon}>
                    <SendToMobileOutlinedIcon />
                  </div>

                  Push notification
                </row>
                <row>
                  Push notification is scheduled for send at 12.30 pm today
                </row>
              </notification-row>



              <notification-row>
                <row>
                  <div className={styles.supportIcon}>
                    <LiveHelpOutlinedIcon />
                  </div>
                  Support



                </row>
                <row>
                  Your support ticket is waiting a response
                </row>
              </notification-row>
            </dashboard-container>
          </half-column>
        </row>

        <row>
          <column>
            <dashboard-container>
              <row>
                <h2>Truck loads</h2>
              </row>
              <row>
                <canvas className={styles.loadChart}
                  ref={loadChartRef}
                  style={{ width: '100%', maxWidth: '600px' }}
                ></canvas>
              </row>
            </dashboard-container>
          </column>

          <column>
            <dashboard-container>
              <notification-row>
                <h2>Active users</h2>
              </notification-row>
              <notification-row>
                <p>
                  Daily avg: 5,482{' '}
                  <span className={styles.greenText}>(+4.5%)</span>
                </p>
              </notification-row>

              <row>
                <column>
                  <row>
                    <p>Month</p>
                  </row>
                  <row>
                    <p>5,319</p>
                  </row>
                </column>
                <column>
                  <row>
                    <p>Today</p>
                  </row>
                  <row>
                    <p>5,513</p>
                  </row>
                </column>
                <column>


            

                  <canvas ref={muaChartRef} className={styles.muaChart}
                   style={{ height: '100px', width: '300'}}
                  ></canvas>
                </column>
              </row>
            </dashboard-container>
          </column>

          <column>
            <dashboard-container>
              <notification-row>
                <h2>Weather</h2>
              </notification-row>

              <notification-row>
                <div className={styles.row}>
                  <p>Port of Dover</p>
                </div>
                <div className={styles.row}>
                  <img src="15.jpg" alt="Weather" width="100" />
                </div>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <div className={styles.row}>Temp: 12C</div>
                    <div className={styles.row}>Wind speed: 8</div>
                  </div>

                  <column>
                    <row>Visibility: Poor</row>
                    <row>Chance of rain: 89%</row>
                  </column>
                </div>
              </notification-row>

              <row>
                <p>Le Shuttle</p>
              </row>
              <row>
                <img src="10.jpg" alt="Weather" width="100" />
              </row>
              <row>
                <column>
                  <row>Temp: 14C</row>
                  <row>Wind speed: 6</row>
                </column>
                <column>
                  <row>Visibility: Good</row>
                  <row>Chance of rain: 67%</row>
                </column>
              </row>
            </dashboard-container>
          </column>
        </row>
      </row>
    </double-column>

    // </div>
  );
};

export default Dashboard;