import React, { useState, useEffect } from 'react';
import styles from './settings.module.css'; 

import { Link } from 'react-router-dom';

function Settings() {
    const [image, setImage] = useState(null);

  

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imgLink = URL.createObjectURL(file);
            setImage(imgLink);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const imgLink = URL.createObjectURL(file);
            setImage(imgLink);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

 

    return (


        <body>
            <row>
                <row>
                    <h1>Settings</h1>
                </row>
                <double-column>
                    <dashboard-container>
                        <h3>Truck stop details</h3>
                    
                            <row>
                                <half-column>
                                    <label for="truckstopName">Name of truck stop</label>
                                    <input type="text" id="truckstopName" name="truckstopName" />
                                </half-column>
                            </row>

                            <row>
                                <half-column>
                                    <label for="spaces">Number of spaces</label>
                                    <input type="text" id="speaces" name="spaces" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="road">Road</label>
                                    <input type="text" id="road" name="road" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="phoneNumber">Phone number</label>
                                    <input type="text" id="phoneNumber" name="phoneNumber" />
                                </half-column>
                                <half-column>
                                    <label for="email">Email</label>
                                    <input type="email" id="eamil" name="eamil" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="addressLineOne">Address line 1</label>
                                    <input type="text" id="addressLineOne" name="addressLineOne" />
                                </half-column>
                                <half-column>
                                    <label for="addressLineTwo">Address line 2 (optional)</label>
                                    <input type="text" id="addressLineTwo" name="addressLineTwo" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="town">Town or city</label>
                                    <input type="text" id="town" name="towny" />
                                </half-column>
                                <half-column>
                                    <label for="coutny">County (optional)</label>
                                    <input type="text" id="county" name="county" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="postcode">Postcode</label>
                                    <input type="text" id="postcode" name="postcode" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <label for="latitude">Latitude</label>
                                    <input type="text" id="latitude" name="latitude" />
                                </half-column>
                                <half-column>
                                    <label for="longitude">Longitude</label>
                                    <input type="text" id="longitude" name="Longitude" />
                                </half-column>
                            </row>
                            <row>
                                <half-column>

                                    <label className={styles.dropArea} htmlFor="input-file" id="drop-area" onDrop={handleDrop} onDragOver={handleDragOver}>
                                        <input type="file" accept="image/*" id="input-file" hidden onChange={handleImageUpload} />
                                        {image ? (
                                            <div className={styles.imgView} style={{ backgroundImage: `url(${image})` }}></div>
                                        ) : (
                                            <div className={styles.imgView}>
                                                <img className={styles.uploadImage}src="upload-icon.png" alt="" />
                                                <p>Drag and drop, or upload an image from your device</p>
                                             
                                            </div>
                                        )}
                                    </label>
                                </half-column>
                            </row>
                            <half-column>
                                <row>
                                    <div id="buttonContainer">
                                        <h3>Facilities</h3>
                                        <div id="facilitiesButtons"></div>
                                        <h3>Infrastructure</h3>
                                        <div id="infrastructureButtons"></div>
                                        <h3>Security</h3>
                                        <div id="securityButtons"></div>
                                    </div>
                                </row>
                            </half-column>
                            <row>
                                <column>
                                    <h3>Opening hours</h3>
                                </column>
                            </row>
                            <row>
                                <half-column>
                                    <h3>Day</h3>
                                </half-column>
                                <half-column>
                                    <h3>Open</h3>
                                </half-column>
                                <half-column>
                                    <h3>Close</h3>
                                </half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>


                                        <label class="checkbox-container">Monday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>


                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="mondayOpen" name="mondayOpen" /></half-column>
                                <half-column> <input type="time" id="mondayClose" name="mondayClose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Tuesday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="tuesdayopen" name="tuesdayopen" /></half-column>
                                <half-column> <input type="time" id="tuesdayclose" name="tuesdayclose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Wednesday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="wednesdayopen" name="wednesdayopen" /></half-column>
                                <half-column> <input type="time" id="wednesdayclose" name="wednesdayclose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Thursday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="thursdayopen" name="thursdayopen" /></half-column>
                                <half-column> <input type="time" id="thursdayclose" name="thursdayclose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Friday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="fridayOpen" name="fridayOpen" /></half-column>
                                <half-column> <input type="time" id="fridayclose" name="fridayclose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Saturday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="saturdayopen" name="saturdayopen" /></half-column>
                                <half-column> <input type="time" id="saturdayclose" name="saturdayclose" /></half-column>
                            </row>
                            <row>
                                <half-column>
                                    <row>
                                        <label class="checkbox-container">Sunday
                                            <input type="checkbox" name="checkbox" />
                                            <span class="checkbox-checkmark"></span>
                                        </label>

                                    </row>
                                </half-column>
                                <half-column> <input type="time" id="sundayopen" name="sundayopen" /></half-column>
                                <half-column> <input type="time" id="sundayclose" name="sundayclose" /></half-column>
                            </row>

                     
                    </dashboard-container>

                    <bottom-row>

                     
                      

                        <Link to="/truckstops" className="button whitebutton">
                                    Cancel
                                </Link>

                                <button id="openPopup" class="button bluebutton">Save</button>


                        

                    </bottom-row>

                </double-column>

                <sticky-column>
                    <row>
                        <dashboard-container>
                            <h3>Guidance notes</h3>

                            <row>
                                <strong>Name</strong>- use


                            </row>
                            <p>
                                Name - use the full name of the truck stop rather than abbreviations <br />
                                Spaces - the maximum number of spaces avaialble <br />
                                Road - the nearest road; motorways and A roads only<br />

                                Address - include motorway junction/s if appropriate<br />
                                Lat/long - be as accurate as possible<br />
                                Image - png/jpg/jpeg only, image should have dimensions of approx 800 x 600 px
                                <br />
                                Amenities - select amenities available<br />
                                Opening hours - check the days open, and for days not open, leave blank<br />
                            </p>
                        </dashboard-container>
                    </row>
                </sticky-column>

            </row>

        </body>
    );
}

export default Settings;