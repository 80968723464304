// import React, { useState, useEffect } from 'react';
// import { HashRouter } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { auth, firestore } from './firebase.js'; // Ensure firestore is correctly imported
// import Sidebar from './components/sidebar/sidebar.js';
// import Dashboard from './components/dashboard/dashboard.js';
// import Login from './components/login/login.js';
// import { PrivateRoute } from './components/private-route/private-route.js';
// import TAS from './components/tas/tas.js';
// import TopNavBar from './components/top-navbar/top-navbar.js';
// import Admin from './components/admin/admin.js';
// import Reports from './components/reports/reports.js';
// import TruckStopsList from './components/truckstops/truckstops.js';
// import Schedule from './components/schedule/schedule.js';
// import InAppMessages from './components/messages/inappmessages/inappmessages.js';
// import SMSMessages from './components/messages/smsmessages/smsmessages.js';
// import PushMessages from './components/messages/pushmessages/pushmessages.js';
// import Live from './components/live/live.js';
// import NewTruckStop from './components/newtruckstop/newtruckstop.js';
// import Settings from './components/settings/settings.js';
// import PortsRoutes from './components/route-configs/ports-routes.js';
// import NECRoutes from './components/route-configs/nec-routes.js';
// import { SitePresets, SiteConfig } from './site-config.js';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth, firestore } from './firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc, getDocs } from 'firebase/firestore';
import Sidebar from './components/sidebar/sidebar.js';
import Dashboard from './components/dashboard/dashboard.js';
import Login from './components/login/login.js'; // Ensure this component is correctly imported
import { PrivateRoute } from './components/private-route/private-route.js';
import TAS from './components/tas/tas.js';
import TopNavBar from './components/top-navbar/top-navbar.js';
import Admin from './components/admin/admin.js';
import Reports from './components/reports/reports.js';
import TruckStopsList from './components/truckstops/truckstops.js';
import Schedule from './components/schedule/schedule.js';
import InAppMessages from './components/messages/inappmessages/inappmessages.js';
import SMSMessages from './components/messages/smsmessages/smsmessages.js';
import PushMessages from './components/messages/pushmessages/pushmessages.js';
import Live from './components/live/live.js';
import NewTruckStop from './components/newtruckstop/newtruckstop.js';
import Settings from './components/settings/settings.js';
import PortsRoutes from './components/route-configs/ports-routes.js';
import NECRoutes from './components/route-configs/nec-routes.js';
import { SitePresets, SiteConfig } from './site-config.js';

function App() {
  let RoutesComponent;
  const selectedMenuItem = useSelector((state) => state.menu.selectedMenuItem);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserModel, setCurrentUserModel] = useState(null);
  const [isSideBarHidden, setIsSideBarHidden] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        fetchUserModel(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserModel = async (email) => {
    try {
      const usersCollection = collection(firestore, 'users');
      const emailQuery = query(usersCollection, where('email', '==', email));
      const querySnapshot = await getDocs(emailQuery);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        setCurrentUserModel(userDoc.data());
        console.log('User model:', userDoc.data());
      } else {
        console.log('No user found with the provided email.');
      }

      // const userDoc = await firestore.collection('users').doc(userId).get();
      // if (userDoc.exists) {
      //   setCurrentUserModel(userDoc.data());
      // }
      console.log('emailQuery:', emailQuery);
    } catch (error) {
      console.error('Error fetching user model:', error);
    }
  };

  // Determine which routes to use based on the current preset
  switch (SiteConfig.currentPreset) {
    case SitePresets.PORTS:
      RoutesComponent = <PortsRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />;
      break;
    case SitePresets.NEC:
      RoutesComponent = <NECRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />;
      break;
    default:
      RoutesComponent = <PortsRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />; // Default to Ports if no match
      break;
  }

  return (
    <HashRouter>
      {RoutesComponent}
    </HashRouter>
  );

  // return (
  //   <HashRouter>
  //     <div className="app">
  //       {currentUser && currentUserModel && (
  //         <Sidebar menuConfig={menuConfig} currentUser={currentUserModel} />
  //       )}
  //       <main className={isSideBarHidden ? 'main-full' : 'main'}>
  //         {RoutesComponent}
  //       </main>
  //     </div>
  //   </HashRouter>
  // );
}

export default App;




// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { auth } from './firesbase.js';
// import Sidebar from './components/sidebar/sidebar.js';
// import Dashboard from './components/dashboard/dashboard.js';
// import Login from './components/login/login.js'; // Ensure this component is correctly imported
// import { PrivateRoute } from './components/private-route/private-route.js';
// import TAS from './components/tas/tas.js';
// import TopNavBar from './components/top-navbar/top-navbar.js';
// import Admin from './components/admin/admin.js';
// import Reports from './components/reports/reports.js';
// import TruckStopsList from './components/truckstops/truckstops.js';
// import Schedule from './components/schedule/schedule.js';
// import InAppMessages from './components/messages/inappmessages/inappmessages.js';
// import SMSMessages from './components/messages/smsmessages/smsmessages.js';
// import PushMessages from './components/messages/pushmessages/pushmessages.js';
// import Live from './components/live/live.js';
// import NewTruckStop from './components/newtruckstop/newtruckstop.js';
// import Settings from './components/settings/settings.js';
// import PortsRoutes from './components/route-configs/ports-routes.js';
// import NECRoutes from './components/route-configs/nec-routes.js';
// import { SitePresets, SiteConfig } from './site-config.js';

// function App() {
//   let RoutesComponent;
//   const selectedMenuItem = useSelector((state) => state.menu.selectedMenuItem);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [currentUserModel, setCurrentUserModel] = useState(null);
//   const [isSideBarHidden, setIsSideBarHidden] = useState(false);



//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(user => {
//       setCurrentUser(user);
//     });

//     return () => unsubscribe(); // Corrected to call unsubscribe function
//   }, []);

//   // Determine which routes to use based on the current preset
//   switch (SiteConfig.currentPreset) {
//     case SitePresets.PORTS:
//       RoutesComponent = <PortsRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />;
//       break;
//     case SitePresets.NEC:
//       RoutesComponent = <NECRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />;
//       break;
//     default:
//       RoutesComponent = <PortsRoutes currentUser={currentUserModel} siteConfig={SiteConfig} />; // Default to Ports if no match
//       break;
//   }

//   return (
//     <HashRouter>
//       {/* <Routes>

//         <Route path="/login" element={currentUser ? (
//           <Navigate to="/" replace />
//         ) : (
//           <Login />
//         )
//         } />

//         {RoutesComponent}



//       </Routes> */}



//       {RoutesComponent}


//     </HashRouter>
//   );
// }

// export default App; 