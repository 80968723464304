import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './archivetab.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';



function ArchiveTab() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (

        <div className={styles.tableRow}>
          
    <table id="events" class="events-table" cellspacing="0" width="100%">

<tbody>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td className={styles.from}>Clive Deamer</td>
        <td className={styles.message}>Hello - </td>
        <td className={styles.date}>12 Apr 2024</td>


    </tr>

    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td className={styles.from}>Alan Davey</td>
        <td className={styles.message}>Message</td>
        <td class="date">10 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Dave Brock</td>
        <td class="message">Message</td>
        <td class="date">9 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Simon House</td>
        <td class="message">Message</td>
        <td class="date">8 Apr 2024</td>


    </tr>

    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Paul Rudolph</td>
        <td class="message">Message</td>
        <td class="date">7 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Keith Hale</td>
        <td class="message">Message</td>
        <td class="date">6 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Harvey Bainbridge</td>
        <td class="message">Message</td>
        <td class="date">6 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Robert Calvert</td>
        <td class="message">Message</td>
        <td class="date">5 Apr 2024</td>


    </tr>
    <tr>

    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>


        <td class="from">Del Dettmar</td>
        <td class="message">Message</td>
        <td class="date">4 Apr 2024</td>


    </tr>







</tbody>
</table>
        </div>





    );
}

export default ArchiveTab;
