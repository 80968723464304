import React, { useState } from "react";
import styles from "./pushmessages.module.css";
import SelectAudience2 from "../ivs2/selectaudience2.js";
import Matrix from "../matrix";
import { dbCreateNewPushMessage } from "../../utils/db";

function PushMessages() {
  const [deliveryTime, setDeliveryTime] = useState("now");
  const [dateTime, setDateTime] = useState(null);
  const [action, setAction] = useState("timeline");
  const [transitions, setTransitions] = useState([]);
  const [sendToEveryone, setSendToEveryone] = useState(false);
  const [includeAudienceIds, setIncludeAudienceIds] = useState([]);
  const [excludeAudienceIds, setExcludeAudienceIds] = useState([]);

  const getDeliveryTimeForm = () => {
    switch (deliveryTime) {
      case "time":
        return (
          <input
            type="time"
            style={{ margin: 0 }}
            onChange={(e) => setDateTime(e.target.value)}
          />
        );
      case "scenario":
        return <Matrix callback={(ids) => setTransitions(ids)} />;
    }
    return null;
  };

  const handleSendToEveryoneChange = (e) => {
    setSendToEveryone(e.target.checked);
  };

  const handleIncludeAudienceChange = (audienceIds) => {
    setIncludeAudienceIds(audienceIds);
  };

  const handleExcludeAudienceChange = (audienceIds) => {
    setExcludeAudienceIds(audienceIds);
  };

  const saveMessage = async () => {
    const formData = new FormData(document.pushForm);
    const data = {
      ...Object.fromEntries(formData.entries()),
      includeAudienceIds,
      excludeAudienceIds,
      sendToEveryone,
    };

    if (deliveryTime === "scenario") {
      data.scenarioTransitions = transitions;
    } else if (deliveryTime === "time") {
      data.deliveryTime = dateTime;
    }

    await dbCreateNewPushMessage(data);
  };

  return (
    <form name="pushForm">
      <row>
        <double-column>
          <row>
            <h1>New push notification</h1>
            <row>
              <column>
                <label htmlFor="messageName">Message name</label>
                <input type="text" id="messageName" name="messageName"></input>
              </column>
              <column></column>
              <column></column>
            </row>

            <row>
              <column>
                <dashboard-container>
                  <SelectAudience2
                    sendToEveryone={sendToEveryone}
                    onSendToEveryoneChange={handleSendToEveryoneChange}
                    includeAudienceIds={includeAudienceIds}
                    excludeAudienceIds={excludeAudienceIds}
                    onIncludeAudienceChange={handleIncludeAudienceChange}
                    onExcludeAudienceChange={handleExcludeAudienceChange}
                  />
                </dashboard-container>
              </column>
            </row>

            <row>
              <dashboard-container>
                <row>
                  <column>
                    <h3>Message</h3>
                    <label htmlFor="messageTitle">Title</label>
                    <input
                      type="text"
                      id="messageTitle"
                      name="messageTitle"
                    ></input>
                  </column>
                  <column></column>
                  <column></column>
                </row>
                <row>
                  <half-column>
                    <label htmlFor="messageText">Message text</label>
                    <textarea rows="8" cols="10" name="messageText"></textarea>
                  </half-column>
                </row>

                <row>
                  <column>
                    <h3>Action when tapped</h3>
                    <label
                      className="radio-container"
                      onClick={() => setAction("timeline")}
                    >
                      Link to timeline
                      <input
                        type="radio"
                        name="action"
                        value="timeline"
                        checked={action === "timeline"}
                      ></input>
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="radio-container"
                      onClick={() => setAction("inapp")}
                    >
                      Link to in-app message
                      <input
                        type="radio"
                        name="action"
                        value="inapp"
                        checked={action === "inapp"}
                      ></input>
                      <span className="checkmark"></span>
                    </label>
                  </column>
                </row>
              </dashboard-container>
            </row>

            <row>
              <dashboard-container>
                <row>
                  <column>
                    <h3>Trigger</h3>
                    <label
                      class="radio-container"
                      onClick={() => setDeliveryTime("now")}
                    >
                      Immediately
                      <input
                        type="radio"
                        name="schedule"
                        value="immediately"
                        checked={deliveryTime === "now"}
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label
                      class="radio-container"
                      onClick={() => setDeliveryTime("time")}
                    >
                      Specific time
                      <input
                        type="radio"
                        name="schedule"
                        value="specificTime"
                        checked={deliveryTime === "time"}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      class="radio-container"
                      onClick={() => setDeliveryTime("scenario")}
                    >
                      Scenario Change
                      <input
                        type="radio"
                        name="schedule"
                        value="scenarioChange"
                        checked={deliveryTime === "scenario"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </column>
                </row>
                <row style={{ marginTop: 16 }}>{getDeliveryTimeForm()}</row>
              </dashboard-container>
            </row>

            <bottom-row>
              <button type="button" className="button bluebutton">
                Review and send
              </button>
              <button
                type="button"
                className="button whitebutton"
                onClick={saveMessage}
              >
                Save
              </button>
            </bottom-row>
          </row>
        </double-column>
      </row>
    </form>
  );
}

export default PushMessages;
