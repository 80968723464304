import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Marker,
  Polyline,
  Popup,
} from "react-leaflet";
// import { SearchControl, OpenStreetMapProvider } from 'react-leaflet-search';
import styles from "./live.module.css";
import { where } from "firebase/firestore";

import { TruckIcon } from "../tas/map-icons/truck-icon.js";
import { BlockIcon } from "../tas/map-icons/block-icon.js";
import { SpawnIcon } from "../tas/map-icons/spawn-icon.js";
import { PortalIcon } from "../tas/map-icons/portal-icon.js";
import { ServiceIcon } from "../tas/map-icons/serviceicon.js";
import ActiveScenariosPanel from "./activescenariospanel.js";
import {
  dbGetAllMessagesSeenByUser,
  dbGetAllScenarioMarkers,
  dbGetCurrentScenario,
  dbGetMostRecentLocations,
  getFirestoreDocs,
  getFirestoreDoc,
} from "../utils/db";

function Live() {
  const [route, setRoute] = useState(null);
  const [locations, setLocations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getLocations();
    getCurrentScenario();
  }, []);

  const getRoute = async (userId) => {
    const data = await getFirestoreDoc(
      "schedules",
      [where("user", "==", userId)],
      (doc) => ({
        id: doc.id,
        data: JSON.parse(doc.data().json_data),
        userId: doc.data().user,
      }),
    );
    setRoute(data);
  };

  const getLocations = async () => {
    const data = await dbGetMostRecentLocations();
    setLocations(data);
  };

  const getCurrentScenario = async () => {
    const data = await dbGetCurrentScenario();
    setCurrentScenario(data);
    getAllMarkers(data.id);
  };

  const getAllMarkers = async (id = null) => {
    const data = (await dbGetAllScenarioMarkers(id ?? currentScenario.id)).map(
      (obj) => ({ ...obj, toggled: false }),
    );
    console.log(data);
    setMarkers(data);
  };

  const getMessages = async (userId) => {
    const data = await dbGetAllMessagesSeenByUser(userId);
    console.log(data);
    setMessages(data);
  };

  const getMarkerIcon = (markerType) => {
    let icon;
    switch (markerType) {
      case "accident":
        icon = BlockIcon;
        break;
      case "spawn":
        icon = SpawnIcon;
        break;
      case "portal":
        icon = PortalIcon;
        break;
      case "service":
        icon = ServiceIcon;
        break;
      default:
        icon = BlockIcon;
    }
    return icon;
  };

  const toggleMessage = (index) => {
    setMessages((prev) =>
      prev.with(index, { ...prev[index], toggled: !prev[index].toggled }),
    );
  };

  // const provider = new OpenStreetMapProvider();

  const center = {
    lat: 51.505,
    lng: -0.09,
  };

  const colors = ["blue", "red"];

  return (
    <div className={styles.liveContainer}>
      <MapContainer
        center={center}
        zoom={13}
        scrollWheelZoom={true}
        className={styles.map}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
        {route &&
          route.data.route.legs.map((leg, index) => (
            <Polyline
              pathOptions={{ color: colors[index] }}
              positions={leg.route.path}
            />
          ))}
        {markers.map(({ latitude, longitude, name, markerType }) => (
          <Marker
            position={[latitude, longitude]}
            icon={getMarkerIcon(markerType)}
          ></Marker>
        ))}
        {locations.map(({ data, name }) => (
          <Marker
            position={[data.latitude, data.longitude]}
            icon={TruckIcon}
            eventHandlers={{
              click: () => {
                getRoute(data.userId);
                getMessages(data.userId);
              },
            }}
          >
            <Popup>
              <h2>{name}</h2>
              <hr />
              <div>Messages:</div>
              {/* TODO: remove inline styles */}
              <div
                style={{ display: "flex", gap: "8px", flexDirection: "column" }}
              >
                {messages.map(
                  ({ messageTitle, messageText, toggled }, index) => (
                    <div style={{ padding: "8px", backgroundColor: "#f6f7f899" }}>
                      <div
                        onClick={() => toggleMessage(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            transform: `rotate(${toggled ? 90 : 0}deg)`,
                          }}
                        >
                          {"> "}
                        </div>
                        {messageTitle}
                      </div>
                      {toggled && <div>{messageText}</div>}
                    </div>
                  ),
                )}
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      <map-column>
        <map-container>
          <ActiveScenariosPanel scenarioChangeCallback={getAllMarkers} />
        </map-container>
      </map-column>
    </div>
  );
}

export default Live;
