import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { blockVariants } from "./inappcontent";

function ContextMenu({ top, left, show, callback }) {
  return (
    <div
      id="context-menu"
      className="contextMenu"
      style={{ display: show ? "flex" : "none", top, left }}
    >
      <div onClick={() => callback("up")}>
        <NorthIcon fontSize="small" />
        <span>Move Up</span>
      </div>
      <div onClick={() => callback("down")}>
        <SouthIcon fontSize="small" />
        <span>Move Down</span>
      </div>
      <div onClick={() => callback("delete")}>
        <DeleteIcon fontSize="small" />
        <span>Delete</span>
      </div>
    </div>
  );
}

export default function ContentArea({
  // only props required for preview mode
  blocks,
  isPreview,
  defaultContent,

  // required for edit mode
  selectedChildIndex,
  setSelectedChildIndex,
  setBlocks,
  setBlockChild,
}) {
  const [menuProps, setMenuProps] = useState({
    top: 0,
    right: 0,
    show: false,
  });
  const [menuBlock, setMenuBlock] = useState(null);

  useEffect(() => {
    if (isPreview) {
      document.addEventListener("click", deselectMenu, true);
    }
    return isPreview
      ? () => {
          document.removeEventListener("click", deselectMenu, true);
        }
      : () => {};
  }, []);

  const deselectMenu = (e) => {
    const menu = document.getElementById("context-menu");
    if (e.target !== menu && !menu.contains(e.target)) {
      closeMenu();
    }
  };

  const showMenu = ({ pageX, pageY }) => {
    setMenuProps({ top: pageY, left: pageX, show: true });
  };

  const closeMenu = () => {
    setMenuProps((prev) => ({ ...prev, show: false }));
  };

  const flipArrayItemsInPlace = (arr, index, offset) => {
    arr.splice(index, 0, arr.splice(index + offset, 1)[0]);
  };

  const menuCallback = (action) => {
    setBlocks((prev) => {
      switch (action) {
        case "up":
          if (menuBlock > 0) {
            flipArrayItemsInPlace(prev, menuBlock, -1);
          }
          break;
        case "down":
          if (menuBlock < prev.length - 1) {
            flipArrayItemsInPlace(prev, menuBlock, 1);
          }
          break;
        case "delete":
          prev.splice(menuBlock, 1);
          break;
      }
      setSelectedChildIndex(null);
      return prev;
    });
    closeMenu();
  };

  const placeholder = () => (
    <div
      style={{
        textAlign: "center",
        padding: 16,
        backgroundColor: "#f6f7f8",
        height: "calc(100% - 32px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>Drag Content Here</span>
    </div>
  );

  const isSelectedChild = (blockIndex, childIndex) =>
    selectedChildIndex?.[0] === blockIndex &&
    selectedChildIndex?.[1] === childIndex;

  const getGridLayout = (blockIndex, spacing, children) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {!isPreview && (
          <IconButton
            aria-label="Block context menu"
            style={{ aspectRatio: "1", height: 30 }}
            onClick={(e) => {
              setMenuBlock(blockIndex);
              showMenu(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <div
          className="gridBlock"
          style={{
            gridTemplateColumns: spacing,
            ...(isPreview
              ? { border: "none", marginBottom: 0, backgroundColor: "white" }
              : {}),
          }}
        >
          {children.map((child, childIndex) => (
            <div
              className="blockChild"
              {...(!isPreview
                ? {
                    onDragOver: (e) => e.preventDefault(),
                    onDrop: (e) => contentDrop(e, blockIndex, childIndex),
                    onClick: () =>
                      setSelectedChildIndex([blockIndex, childIndex]),
                    style: {
                      border: isSelectedChild(blockIndex, childIndex)
                        ? "1px solid gray"
                        : "1px solid white",
                    },
                  }
                : {})}
            >
              {getContent(child, blockIndex, childIndex) ??
                (!isPreview && placeholder())}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getContent = (contentName, blockIndex, childIndex) => {
    if (!Object.keys(defaultContent).includes(contentName)) {
      return null;
    }

    const defaultObj = defaultContent[contentName];
    const content = !isPreview
      ? defaultObj.getElement(blockIndex, childIndex)
      : defaultObj.getPreview(blockIndex, childIndex);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {content}
      </div>
    );
  };

  const getVariantLayout = (blockIndex, block) => {
    const variant = blockVariants[block.variant];

    if (variant !== undefined) {
      return getGridLayout(
        blockIndex,
        variant.layout,
        block.children.slice(0, variant.maxLength),
      );
    }
    return <div>NO LAYOUT FOR THAT VARIANT</div>;
  };

  const contentDrop = (e, i, j) => {
    e.preventDefault();
    const child = e.dataTransfer.getData("text");
    setBlockChild(child, i, j);
  };

  return (
    <>
      {blocks.map((block, blockIndex) => getVariantLayout(blockIndex, block))}
      {!isPreview && <ContextMenu callback={menuCallback} {...menuProps} />}
    </>
  );
}
