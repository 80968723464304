import React, { useEffect, useRef, useState } from "react";
import styles from "./drop-pin.module.css";
import Tooltip from "@mui/material/Tooltip";
import { firestore } from "../../../firesbase.js";
import {
  collection,
  doc,
  deleteDoc,
  onSnapshot,
  where,
  query,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { BarChart } from "@mui/x-charts/BarChart";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import CarCrashOutlinedIcon from "@mui/icons-material/CarCrashOutlined";
import LandslideOutlinedIcon from "@mui/icons-material/LandslideOutlined";
import FloodOutlinedIcon from "@mui/icons-material/FloodOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";

function DropPin({ marker, setActiveMarker, position, exitTimes }) {
  const [accidentName, setAccidentName] = useState(marker.name ?? "");
  const [accidentDescription, setAccidentDescription] = useState(
    marker.description ?? "",
  );
  const [accidentType, setAccidentType] = useState(marker.markerType);
  const [portalFlow, setPortalFlow] = useState(marker.portalFlow);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const [relativePosition, setRelativePosition] = useState({ x: 0, y: 0 });

  const [isOpen, setIsOpen] = useState(false); // State to track if the dropdown is open

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectChange = (e) => {
    setAccidentType(e.target.value);
  };

  useEffect(() => {
    setAccidentName(marker.name ?? "");
    setAccidentDescription(marker.description ?? "");
    setAccidentType(marker.markerType ?? "");
  }, [marker]);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
  }, []);

  useEffect(() => {
    if (position) {
      let offsetX = width - 238;
      let offsetY = -height / 1.08;

      if (document.body.classList.contains("active")) {
        offsetX = width - 463;
        let offsetY = -height / 1.08;
      }

      const relX = position.x + offsetX;
      const relY = position.y + offsetY;

      setRelativePosition({ x: relX, y: relY });
    }
  }, [position, height, width]);

  const save = () => {
    const accidentDocRef = doc(firestore, "geopoints", marker.id); // Assuming `accidentId` is available in the component's props
    try {
      setDoc(
        accidentDocRef,
        {
          name: accidentName,
          description: accidentDescription,
          markerType: accidentType,
          portalFlow: portalFlow,
        },
        { merge: true },
      );
      setActiveMarker(null);
      // markerRef.closePopup();
      console.log("Accident name saved successfully");
    } catch (error) {
      console.error("Error saving accident name:", error);
    }
  };

  const deleteMarker = async () => {
    const accidentDocRef = doc(firestore, "geopoints", marker.id);
    try {
      await deleteDoc(accidentDocRef);
      setActiveMarker(null); // Assuming this function is used to unset the active marker in the parent component
      console.log("Marker deleted successfully");
    } catch (error) {
      console.error("Error deleting marker:", error);
    }
  };

  if (!position) return null;

  const style = {
    position: "absolute",
    left: relativePosition.x + "px",
    top: relativePosition.y + "px",
    zIndex: 999999999,
  };

  const getColorForValue = (value) => {
    if (value >= 0 && value < 16) {
      return "#d2e6bd";
    } else if (value >= 16 && value < 20) {
      return "#f9e4ae";
    } else {
      return "#f7c7c9";
    }
  };

  const handleDropdownItemClick = (value) => {
    setAccidentType(value);
    setIsOpen(false); // Close the dropdown after selecting an item
  };

  return (
    <div>
      <droppin-dialog className={styles.dropPinDialog} style={style} ref={ref}>
        <div className={styles.dropPinContainer}>
          <column>
            {/* <column>
                            <div className={styles.text}>
                                <label htmlFor="pinName">Name</label>
                                <input type="text" id="pinName" name="pinName" className={styles.text} value={accidentName} onChange={(e) => setAccidentName(e.target.value)} />
                            </div>

                        </column> */}

            <row>
              <column>
                <label htmlFor="pinName">Name</label>
                <input
                  type="text"
                  id="pinName"
                  name="pinName"
                  className={`${styles.text} ${styles.input}`}
                  value={accidentName}
                  onChange={(e) => setAccidentName(e.target.value)}
                />
              </column>
            </row>
            <row>
              <column>
                <label htmlFor="pinDescription">Description</label>
                <textarea
                  id="pinDescription"
                  rows="3"
                  className={styles.textarea}
                  value={accidentDescription}
                  onChange={(e) => setAccidentDescription(e.target.value)}
                ></textarea>
              </column>
            </row>

            <row>
              <div>
                <div className={styles.dropDown}>
                  <button className={styles.dropbtn} onClick={toggleDropdown}>
                    {accidentType ? `Type - ${accidentType}` : "Select"}{" "}
                    <ExpandMoreOutlinedIcon />
                  </button>
                  <div
                    className={styles.dropdownContent}
                    style={{ display: isOpen ? "block" : "none" }}
                  >
                    <a onClick={() => handleDropdownItemClick("spawn")}>
                      Start
                    </a>
                    <a onClick={() => handleDropdownItemClick("portal")}>
                      Portal
                    </a>
                    <a onClick={() => handleDropdownItemClick("service")}>
                      Service
                    </a>
                    <a onClick={() => handleDropdownItemClick("accident")}>
                      Accident
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className={`${styles.dropdown} ${styles.customSelect}`}> 
                                <select value={accidentType} onChange={handleSelectChange}>
                                    <option value="spawn">Spawn</option>
                                    <option value="portal">Portal</option>
                                    <option value="service">Service</option>
                                    <option value="accident">Accident</option>
                                </select>
                            </div> */}

              {/* {accidentType == "portal" && (
                                <div>
                                    <input
                                        type="range"
                                        value={portalFlow}
                                        onChange={(e) => setPortalFlow(e.target.value)}
                                        min="1"
                                        max="20"
                                        step="2"
                                    // className={styles.simulationSpeedSlider}
                                    // disabled={isDisabled}
                                    />
                                    <br />
                                    <label className={styles.simulationSpeedLabel}>
                                        Trucks per hour: {portalFlow}
                                    </label>
                                </div>
                            )
                            } */}
            </row>

            {exitTimes[marker.id] && (
              <BarChart
                xAxis={[
                  { scaleType: "band", data: exitTimes[marker.id].labels },
                ]}
                series={[{ data: exitTimes[marker.id].data }]}
                height={180}
                // colors={colors}
                margin={{ top: 20, right: 0, bottom: 20, left: 20 }}
              />
            )}

            {/* <div className={styles.iconsRow}>

                        <Tooltip title="Road traffic accident" arrow>
                            <CarCrashOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Pedestrian" arrow>
                            <DirectionsWalkOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Snow/ice" arrow>
                            <AcUnitOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Hazardous spill" arrow>
                            <ScienceOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Flood" arrow>
                            <FloodOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Landslide" arrow>
                            <LandslideOutlinedIcon className={styles.icon} />
                        </Tooltip>
                    </div>

                    <div className={styles.iconsRow}>

                        <Tooltip title="Road traffic accident" arrow>
                            <CarCrashOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Pedestrian" arrow>
                            <DirectionsWalkOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Snow/ice" arrow>
                            <AcUnitOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Hazardous spill" arrow>
                            <ScienceOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Flood" arrow>
                            <FloodOutlinedIcon className={styles.icon} />
                        </Tooltip>

                        <Tooltip title="Landslide" arrow>
                            <LandslideOutlinedIcon className={styles.icon} />
                        </Tooltip>


                    </div> */}
          </column>
          <div className={styles.bottomRow}>
            <button
              className={styles.whiteButton}
              onClick={() => deleteMarker()}
            >
              Delete
            </button>
            <button className={styles.blueButton} onClick={() => save()}>
              Save
            </button>
          </div>

          {/* <div className={styles.bottomRow} >
                    <button className={styles.whiteButton} onClick={() => setIsDropPinOpen(false)}>Delete</button>
                    <button className={styles.blueButton} onClick={() => setIsDropPinOpen(false)}>Save</button>
                </div> */}
        </div>

        {/* {!showSaveButton && <input type="button" onClick={() => save()} value={"Save"}></input>}
            {showSaveButton && <input type="button" onClick={() => setAutoOpenPopUpMarker('')} value={"close"}></input>} */}
      </droppin-dialog>
    </div>
  );
}

export default DropPin;
