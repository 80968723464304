import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMapEvents } from 'react-leaflet'
import { Polyline } from 'react-leaflet';
import Chart from 'chart.js/auto';
import { Bar } from 'chart.js';
import styles from './tas.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import styles from './map-icons.module.css';

// import { collection, doc, onSnapshot, addDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc, getDocs } from 'firebase/firestore';
import { RouteService } from '../../api/route-service.js';
import { GlobalVariables } from '../utils/global-variables.js';
import { SimulationManagerTesting } from './simulation/simulation-manager-testing.js';
import { SimulationManager } from './simulation/simulation-manager.js';
import { SimulationUIManager } from './simulation/simulation-ui-manager.js';
import { AccidentsPanel } from './accidents/accidents-panel.js';
import { MapEventsHandler } from './map-events-handler.js'
import { BlockIcon } from './map-icons/block-icon.js';
import { SpawnIcon } from './map-icons/spawn-icon.js';
import { PortalIcon } from './map-icons/portal-icon.js';
import { TruckIcon } from './map-icons/truck-icon.js';
import { AppTruckIcon } from './map-icons/app-truck-icon.js';

// import { ServiceIcon } from './map-icons/service-icon.js';

import { ServiceIcon } from './map-icons/serviceicon.js';

import { ScenariosPanel } from './scenarios/scenarios-panel.js';
import { UsersPanel } from './users/users-panel.js';
import { SimulationSpeedPanel } from './simulation-speed/simulation-speed-panel.js';
import { BarChart } from '@mui/x-charts/BarChart';
import { CustomTime } from './simulation/custom-time.js';
import ScenarioRoadBlocks from './scenarios/scenario-road-blocks.js';
import { AddMarkerPanel } from './add-marker/add-marker-panel.js';
import DropPin from './scenarios/drop-pin.js';
import AddScenarioModal from './scenarios/add-scenario-modal.js';

import RoutingControl from './routing/routing-control.js'
import { AddRoutePanel } from './add-route/add-route-panel.js';
import { SiteConfig } from '../../site-config.js';
import TruckModal from './scenarios/truck-modal.js';




function TestingTAS({ siteConfig }) {
    // const [map, setMap] = useState(null);
    const map = useRef(null);
    const [markers, setMarkers] = useState([]);
    const [accidentMarkers, setAccidentMarkers] = useState([]);
    const [addingAccident, setAddingAccident] = useState(false);
    const [scenarioId, setScenarioId] = useState(null);
    const [scenario, setScenario] = useState(null);
    const [simulationSpeed, setSimulationSpeed] = useState(10);
    const [truckSpawnRate, setTruckSpawnRate] = useState(10);

    const [customTime, setCustomTime] = useState(new CustomTime());
    const [enableScheduler, setEnableScheduler] = useState(true);
    const [isScenarioRoadBlocksOpen, setIsScenarioRoadBlocksOpen] = useState(false);
    const [isAddScenarioOpen, setIsAddScenarioOpen] = useState(false);
    const [isEditScenarioOpen, setIsEditScenarioOpen] = useState(false);
    const [doverChartData, setDoverChartData] = useState([{
        labels: [],
        data: []
    }]);
    const [exitTimes, setExitTimes] = useState({});
    // const [markerUpdates, setMarkerUpdates] = useState(0);
    const [autoOpenPopUpMarker, setAutoOpenPopUpMarker] = useState('');
    const routeService = new RouteService(SiteConfig.routeServerBaseUrl);


    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const [activeMarker, setActiveMarker] = useState(null);
    const [activeTruckMarker, setActiveTruckMarker] = useState(null);
    const activeTruckMarkerRef = useRef(null);
    useEffect(() => {
        activeTruckMarkerRef.current = activeTruckMarker;
    }, [activeTruckMarker]);
    const [truckInstructionText, setTruckInstructionText] = useState('');
    const [bearing, setBearing] = useState(0);
    const [truckMarkerPosition, setTruckMarkerPosition] = useState(null);
    const [dropPinPosition, setDropPinPosition] = useState(null);
    // const [dropPinPosition, setResetUserLocation] = useState(null);

    // simulated user code below
    const resetUserLocation = async (userId) => {
        // rest the simulated user location in the database to the spawn location
        //get the spawn point from the simulator:
        let spawnPoint = simulationManagerTestingRef.current.spawnPoints[0];
        simulationManagerTestingRef.current.resetTruckLocation(userId);
        const userRef = doc(firestore, 'simulatedLocation', userId);
        await setDoc(userRef, { location: { latitude: spawnPoint.latitude, longitude: spawnPoint.longitude } }, { merge: true });
    };

    useEffect(() => {
        const scenariosCollection = collection(firestore, 'scenarios');
        const q = query(scenariosCollection, where('status', '==', 'active'));

        getDocs(q)
            .then(querySnapshot => {
                if (!querySnapshot.empty) {
                    const activeScenario = querySnapshot.docs[0];
                    setScenarioId(activeScenario.id);
                } else {
                    console.log('No active scenario found');
                    // Optionally, you could set the scenarioId to null or handle this case as needed
                    // setScenarioId(null);
                }
            })
            .catch(error => {
                console.error('Error fetching active scenario:', error);
            });

    }, []);

    useEffect(() => {
        if (scenarioId) {
            const scenarioRef = doc(firestore, "scenarios", scenarioId);
            const accidentsCollection = collection(firestore, 'geopoints');
            const q = query(accidentsCollection, where('scenarios', 'array-contains', scenarioRef));
            const unsubscribe = onSnapshot(q, async (snapshot) => {
                const updatedAccidents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                //clear the accidents in the routing server
                await routeService.clearAccidents();
                setAccidentMarkers(prev => []);
                // add each accident with addAccidentMarker
                updatedAccidents.forEach(accident => {
                    if (accident.latitude != null && accident.longitude != null) {

                        if (accident.markerType == "accident") {
                            routeService.addAccident({
                                lat: accident.latitude,
                                lng: accident.longitude
                            });
                        }
                        let marker = accident;
                        accident.position = { lat: accident.latitude, lng: accident.longitude }
                        setAccidentMarkers(prev => [...prev, marker]);
                    }
                });
            });

            return () => unsubscribe();
        }
    }, [scenarioId]);

    const onMapDrag = (e) => {
        console.log(e);
        if (activeMarker) {
            const screenPos = map.current.latLngToContainerPoint(activeMarker.position);
            setDropPinPosition(screenPos);
        }
        if (activeTruckMarkerRef.current) {
            const screenPos = map.current.latLngToContainerPoint(activeTruckMarkerRef.current.position);
            setDropPinPosition(screenPos);
        }
    }

    const handleMapClick = (e) => {
        setActiveMarker(null);
        setActiveTruckMarker(null);
        activeTruckMarkerRef.current = null;
    }


    const openMarker = async (marker) => {
        await map.current.setView([marker.position.lat, marker.position.lng], map.current.getZoom())
        setTimeout(() => {
            setActiveMarker(marker);
            setActiveTruckMarker(null);
            const screenPos = map.current.latLngToContainerPoint(marker.position);
            setDropPinPosition(screenPos);
        }, 500); // Delay of 500 milliseconds        
    };

    const openTruckMarker = async (marker) => {
        await map.current.setView([marker.position.lat, marker.position.lng], map.current.getZoom());
        setActiveTruckMarker(marker);
        setActiveMarker(null);
        setTimeout(() => {

            activeTruckMarkerRef.current.position = marker.position;
            setTruckInstructionText(marker.instructionText);
            setBearing(marker.bearing);
            setTruckMarkerPosition(marker.position);
            const screenPos = map.current.latLngToContainerPoint(marker.position);
            setDropPinPosition(screenPos);
        }, 500);
    };

    const addMarker = (markerData) => {
        setMarkers(prev => [...prev, markerData]);
    };

    const updateMarker = (id, newPosition, instructionText, bearing, isDummy) => {
        setMarkers(prev => prev.map(marker => marker.id === id ? { ...marker, position: newPosition, instructionText: instructionText, bearing: bearing, isDummy: isDummy } : marker));

        if (activeTruckMarkerRef.current && activeTruckMarkerRef.current.id === id) {
            activeTruckMarkerRef.current.position = newPosition;
            setTruckInstructionText(instructionText);
            setBearing(bearing);
            setTruckMarkerPosition(newPosition);
            const screenPos = map.current.latLngToContainerPoint(newPosition);
            setDropPinPosition(screenPos);
        }
    };

    const removeMarker = (id) => {
        setMarkers(prev => prev.filter(marker => marker.id !== id));
        if (activeTruckMarkerRef.current && activeTruckMarkerRef.current.id === id) {
            setActiveTruckMarker(null);
        }
    };

    const simulationManagerRef = useRef(null);
    const simulationManagerTestingRef = useRef(null);
    const simulationUIManagerRef = useRef(null);

    if (!simulationManagerTestingRef.current) {
        simulationUIManagerRef.current = new SimulationUIManager(map, document.getElementById('vehicle-panel'), addMarker, updateMarker, removeMarker);
        simulationManagerTestingRef.current = new SimulationManagerTesting(100, simulationSpeed, truckSpawnRate, scenarioId, simulationUIManagerRef.current, customTime, setDoverChartData, enableScheduler, setExitTimes);

        // if (!simulationManagerRef.current) {
        //     simulationManagerRef.current = new SimulationManager(100, simulationSpeed, truckSpawnRate, scenarioId, simulationUIManagerRef.current, customTime, setDoverChartData, enableScheduler, setExitTimes);
        // }
    }



    const center = {
        lat: 51.505,
        lng: -0.09,
    };

    useEffect(() => {

        if (map) {
            // Example function to start simulation
            function startSimulation() {
                // Simulation start logic
                // console.log(simulationManagerRef.current);
                simulationManagerTestingRef.current.startSimulation(true);
            }

            startSimulation();
        }

        return () => {
        };
    }, [map]);

    useEffect(() => {
        simulationManagerTestingRef.current.updateSimulationSpeed(simulationSpeed, truckSpawnRate);
        // simulationManagerRef.current.updateSimulationSpeed(simulationSpeed, truckSpawnRate);
    }, [simulationSpeed, truckSpawnRate]);

    useEffect(() => {
        simulationManagerTestingRef.current.updateScenarioId(scenarioId);
        // simulationManagerRef.current.updateScenarioId(scenarioId, true);
    }, [scenarioId]);

    useEffect(() => {
        simulationManagerTestingRef.current.updateEnableScheduler(enableScheduler);
        // simulationManagerRef.current.updateEnableScheduler(enableScheduler);
    }, [enableScheduler]);

    return (
        <div className={styles.tasContainer}>
            <MapContainer
                center={center}
                zoom={13}
                scrollWheelZoom={true}
                className={styles.map}
                zoomControl={false}
                ref={map}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position="topright" className={styles.zoomControl} />

                <MapEventsHandler
                    onMapClick={handleMapClick}
                    onMapDrag={onMapDrag}
                // addingRoute={addingRoute}
                // handleRouteMapClick={handleRouteMapClick}
                // handleAddWaypoint={handleAddWaypoint}
                />

                {markers.map(marker => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={marker.isDummy ? TruckIcon : AppTruckIcon}
                        eventHandlers={{
                            click: () => openTruckMarker(marker),
                            // dragend: (e) => {
                            //     // const latLng = e.target.getLatLng();
                            //     // const accidentDocRef = doc(firestore, 'geopoints', marker.id); // Assuming `accidentId` is available in the component's props
                            //     try {
                            //         // setDoc(accidentDocRef, { latitude: latLng.lat, longitude: latLng.lng }, { merge: true });
                            //         setActiveTruckMarker(null);
                            //         // markerRef.closePopup();
                            //         console.log('Accident name saved successfully');
                            //     } catch (error) {
                            //         console.error('Error saving accident name:', error);
                            //     }
                            //     // const marker = markerRef.current
                            //     // if (marker != null) {
                            //     //     setPosition(marker.getLatLng())
                            //     // }
                            // },
                        }}>
                    </Marker>
                ))}

                {/* {routeMarkers.map(marker => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={TruckIcon}
                    >
                    </Marker>
                ))} */}

                {accidentMarkers.map(marker => {
                    let icon;
                    switch (marker.markerType) {
                        case 'accident':
                            icon = BlockIcon;
                            break;
                        case 'spawn':
                            icon = SpawnIcon;
                            break;
                        case 'portal':
                            icon = PortalIcon;
                            break;
                        case 'service':
                            icon = ServiceIcon;
                            break;
                        default:
                            icon = BlockIcon; // Default to BlockIcon if no type matches
                    }

                    // {accidentMarkers.map(marker => {
                    //   const IconComponent = getIcon(marker.markerType);

                    return (
                        <Marker
                            key={marker.id}
                            position={marker.position}
                            icon={icon}
                            className="testy"
                            eventHandlers={{
                                click: () => openMarker(marker),
                                dragend: (e) => {
                                    const latLng = e.target.getLatLng();
                                    const accidentDocRef = doc(firestore, 'geopoints', marker.id); // Assuming `accidentId` is available in the component's props
                                    try {
                                        setDoc(accidentDocRef, { latitude: latLng.lat, longitude: latLng.lng }, { merge: true });
                                        setActiveMarker(null);
                                        // markerRef.closePopup();
                                        console.log('Accident name saved successfully');
                                    } catch (error) {
                                        console.error('Error saving accident name:', error);
                                    }
                                    // const marker = markerRef.current
                                    // if (marker != null) {
                                    //     setPosition(marker.getLatLng())
                                    // }
                                },
                            }}
                            draggable={true}

                        >
                        </Marker>
                    );
                })}

                {/* {routes.map((route) => (
                    <React.Fragment key={route.id}>
                        {route.waypoints.map((waypoint, index) => {
                            if (index === 0 || index === route.waypoints.length - 1) {
                                return (
                                    <Marker
                                        key={`${route.id}-${index}`}
                                        position={[waypoint.lat, waypoint.lng]}
                                        icon={TruckIcon}
                                    >
                                        <Popup>
                                            <div>
                                                <p>Route: {route.id}</p>
                                                <button onClick={() => handleDeleteRoute(route.id)}>Delete Route</button>
                                            </div>
                                        </Popup>
                                    </Marker>
                                );
                            }
                            return null;
                        })}
                        <Polyline
                            positions={route.waypoints.map((waypoint) => [waypoint.lat, waypoint.lng])}
                            color="#6FA1EC"
                            weight={4}
                            eventHandlers={{
                                click: () => handlePolylineClick(route.id),
                            }}
                        />
                        {activeRouteId === route.id && (
                            <Popup position={route.waypoints[0]}>
                                <div>
                                    <p>Route: {route.id}</p>
                                    <button onClick={() => handleDeleteRoute(route.id)}>Delete Route</button>
                                </div>
                            </Popup>
                        )}
                    </React.Fragment>
                ))} */}

                {/* <RoutingControl
                    ref={routingControlRef}
                    waypoints={routingWaypoints}
                    lineOptions={{
                        styles: [{ color: "#6FA1EC", weight: 4 }],
                    }}
                    show={true}
                    addWaypoints={false}
                    routeWhileDragging={true}
                    draggableWaypoints={true}
                    fitSelectedRoutes={true}
                    showAlternatives={false}
                /> */}

            </MapContainer>

            {
                activeMarker && dropPinPosition && (
                    <DropPin marker={activeMarker} setActiveMarker={setActiveMarker} position={dropPinPosition} exitTimes={exitTimes} />
                )
            }

            {
                (activeTruckMarkerRef.current && !activeMarker) && dropPinPosition && (
                    <TruckModal truckMarker={{ ...activeTruckMarkerRef.current }} instructionText={truckInstructionText} bearing={bearing} truckMarkerPosition={truckMarkerPosition} setActiveMarker={setActiveTruckMarker} position={dropPinPosition} />
                )
            }

            {doverChartData && doverChartData['0'] && doverChartData['0'].labels.length > 0 && (<map-row>
                <map-container>

                    {/* <Tabs
                      indicatorColor="transparent" 
                      className={styles.tabs}
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Graph Tabs">
                        {doverChartData.map((chartData, index) => (
                            <Tab label={`${chartData.name}`} key={index} />
                        ))}
                    </Tabs> */}



                    <Tabs
                        indicatorColor="transparent"
                        className={styles.tabs}
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Graph Tabs"
                    >
                        {doverChartData.map((chartData, index) => (
                            <Tab
                                className={`${styles.tab} ${selectedTab === index ? styles.activeTab : ''}`}
                                label={`${chartData.name}`}
                                key={index}
                            />
                        ))}
                    </Tabs>
                    {doverChartData.map((chartData, index) => (
                        <div
                            role="tabpanel"
                            hidden={selectedTab !== index}
                            id={`tabpanel-${index}`}
                            aria-labelledby={`tab-${index}`}
                            key={index}>
                            {selectedTab === index && (
                                <div>
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: chartData.labels }]}
                                        series={[{ data: chartData.data }]}
                                        height={200}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </map-container>
            </map-row>)}

            {/* <map-column>
                <map-container>
                    <ScenariosPanel setScenarioId={setScenarioId} scenarioId={scenarioId} setScenario={setScenario} setIsScenarioRoadBlocksOpen={setIsScenarioRoadBlocksOpen} setIsAddScenarioOpen={setIsAddScenarioOpen} setIsEditScenarioOpen={setIsEditScenarioOpen} siteConfig={siteConfig} />
                </map-container>
            </map-column> */}

            <simulation-panel2>
                <map-container>
                    <SimulationSpeedPanel simulationSpeed={simulationSpeed} setSimulationSpeed={setSimulationSpeed} customTime={customTime} enableScheduler={enableScheduler} setEnableScheduler={setEnableScheduler} truckSpawnRate={truckSpawnRate} setTruckSpawnRate={setTruckSpawnRate} scenarioId={scenarioId} isTesting={true} />
                </map-container>
            </simulation-panel2>

            <users-panel>
                <map-container>
               
                    <UsersPanel resetUserLocation={resetUserLocation} />
               
                </map-container>
            </users-panel>

            <roadblock-dialog>
                <map-container>
                    <ScenarioRoadBlocks
                        isOpen={isScenarioRoadBlocksOpen}
                        setIsScenarioRoadBlocksOpen={setIsScenarioRoadBlocksOpen}
                        accidentMarkers={accidentMarkers}
                        activeMarker={activeMarker}
                        setActiveMarker={setActiveMarker}
                        openMarker={openMarker}
                    // scenarioId={scenarioId}
                    // addAccidentMarker={addAccidentMarker}
                    // clearAccidents={clearAccidents}
                    />
                </map-container>
            </roadblock-dialog>


            {/* <div style={{ top: "100px", left: "800px", position: "absolute", zIndex: 99999 }}>
                <AddMarkerPanel toggleAddingAccident={toggleAddingAccident} addingAccident={addingAccident} scenarioId={scenarioId} />
            </div>

            <div style={{ top: "150px", left: "800px", position: "absolute", zIndex: 99999 }}>
                <AddRoutePanel
                    toggleAddingRoute={toggleAddingRoute}
                    addingRoute={addingRoute}
                    scenarioId={scenarioId}
                    createRoute={createRoute}
                    routingWaypoints={routingWaypoints}
                    setRoutingWaypoints={setRoutingWaypoints}
                    onRouteFinish={onRouteFinish}
                    routingControlRef={routingControlRef}
                />
            </div> */}

            <div>
                <AddScenarioModal scenarioId={scenarioId} scenario={scenario} isEditScenarioOpen={isEditScenarioOpen} setIsEditScenarioOpen={setIsEditScenarioOpen} isAddScenarioOpen={isAddScenarioOpen} setIsAddScenarioOpen={setIsAddScenarioOpen} />
            </div>


        </div>
    );
}

export default TestingTAS;
