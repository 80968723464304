import React, { useState } from 'react';

import styles from './smsmessages.module.css';

import SelectAudience from '../audience/selectaudience.js';



function SMSMessages() {


    return (
        <row>
            <double-column>
                <row>
                    <h1>New SMS message</h1>
                    <row>
                        <column>
                            <label for="messageName">Message name</label>
                            <input type="text" id="messageName" name="messageName"></input>
                        </column>
                        <column>
                        </column>
                        <column>
                        </column>
                    </row>

                    <row>
                        <column>
                            <dashboard-container>
                                <SelectAudience></SelectAudience>
                            </dashboard-container>
                        </column>
                    </row>
                    <dashboard-container>
                        <row>
                            <half-column>
                                <h3>Message</h3>
                                <label for="emailSubject">Message text</label>
                                <textarea rows="8"></textarea>
                            </half-column>
                        </row>
                    </dashboard-container>
                    <row>
                        <column>
                            <dashboard-container>
                                <row>
                                    <h3>Delivery schedule</h3>
                                </row>
                                <row>
                                    <row>
                                        <p>When should this message start sending?</p>
                                    </row>



                                    <column>

                                        <label class="radio-container">Immediately
                                            <input type="radio" name="radio"></input>
                                            <span class="checkmark"></span>
                                        </label>

                                        <label class="radio-container">Specific time
                                            <input type="radio" name="radio"></input>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="radio-container">Link to a scenario
                                            <input type="radio" name="radio"></input>
                                            <span class="checkmark"></span>
                                        </label>

                                    </column>

                                </row>
                            </dashboard-container>
                            <bottom-row>
                                <button class="button bluebutton" href="sign-in.html">Review and send</button>
                                <button class="button whitebutton" href="truck-stops.html">Save</button>
                            </bottom-row>

                        </column>
                    </row>
                </row>

            </double-column>






        </row>
    );
}

export default SMSMessages;
