import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './senttab.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';




function SentTab() {
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleOpenModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    // };

    return (

        <div className={styles.tableRow}>

      

            <table id="events" class="events-table" cellspacing="0" width="100%">

                <tbody>
                    <tr>

                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>


                        <td className={styles.from}>Terry Ollis </td>
                        <td className={styles.message}>Hello - </td>
                        <td className={styles.date}>12 Apr 2024</td>


                    </tr>

                    <tr>

                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>


                        <td className={styles.from}> 	Mick Slattery </td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>10 Apr 2024</td>


                    </tr>
                    <tr>

                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>


                        <td className={styles.from}>Nik Turner</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>9 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from"> 	John Harrison </td>
                        <td class="message">Message</td>
                        <td class="date">8 Apr 2024</td>


                    </tr>

                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Huw Lloyd-Langton</td>
                        <td class="message">Message</td>
                        <td class="date">7 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Dave Anderson </td>
                        <td class="message">Message</td>
                        <td class="date">6 Apr 2024</td>


                    </tr>
                    <tr>
                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Lemmy Kilmister</td>
                        <td class="message">Message</td>
                        <td class="date">6 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Simon House</td>
                        <td class="message">Message</td>
                        <td class="date">5 Apr 2024</td>


                    </tr>
                    <tr>

                    <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />

                            </div>

                        </td>

                        <td class="from">Alan Powell</td>
                        <td class="message">Message</td>
                        <td class="date">4 Apr 2024</td>


                    </tr>







                </tbody>
            </table>
        </div>
    );
}

export default SentTab;
