import React, { useState, useRef, useEffect } from "react";
import styles from "./ivs2.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CircularSlider from './circularslider';
import { MapContainer, TileLayer, Marker, ZoomControl, useMap } from "react-leaflet";
import L from "leaflet";
import arrowSvg from "./arrow.svg";
import shadow from "./truck.svg";

function IVSMapModal({ isOpen, onClose, initialLocation, initialBearing, onAngleChange }) {
  const defaultCenter = { lat: 51.505, lng: -0.09 };
  const [markerPosition, setMarkerPosition] = useState(
    initialLocation ? { lat: initialLocation[0], lng: initialLocation[1] } : defaultCenter
  );
  const [angle, setAngle] = useState(initialBearing || 0);

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (initialLocation) {
      setMarkerPosition({ lat: initialLocation[0], lng: initialLocation[1] });
    }
    if (initialBearing !== undefined) {
      setAngle(initialBearing);
    }
  }, [initialLocation, initialBearing]);

  const markerIcon = new L.icon({
    iconUrl: arrowSvg,
    iconRetinaUrl: arrowSvg,
    shadowUrl: shadow,
    shadowSize: new L.Point(32, 32),
    shadowAnchor: [16, 15],
    iconSize: new L.Point(32, 32),
    className: "arrowMarker",
  });

  if (!isOpen) return null;

  const getReturnValues = () => ({
    bearing: +angle,
    position: [markerPosition.lat, markerPosition.lng],
  });

  const handleAngleChange = (newAngle) => {
    setAngle(newAngle);
    if (markerRef.current) {
      markerRef.current.setRotationAngle(newAngle);
    }
    onAngleChange(newAngle);
  };

  const MapController = () => {
    const map = useMap();
    useEffect(() => {
      map.setView([markerPosition.lat, markerPosition.lng], map.getZoom());
    }, [map]);
    return null;
  };

  return (
    <div className={styles.modalOverlay} onClick={() => onClose(getReturnValues())}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Add pins
          <CloseOutlinedIcon onClick={() => onClose(getReturnValues())} className={styles.closeIcon} />
        </settings-row>
        <div style={{ position: "relative" }}>
          <MapContainer
            center={[markerPosition.lat, markerPosition.lng]}
            zoom={13}
            scrollWheelZoom={true}
            className={styles.map}
            zoomControl={false}
            ref={mapRef}
          >
            <MapController />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomControl position="topright" className={styles.zoomControl} />
            <Marker
              position={markerPosition}
              draggable={true}
              ref={markerRef}
              rotationAngle={angle}
              icon={markerIcon}
              eventHandlers={{
                dragend: () => {
                  if (markerRef.current) {
                    setMarkerPosition(markerRef.current.getLatLng());
                  }
                },
              }}
            />
          </MapContainer>
          <div className={styles.mapRangeContainer}>
            <CircularSlider
              initialAngle={angle}
              onChange={handleAngleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IVSMapModal;