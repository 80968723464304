import React, { useEffect, useState } from "react";
import styles from "./selectaudience.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";
// import Modal from "../audience/createaudience/createaudiencepopup.js";
import Modal from "../audience/createaudience/createaudiencepopup.js";
import { firestore } from "../../../firesbase.js";
import { collection, getDocs } from "firebase/firestore";
import { dbGetAllAudiences } from "../../utils/db";

function SelectAudiencePopUp({ children, isOpen, onClose }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);

  useEffect(() => {
    getAudiences();
  }, []);

  const getAudiences = async () => {
    const data = await dbGetAllAudiences();
    setAudiences(data);
    setSelectedIndices(new Array(data.length).fill(false));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getAudiences();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Select audience
          <CloseOutlinedIcon onClick={onClose} className={styles.closeIcon} />
        </settings-row>

        {audiences.map((audience, index) => (
          <row key={index}>
            <label
              className="checkbox-container audiencebutton"
              style={{ margin: "8px 0" }}
            >
              {audience.name}
              <input
                type="checkbox"
                name={"audience-" + audience.id}
                checked={selectedIndices[index]}
                onClick={() =>
                  setSelectedIndices((prev) => [
                    ...prev.slice(0, index),
                    true,
                    ...prev.slice(index + 1, prev.length),
                  ])
                }
              />
              <span
                className="checkbox-checkmark"
                style={{ position: "relative" }}
              ></span>
            </label>
          </row>
        ))}

        <popup-row>
          <Link
            to="/manageaudiences"
            className="button whitebutton"
            type="button"
          >
            Manage audiences
          </Link>

          <button
            onClick={handleOpenModal}
            className="button bluebutton"
            type="button"
          >
            Create audience
          </button>
        </popup-row>

        <button
          type="button"
          class="button bluebutton"
          style={{ width: "100%" }}
          onClick={() => {
            onClose(
              audiences
                .filter((_, index) => selectedIndices[index])
                .map((audience) => audience.id),
            );
            setSelectedIndices(new Array(audiences.length).fill(false));
          }}
        >
          Save
        </button>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}></Modal>
    </div>
  );
}

export default SelectAudiencePopUp;
