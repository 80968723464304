import React, { useEffect, useState } from 'react';
import styles from './selectaudience.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Link } from 'react-router-dom';
import Modal from '../audience/createaudience/createaudiencepopup.js';
import { firestore } from "../../../firesbase.js";
import { collection, getDocs } from 'firebase/firestore';

function SelectAudiencePopUp2({ isOpen, onClose, onSelectAudience, includedAudiences, excludedAudiences }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [audiences, setAudiences] = useState([]);

    useEffect(() => {
        const getAudiences = async () => {
            const querySnapshot = await getDocs(collection(firestore, 'audiences'));
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push(doc.data().name);
            });
            setAudiences(data);
        };
        getAudiences();
    }, []);

    const filteredAudiences = audiences.filter(audience => 
        !includedAudiences.includes(audience) && !excludedAudiences.includes(audience)
    );

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAudienceClick = (audience) => {
        onSelectAudience(audience);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <settings-row>
                    Select audience 
                    <CloseOutlinedIcon onClick={onClose} className={styles.closeIcon} /> 
                </settings-row>

                {filteredAudiences.map((audience, index) => (
                    <div key={index} onClick={() => handleAudienceClick(audience)}>
                        <button className="button audiencebutton">{audience}<AddOutlinedIcon /></button>
                    </div>
                ))}

                <popup-row>
                    <Link to="/manageaudiences" className="button whitebutton">
                        Manage audiences
                    </Link>
                    <button onClick={handleOpenModal} className="button bluebutton">Create audience</button>
                </popup-row>
            </div>

            <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
    );
}

export default SelectAudiencePopUp2;
