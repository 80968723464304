import L from 'leaflet'; // Assuming Leaflet is used for map operations

class SimulationUIManager {
  constructor(map, vehiclePanel, addMarker, updateMarker, removeMarker) {
    this.map = map;
    this.vehiclePanel = vehiclePanel;
    this.addMarker = addMarker;
    this.updateMarker = updateMarker;
    this.removeMarker = removeMarker;
  }

  setView(latitude, longitude, zoom) {
    let zoomTo = this.map.current.getZoom();
    if (zoom) {
      zoomTo = zoom;
    }
    this.map.current.setView([latitude, longitude], zoomTo)
  }

  //   addMarker(vehicle) {

  //   }

  addVehicleToPanel(vehicle) {
    // let vehicleItem = document.createElement('li');
    // vehicleItem.setAttribute('data-vehicle-id', vehicle.id);
    // vehicleItem.classList.add('list-group-item');
    // vehicleItem.textContent = `Truck ${vehicle.id}`;
    // this.vehiclePanel.appendChild(vehicleItem);
  }

  removeVehicleFromPanel(vehicle) {
    // const vehicleItem = this.vehiclePanel.querySelector(`[data-vehicle-id="${vehicle.id}"]`);
    // if (vehicleItem) {
    //   this.vehiclePanel.removeChild(vehicleItem);
    // }
  }

  updateVehiclePopup(vehicle) {
    // const currentSpeed = (vehicle.speed * 3.6).toFixed(2); // Convert m/s to km/h and format
    // const eta = new Date(vehicle.estimatedTimeOfArrival).toLocaleTimeString();
    // const popupContent = `
    //   <div>
    //       <p><strong>Truck ID:</strong> ${vehicle.id}</p>
    //       <p><strong>Current Speed:</strong> ${currentSpeed} km/h</p>
    //       <p><strong>Estimated Time to Port:</strong> ${eta}</p>
    //   </div>
    // `;
    // vehicle.marker.setPopupContent(popupContent);
  }

  highlightVehicle(vehicle) {
    // vehicle.marker.setIcon(L.icon({
    //   iconUrl: 'images/truck-highlighted.png',
    //   iconSize: [30, 30],
    //   iconAnchor: [15, 15],
    //   popupAnchor: [0, -15]
    // }));
  }
}

export { SimulationUIManager };