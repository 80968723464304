// // import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';

// export const PrivateRoute = ({ children }) => {
//     const isAuthenticated = true;
        
//     if (isAuthenticated ) {
//       return children
//     }
      
//     return <Navigate to="/login" />
//   }



import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setIsAuthenticated(!!user);
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    if (loading) {
        return <div>Loading...</div>; // Or any other loading state component or spinner
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return children;
}