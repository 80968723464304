import React, { useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './draftstab.module.css';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';



function DraftsTab() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (

        <div className={styles.tableRow}>
            <table id="events" class="events-table" cellspacing="0" width="100%">
                <tbody>
                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>

                        <td classname={styles.from}>Nik Turner</td>
                        <td classname={styles.message}>Message</td>
                        <td class={styles.date}>10 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>
                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>
                        <td className={styles.from}>Simon House</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>8 Apr 2024</td>
                    </tr>

                    <tr>
                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>

                        <td className={styles.from}>Robert Calvert</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>5 Apr 2024</td>


                    </tr>
                    <tr>

                        <td className={styles.icons}>
                            <div className={styles.iconsRow}>

                                <label className={styles.emailCheckboxContainer}>
                                    <input type="checkbox" name="checkbox"></input>
                                    <span className={styles.checkboxCheckmark}></span>
                                </label>
                                <StarBorderOutlinedIcon />
                            </div>
                        </td>

                        <td className={styles.from}>Del Dettmar</td>
                        <td className={styles.message}>Message</td>
                        <td className={styles.date}>4 Apr 2024</td>


                    </tr>

                </tbody>
            </table>
        </div>





    );
}

export default DraftsTab;
