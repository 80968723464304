import * as React from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import styles from "./rowstab.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function InAppMessagesRowsTab({ callback }) {
  return (
    <row className="email-icons-container">
      <column>
        <row>
          {[
            ...Array(12)
              .keys()
              .map((k) => (k + 1).toString()),
          ].map((n) => (
            <email-container onClick={() => callback((+n) - 1)}>
              <img src={`email-blocks${n.padStart(3, "0")}.jpg`} />
            </email-container>
          ))}
        </row>
      </column>
    </row>
  );
}

export default InAppMessagesRowsTab;

