import React from "react";
import styles from "./createaudiencepopup.module.css";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

function AudienceList({ onComponentChange }) {
  return (
    <div>
      <row onClick={() => onComponentChange("Destinations")}>
        <div className={styles.createColumn1}>
          <div className={styles.createName}>
            <AddOutlinedIcon /> Destination
          </div>
        </div>
        <div className={styles.createColumn2}>
          <div className={styles.createAdd}>
            The portal towards which drivers are travelling <AddOutlinedIcon />
          </div>
        </div>
      </row>
      <row onClick={() => onComponentChange("Load")}>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Load
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of load <AddOutlinedIcon />
          </button>
        </div>
      </row>
      <row onClick={() => onComponentChange("VehicleSize")}>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Vehicle size
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of vehicle size <AddOutlinedIcon />
          </button>
        </div>
      </row>
      <row onClick={() => onComponentChange("FuelType")}>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Fuel type
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of fuel type <AddOutlinedIcon />
          </button>
        </div>
      </row>
      <row>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Haulier
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of haulier <AddOutlinedIcon />
          </button>
        </div>
      </row>
      <row>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Language
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of language <AddOutlinedIcon />
          </button>
        </div>
      </row>
      <row>
        <div className={styles.createColumn1}>
          <button type="button" className={styles.createButton}>
            <AddOutlinedIcon /> Nationality
          </button>
        </div>
        <div className={styles.createColumn2}>
          <button type="button" className="button audiencebutton">
            Explanation of nationality <AddOutlinedIcon />
          </button>
        </div>
      </row>
    </div>
  );
}

export default AudienceList;
