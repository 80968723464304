import React from 'react';
import truckStopsData from './truckstops.json'; 
import { Link } from 'react-router-dom';
import styles from './truckstops.module.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


function TruckStopsList() {
    const truckStops = truckStopsData; 

    return (
        <div>
            <double-column>
                <row>
                    <h1>Manage truckstops</h1>
                    <row>
                        <dashboard-container>
                            <button-row>
                             


                                <Link to="/newtruckstop" className="button bluebutton">
                                    Add new truck stop
                                </Link>
                            
                            </button-row>
                            <table id="events" className="events-table" cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th className="name">Name</th>
                                        <th className="address">Address</th>
                                        <th className="spaces">Spaces</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {truckStops.map(truckStop => (
                                        <tr key={truckStop.id}>
                                            <td className="name">{truckStop.name}</td>
                                            <td className="address">{truckStop.address}</td>
                                            <td className="spaces">{truckStop.spaces}</td>
                                            <td className="actions">
                                                <EditOutlinedIcon/>
                                                <DeleteOutlineOutlinedIcon/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </dashboard-container>
                    </row>
                </row>
            </double-column>
        </div>
    );
}

export default TruckStopsList;
