import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import styles from './ivs2.module.css';

function IVSMessage({ title, message, DropdownValue, handleTitleChange, handleMessageChange, handleDropdownClick }) {
    return (
        <dashboard-container>
            <row>
                <column>
                    <h3>Message</h3>
                    <row>
                        <label htmlFor="messageType">Type of message</label>
                        <row>
                            <div className="dropdown">
                                <button className="dropbtn" type="button">
                                    {DropdownValue} <ExpandMoreOutlinedIcon />
                                </button>
                                <div className="dropdown-content">
                                    <a onClick={() => handleDropdownClick("Info", "info.png")}>Info</a>
                                    <a onClick={() => handleDropdownClick("Hazard", "hazard.png")}>Hazard</a>
                                    <a onClick={() => handleDropdownClick("Alert", "alert.png")}>Alert</a>
                                </div>
                            </div>
                        </row>
                        <half-column>
                            <label htmlFor="messageName">Title</label>
                            <input
                                type="text"
                                maxLength="28"
                                value={title}
                                onChange={handleTitleChange}
                                className={styles.titleInput}
                            ></input>
                            <div className={styles.textareaTip}>Max 25 characters</div>
                        </half-column>
                    </row>
                </column>
            </row>
            <row>
                <half-column>
                    <label htmlFor="emailSubject">Message text</label>
                    <textarea
                        rows="8"
                        cols="10"
                        maxLength="128"
                        value={message}
                        onChange={handleMessageChange}
                    ></textarea>
                    <div className={styles.textareaTip}>Max 128 characters</div>
                </half-column>
            </row>

        </dashboard-container>
    );
}

IVSMessage.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    DropdownValue: PropTypes.string.isRequired,
    handleTitleChange: PropTypes.func.isRequired,
    handleMessageChange: PropTypes.func.isRequired,
    handleDropdownClick: PropTypes.func.isRequired,
};

export default IVSMessage;
