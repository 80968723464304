import React, { useState, useEffect } from "react";
import styles from "./newtruckstop.module.css";
import truckStopFacilitiesData from "./truckstop-facilities.json";
import { Link } from "react-router-dom";
import { firestore } from "../../firesbase.js";
import {
  collection,
  updateDoc,
  addDoc,
  query,
  getDoc,
  onSnapshot,
  getDocs,
  writeBatch,
  doc,
} from "firebase/firestore";

function NewTruckStop() {
  const [image, setImage] = useState(null);
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgLink = URL.createObjectURL(file);
      setImage(imgLink);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const imgLink = URL.createObjectURL(file);
      setImage(imgLink);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = (event) => {
    const button = event.target;

    // Toggle class based on existing class
    if (button.classList.contains(styles.facilitiesButtonSelected)) {
      button.classList.remove(styles.facilitiesButtonSelected);
    } else {
      button.classList.add(styles.facilitiesButtonSelected);
    }

    if (selectedFacilities.includes(button.dataset.id)) {
      setSelectedFacilities((prev) =>
        prev.filter((id) => id !== button.dataset.id),
      );
    } else {
      setSelectedFacilities((prev) => [...prev, button.dataset.id]);
    }
  };

  const saveForm = async () => {
    const k = ["A", "B", "C", "D", "E", "F"];
    const t = ["is", "is not", "is", "is", "is", "is"];
    const v = [1, 2, 3, 4, 5, 6];
    const c = ["OR", "AND", "AND", "OR", "AND"];

    const data = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
    };

    // [value, ...] -> [[value, ...], ...]
    // where each nested array is a group of ANDs
    const z = k
      .reduce(
        (acc, key, i) => {
          const b = data[key] === v[i];
          acc[acc.length - 1].push(t[i] === "is" ? b : !b);
          if (c[i] === "OR") acc.push([]);
          return acc;
        },
        [[]],
      )
      .map((arr) => arr.every((b) => b)) // all
      .some((b) => b); // or

    console.log(z);
    // const data = new FormData(document.addTruckStopForm);
    // for (const day of days) {
    //   if (data.get(day) === null) {
    //     data.delete(day + "Open");
    //     data.delete(day + "Close");
    //   }
    //   data.delete(day);
    // }
    // const dataObj = Object.fromEntries(data);
    // dataObj.facilities = selectedFacilities;
    //
    // function getBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => resolve(reader.result);
    //     reader.onerror = (error) => {
    //       console.log("Error: ", error);
    //       reject(null);
    //     };
    //     reader.readAsDataURL(file);
    //   });
    // }
    //
    // const b64 = await getBase64(data.get("image"));
    //
    // const numBytes = Math.floor((b64.length * 3) / 4);
    //
    // dataObj.image = numBytes < 500000 ? b64 : "";
    //
    // try {
    //   const docRef = await addDoc(
    //     collection(firestore, "truck_stops"),
    //     dataObj,
    //   );
    //   document.addTruckStopForm.reset();
    //   window.scrollTo(0, 0);
    // } catch (e) {
    //   console.error("Error adding document: ", e);
    // }
  };

  return (
    <form name="addTruckStopForm">
      <row>
        <row>
          <h1>New truck stop</h1>
        </row>
        <double-column>
          <dashboard-container>
            <h3>Truck stop details</h3>

            <row>
              <half-column>
                <label for="truckstopName">Name of truck stop</label>
                <input type="text" id="truckstopName" name="name" />
              </half-column>
            </row>

            <row>
              <half-column>
                <label for="spaces">Number of spaces</label>
                <input type="number" id="speaces" name="spaces" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="road">Road</label>
                <input type="text" id="road" name="road" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="phoneNumber">Phone number</label>
                <input type="text" id="phoneNumber" name="phoneNumber" />
              </half-column>
              <half-column>
                <label for="email">Email</label>
                <input type="email" id="email" name="email" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="addressLineOne">Address line 1</label>
                <input type="text" id="addressLineOne" name="addressLineOne" />
              </half-column>
              <half-column>
                <label for="addressLineTwo">Address line 2 (optional)</label>
                <input type="text" id="addressLineTwo" name="addressLineTwo" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="town">Town or city</label>
                <input type="text" id="town" name="towny" />
              </half-column>
              <half-column>
                <label for="coutny">County (optional)</label>
                <input type="text" id="county" name="county" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="postcode">Postcode</label>
                <input type="text" id="postcode" name="postcode" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label for="latitude">Latitude</label>
                <input type="text" id="latitude" name="lat" />
              </half-column>
              <half-column>
                <label for="longitude">Longitude</label>
                <input type="text" id="longitude" name="long" />
              </half-column>
            </row>
            <row>
              <half-column>
                <label
                  className={styles.dropArea}
                  htmlFor="input-file"
                  id="drop-area"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <input
                    type="file"
                    accept="image/*"
                    id="input-file"
                    hidden
                    onChange={handleImageUpload}
                    name="image"
                  />
                  {image ? (
                    <div
                      className={styles.imgView}
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                  ) : (
                    <div className={styles.imgView}>
                      <img
                        className={styles.uploadImage}
                        src="upload-icon.png"
                        alt=""
                      />
                      <p>Drag and drop, or upload an image from your device</p>
                    </div>
                  )}
                </label>
              </half-column>
            </row>
            <half-column>
              <row>
                <div id="buttonContainer">
                  {["facilities", "infrastructure", "security"].map((label) => (
                    <>
                      <h3 style={{ textTransform: "capitalize" }}>{label}</h3>
                      <div id={`${label}Buttons`}>
                        {truckStopFacilitiesData[label].map(({ id, name }) => (
                          <button
                            className={styles.facilitiesButton}
                            type="button"
                            data-id={id}
                            onClick={handleClick}
                          >
                            {name}
                          </button>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
              </row>
            </half-column>
            <row>
              <column>
                <h3>Opening hours</h3>
              </column>
            </row>
            <row>
              <half-column>
                <h3>Day</h3>
              </half-column>
              <half-column>
                <h3>Open</h3>
              </half-column>
              <half-column>
                <h3>Close</h3>
              </half-column>
            </row>
            {days.map((day) => (
              <row>
                <half-column>
                  <row>
                    <label
                      class="checkbox-container"
                      style={{ textTransform: "capitalize" }}
                    >
                      {day}
                      <input type="checkbox" name={day} />
                      <span class="checkbox-checkmark"></span>
                    </label>
                  </row>
                </half-column>
                <half-column>
                  {" "}
                  <input type="time" id={`${day}Open`} name={`${day}Open`} />
                </half-column>
                <half-column>
                  {" "}
                  <input type="time" id={`${day}Close`} name={`${day}Close`} />
                </half-column>
              </row>
            ))}
          </dashboard-container>

          <bottom-row>
            <Link to="/truckstops" className="button whitebutton">
              Cancel
            </Link>

            <button
              id="openPopup"
              class="button bluebutton"
              onClick={saveForm}
              type="button"
            >
              Save
            </button>
          </bottom-row>
        </double-column>

        <sticky-column>
          <row>
            <dashboard-container>
              <h3>Guidance notes</h3>
              <p>
                <strong>Name</strong> - use use the full name of the truck stop
                rather than abbreviations
              </p>
              <p>
                <strong> Spaces</strong> - the maximum number of spaces
                avaialble
              </p>
              <p>
                {" "}
                <strong> Road</strong> - the nearest road; motorways and A roads
                onl
              </p>
              <p>
                <strong>Address </strong> - include motorway junction/s if
                appropriate
              </p>
              <p>
                <strong>Lat/long</strong> - be as accurate as possible
              </p>
              <p>
                <strong>Image</strong> - png/jpg/jpeg only, image should have
                dimensions of approx 800 x 600 px
              </p>
              <p>
                <strong>Amenities</strong> - select amenities avaialble
              </p>
              <p>
                <strong>Opening hours</strong> - check the days open, and for
                days not open, leave blank
              </p>
            </dashboard-container>
          </row>
        </sticky-column>
      </row>
    </form>
  );
}

export default NewTruckStop;
