import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMapEvents } from 'react-leaflet'
import { Polyline } from 'react-leaflet';
import Chart from 'chart.js/auto';
import { Bar } from 'chart.js';
import styles from './tas.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import styles from './map-icons.module.css';

// import { collection, doc, onSnapshot, addDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc } from 'firebase/firestore';
import { RouteService } from '../../api/route-service.js';
import { GlobalVariables } from '../utils/global-variables.js';
import { SimulationManager } from './simulation/simulation-manager.js';
import { SimulationUIManager } from './simulation/simulation-ui-manager.js';
import { AccidentsPanel } from './accidents/accidents-panel.js';
import { MapEventsHandler } from './map-events-handler.js'
import { BlockIcon } from './map-icons/block-icon.js';
import { SpawnIcon } from './map-icons/spawn-icon.js';
import { PortalIcon } from './map-icons/portal-icon.js';
import { TruckIcon } from './map-icons/truck-icon.js';

// import { ServiceIcon } from './map-icons/service-icon.js';

import { ServiceIcon } from './map-icons/serviceicon.js';

import { ScenariosPanel } from './scenarios/scenarios-panel.js';
import { SimulationSpeedPanel } from './simulation-speed/simulation-speed-panel.js';
import { BarChart } from '@mui/x-charts/BarChart';
import { CustomTime } from './simulation/custom-time.js';
import ScenarioRoadBlocks from './scenarios/scenario-road-blocks.js';
import { AddMarkerPanel } from './add-marker/add-marker-panel.js';
import DropPin from './scenarios/drop-pin.js';
import AddScenarioModal from './scenarios/add-scenario-modal.js';

import RoutingControl from './routing/routing-control.js'
import { AddRoutePanel } from './add-route/add-route-panel.js';
import { SiteConfig } from '../../site-config.js';
import TruckModal from './scenarios/truck-modal.js';
import { Scheduler } from './simulation/scheduler.js'




function TAS({ siteConfig }) {
    // const [map, setMap] = useState(null);
    const map = useRef(null);
    const [markers, setMarkers] = useState([]);
    const [accidentMarkers, setAccidentMarkers] = useState([]);
    const [addingAccident, setAddingAccident] = useState(false);
    const [scenarioId, setScenarioId] = useState(null);
    const [scenario, setScenario] = useState(null);
    const [simulationSpeed, setSimulationSpeed] = useState(10);
    const [truckSpawnRate, setTruckSpawnRate] = useState(10);

    const [customTime, setCustomTime] = useState(new CustomTime());
    const [enableScheduler, setEnableScheduler] = useState(true);
    const [isScenarioRoadBlocksOpen, setIsScenarioRoadBlocksOpen] = useState(false);
    const [isAddScenarioOpen, setIsAddScenarioOpen] = useState(false);
    const [isEditScenarioOpen, setIsEditScenarioOpen] = useState(false);
    const [doverChartData, setDoverChartData] = useState([{
        labels: [],
        data: []
    }]);
    const [exitTimes, setExitTimes] = useState({});
    // const [markerUpdates, setMarkerUpdates] = useState(0);
    const [autoOpenPopUpMarker, setAutoOpenPopUpMarker] = useState('');
    const routeService = new RouteService(SiteConfig.routeServerBaseUrl);
    // let autoOpenPopUpMarker = null
    // const markerRefs = React.useRef({});
    // const [done, setDone] = React.useState(false);
    // const [activeAccidentMarkerId, setActiveAccidentMarkerId] = useState(null);

    // let accidentMarkerRefs = [];


    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    const [activeMarker, setActiveMarker] = useState(null);
    const [activeTruckMarker, setActiveTruckMarker] = useState(null);
    const activeTruckMarkerRef = useRef(null);
    useEffect(() => {
        activeTruckMarkerRef.current = activeTruckMarker;
    }, [activeTruckMarker]);
    const [truckInstructionText, setTruckInstructionText] = useState('');
    const [bearing, setBearing] = useState(0);
    const [truckMarkerPosition, setTruckMarkerPosition] = useState(null);








    // const [popperElement, setPopperElement] = useState(null);
    // const [referenceElement, setReferenceElement] = useState(null);
    const [dropPinPosition, setDropPinPosition] = useState(null);

    const openMarker = async (marker) => {
        await map.current.setView([marker.position.lat, marker.position.lng], map.current.getZoom())
        setTimeout(() => {
            setActiveMarker(marker);
            setActiveTruckMarker(null);
            const screenPos = map.current.latLngToContainerPoint(marker.position);
            setDropPinPosition(screenPos);
        }, 500); // Delay of 500 milliseconds        
    };


    const openTruckMarker = async (marker) => {
        await map.current.setView([marker.position.lat, marker.position.lng], map.current.getZoom());
        setTimeout(() => {
            setActiveTruckMarker(marker);
            setActiveMarker(null);
            const screenPos = map.current.latLngToContainerPoint(marker.position);
            setDropPinPosition(screenPos);
        }, 500);
    };


    useEffect(() => {
        if (scenarioId) {
            const scenarioRef = doc(firestore, "scenarios", scenarioId);
            const accidentsCollection = collection(firestore, 'geopoints');
            const q = query(accidentsCollection, where('scenarios', 'array-contains', scenarioRef));
            const unsubscribe = onSnapshot(q, async (snapshot) => {
                const updatedAccidents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                //clear the accidents in the routing server
                await routeService.clearAccidents();
                setAccidentMarkers(prev => []);
                // add each accident with addAccidentMarker
                updatedAccidents.forEach(accident => {
                    if (accident.latitude != null && accident.longitude != null) {

                        if (accident.markerType == "accident") {
                            routeService.addAccident({
                                lat: accident.latitude,
                                lng: accident.longitude
                            });
                        }
                        let marker = accident;
                        accident.position = { lat: accident.latitude, lng: accident.longitude }
                        setAccidentMarkers(prev => [...prev, marker]);
                    }
                });
            });

            return () => unsubscribe();
        }
    }, [scenarioId]);

    const [routes, setRoutes] = useState([]);
    useEffect(() => {
        if (scenarioId) {
            const routesCollection = collection(firestore, 'routes');
            const routesQuery = query(routesCollection, where('scenarioId', '==', scenarioId));

            const unsubscribe = onSnapshot(routesQuery, async (snapshot) => {

                await routeService.clearRoutes();
                setRoutes([]);

                const routesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                routesData.forEach(route => {
                    // if (accident.latitude != null && accident.longitude != null) {

                    // if (accident.markerType == "accident") {
                    //     routeService.addAccident({
                    //         lat: accident.latitude,
                    //         lng: accident.longitude
                    //     });
                    // }
                    // let marker = accident;
                    // accident.position = { lat: accident.latitude, lng: accident.longitude }
                    // setAccidentMarkers(prev => [...prev, marker]);

                    routeService.addRoute(route.edgeIds);

                    setRoutes(prev => [...prev, route]);
                    // }
                });

                // setRoutes(routesData);
            });

            return () => unsubscribe();
        } else {
            setRoutes([]);
        }
    }, [scenarioId]);



    const onMapDrag = (e) => {
        console.log(e);
        if (activeMarker) {
            const screenPos = map.current.latLngToContainerPoint(activeMarker.position);
            setDropPinPosition(screenPos);
        }
        if (activeTruckMarkerRef.current) {
            const screenPos = map.current.latLngToContainerPoint(activeTruckMarkerRef.current.position);
            setDropPinPosition(screenPos);
        }
    }

    const handleMapClick = (e) => {
        if (addingAccident) {
            const scenarioRef = doc(firestore, "scenarios", scenarioId);
            const dbGeoPoint = {
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
                name: 'New Accident',
                description: 'New accident description',
                // scenario: scenarioRef,
                scenarios: [scenarioRef],
                markerType: 'accident',
                portalFlow: 10
            };

            const geopointRef = collection(firestore, 'geopoints');
            addDoc(geopointRef, dbGeoPoint)
                .then((docRef) => {
                    setAddingAccident(false);
                    setAutoOpenPopUpMarker(docRef.id);
                })
                .then(() => {
                    console.log('Accident added to Firestore successfully!');
                })
                .catch(error => console.error('Error adding accident to Firestore:', error));
        }
        setActiveMarker(null);
        setActiveTruckMarker(null);
    }

    const [routeMarkers, setRouteMarkers] = useState([]);

    const handleRouteMapClick = (e) => {
        if (addingRoute) {
            const newMarker = e.latlng;
            setRouteMarkers((prevMarkers) => [...prevMarkers, { id: routeMarkers.length, position: newMarker }]);

            if (routeMarkers.length === 1) {
                // Get the route when two markers are placed
                const startMarker = routeMarkers[0];
                const endMarker = newMarker;
                createRoute(startMarker, endMarker);
                setRouteMarkers([]);
            }
        }
    };

    const handleAddWaypoint = (e) => {
        if (addingRoute) {
            const newWaypoint = e.latlng;

            // Get the RoutingControl instance from the ref
            const routingControl = routingControlRef.current;

            if (routingControl) {
                // Add the new waypoint to the route
                routingControl.addWaypoint(newWaypoint, routingControl.getWaypoints().length - 1);
            }
        }
    };

    const onRouteFinish = () => {
        setAddingRoute(false);
        setRouteMarkers([]);

        // Reset the waypoints of the routing control
        if (routingControlRef.current) {
            routingControlRef.current.setWaypoints([]);
        }

    };
    // const waypoints = [
    //     { lat: 33.52001088075479, lng: 36.26829385757446 },
    //     { lat: 33.50546582848033, lng: 36.29547681726967 },
    // ];

    const [addingRoute, setAddingRoute] = useState(false);
    const toggleAddingRoute = () => {
        setAddingRoute((prev) => !prev);
    };

    const [routingWaypoints, setRoutingWaypoints] = useState([]);
    const routingControlRef = useRef(null);

    const createRoute = async (startMarker, endMarker) => {
        try {
            // Create new waypoints for the route
            const newWaypoints = [
                { lat: startMarker.position.lat, lng: startMarker.position.lng },
                { lat: endMarker.lat, lng: endMarker.lng },
            ];

            // Update the routing waypoints state
            const routingControl = routingControlRef.current;

            // Update the waypoints of the RoutingControl instance
            if (routingControl) {
                routingControl.setWaypoints(newWaypoints);
            }
        } catch (error) {
            console.error("Error creating route:", error);
        }
    };

    const [activeRouteId, setActiveRouteId] = useState(null);

    const handlePolylineClick = (routeId) => {
        setActiveRouteId(routeId);
    };

    const handleDeleteRoute = async (routeId) => {
        try {
            const routeRef = doc(firestore, 'routes', routeId);
            await deleteDoc(routeRef);
            console.log('Route deleted successfully');
            setActiveRouteId(null);
        } catch (error) {
            console.error('Error deleting route:', error);
        }
    };


    const toggleAddingAccident = () => {
        setAddingAccident(prev => !prev);
    };

    const addMarker = (markerData) => {
        setMarkers(prev => [...prev, markerData]);
    };

    const updateMarker = (id, newPosition, instructionText, bearing) => {
        setMarkers(prev => prev.map(marker => marker.id === id ? { ...marker, position: newPosition, instructionText: instructionText, bearing: bearing } : marker));

        // if (activeTruckMarker) {
        //     const screenPos = map.current.latLngToContainerPoint(activeTruckMarker.position);
        //     setDropPinPosition(screenPos);
        // }

        if (activeTruckMarkerRef.current && activeTruckMarkerRef.current.id === id) {
            activeTruckMarkerRef.current.position = newPosition;
            setTruckInstructionText(instructionText);
            setBearing(bearing);
            setTruckMarkerPosition(newPosition);
            const screenPos = map.current.latLngToContainerPoint(newPosition);
            setDropPinPosition(screenPos);
        }
    };

    const removeMarker = (id) => {
        setMarkers(prev => prev.filter(marker => marker.id !== id));
        if (activeTruckMarkerRef.current && activeTruckMarkerRef.current.id === id) {
            setActiveTruckMarker(null);
        }
    };

    const simulationManagerRef = useRef(null);
    const simulationUIManagerRef = useRef(null);

    if (!simulationManagerRef.current) {
        simulationUIManagerRef.current = new SimulationUIManager(map, document.getElementById('vehicle-panel'), addMarker, updateMarker, removeMarker);
        simulationManagerRef.current = new SimulationManager(100, simulationSpeed, truckSpawnRate, scenarioId, simulationUIManagerRef.current, customTime, setDoverChartData, enableScheduler, setExitTimes);
    }

    const center = {
        lat: 51.505,
        lng: -0.09,
    };

    // this.scheduler = new Scheduler(this.services, this.customTime, this.setDoverChartData, this.enableScheduler);

    useEffect(() => {

        if (map) {
            // Example function to start simulation
            function startSimulation(Scheduler) {
                // Simulation start logic
                // simulationManagerRef.current.startSimulation();
            }

            startSimulation(false);
        }

        return () => {
        };
    }, [map]);

    useEffect(() => {
        simulationManagerRef.current.updateSimulationSpeed(simulationSpeed, truckSpawnRate);
    }, [simulationSpeed, truckSpawnRate]);

    useEffect(() => {
        simulationManagerRef.current.updateScenarioId(scenarioId);
    }, [scenarioId]);

    useEffect(() => {
        simulationManagerRef.current.updateEnableScheduler(enableScheduler);
    }, [enableScheduler]);

    return (
        <div className={styles.tasContainer}>
            <MapContainer
                center={center}
                zoom={13}
                scrollWheelZoom={true}
                className={styles.map}
                zoomControl={false}
                ref={map}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position="topright" className={styles.zoomControl} />

                <MapEventsHandler
                    onMapClick={handleMapClick}
                    onMapDrag={onMapDrag}
                    addingRoute={addingRoute}
                    handleRouteMapClick={handleRouteMapClick}
                    handleAddWaypoint={handleAddWaypoint}
                />

                {markers.map(marker => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={TruckIcon}
                        eventHandlers={{
                            click: () => openTruckMarker(marker),
                            // dragend: (e) => {
                            //     // const latLng = e.target.getLatLng();
                            //     // const accidentDocRef = doc(firestore, 'geopoints', marker.id); // Assuming `accidentId` is available in the component's props
                            //     try {
                            //         // setDoc(accidentDocRef, { latitude: latLng.lat, longitude: latLng.lng }, { merge: true });
                            //         setActiveTruckMarker(null);
                            //         // markerRef.closePopup();
                            //         console.log('Accident name saved successfully');
                            //     } catch (error) {
                            //         console.error('Error saving accident name:', error);
                            //     }
                            //     // const marker = markerRef.current
                            //     // if (marker != null) {
                            //     //     setPosition(marker.getLatLng())
                            //     // }
                            // },
                        }}>
                    </Marker>
                ))}

                {routeMarkers.map(marker => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={TruckIcon}
                    >
                    </Marker>
                ))}

                {accidentMarkers.map(marker => {
                    let icon;
                    switch (marker.markerType) {
                        case 'accident':
                            icon = BlockIcon;
                            break;
                        case 'spawn':
                            icon = SpawnIcon;
                            break;
                        case 'portal':
                            icon = PortalIcon;
                            break;
                        case 'service':
                            icon = ServiceIcon;
                            break;
                        default:
                            icon = BlockIcon; // Default to BlockIcon if no type matches
                    }

                    // {accidentMarkers.map(marker => {
                    //   const IconComponent = getIcon(marker.markerType);

                    return (
                        <Marker
                            key={marker.id}
                            position={marker.position}
                            icon={icon}
                            className="testy"
                            eventHandlers={{
                                click: () => openMarker(marker),
                                dragend: (e) => {
                                    const latLng = e.target.getLatLng();
                                    const accidentDocRef = doc(firestore, 'geopoints', marker.id); // Assuming `accidentId` is available in the component's props
                                    try {
                                        setDoc(accidentDocRef, { latitude: latLng.lat, longitude: latLng.lng }, { merge: true });
                                        setActiveMarker(null);
                                        // markerRef.closePopup();
                                        console.log('Accident name saved successfully');
                                    } catch (error) {
                                        console.error('Error saving accident name:', error);
                                    }
                                    // const marker = markerRef.current
                                    // if (marker != null) {
                                    //     setPosition(marker.getLatLng())
                                    // }
                                },
                            }}
                            draggable={true}

                        >
                        </Marker>
                    );
                })}

                {routes.map((route) => (
                    <React.Fragment key={route.id}>
                        {route.waypoints.map((waypoint, index) => {
                            if (index === 0 || index === route.waypoints.length - 1) {
                                return (
                                    <Marker
                                        key={`${route.id}-${index}`}
                                        position={[waypoint.lat, waypoint.lng]}
                                        icon={TruckIcon}
                                    >
                                        <Popup>
                                            <div>
                                                <p>Route: {route.id}</p>
                                                <button onClick={() => handleDeleteRoute(route.id)}>Delete Route</button>
                                            </div>
                                        </Popup>
                                    </Marker>
                                );
                            }
                            return null;
                        })}
                        <Polyline
                            positions={route.waypoints.map((waypoint) => [waypoint.lat, waypoint.lng])}
                            color="#6FA1EC"
                            weight={4}
                            eventHandlers={{
                                click: () => handlePolylineClick(route.id),
                            }}
                        />
                        {activeRouteId === route.id && (
                            <Popup position={route.waypoints[0]}>
                                <div>
                                    <p>Route: {route.id}</p>
                                    <button onClick={() => handleDeleteRoute(route.id)}>Delete Route</button>
                                </div>
                            </Popup>
                        )}
                    </React.Fragment>
                ))}

                <RoutingControl
                    ref={routingControlRef}
                    waypoints={routingWaypoints}
                    lineOptions={{
                        styles: [{ color: "#6FA1EC", weight: 4 }],
                    }}
                    show={true}
                    addWaypoints={false}
                    routeWhileDragging={true}
                    draggableWaypoints={true}
                    fitSelectedRoutes={true}
                    showAlternatives={false}
                />

            </MapContainer>

            {
                activeMarker && dropPinPosition && (
                    <DropPin marker={activeMarker} setActiveMarker={setActiveMarker} position={dropPinPosition} exitTimes={exitTimes} />
                )
            }

            {
                (activeTruckMarkerRef.current && !activeMarker) && dropPinPosition && (
                    <TruckModal truckMarker={{ ...activeTruckMarkerRef.current }} instructionText={truckInstructionText} bearing={bearing} truckMarkerPosition={truckMarkerPosition} setActiveMarker={setActiveTruckMarker} position={dropPinPosition} />
                )
            }

            {doverChartData && doverChartData['0'] && doverChartData['0'].labels.length > 0 && (<map-row>
                <map-container>

                    {/* <Tabs
                      indicatorColor="transparent" 
                      className={styles.tabs}
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Graph Tabs">
                        {doverChartData.map((chartData, index) => (
                            <Tab label={`${chartData.name}`} key={index} />
                        ))}
                    </Tabs> */}



                    <Tabs
                        indicatorColor="transparent"
                        className={styles.tabs}
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Graph Tabs"
                    >
                        {doverChartData.map((chartData, index) => (
                            <Tab
                                className={`${styles.tab} ${selectedTab === index ? styles.activeTab : ''}`}
                                label={`${chartData.name}`}
                                key={index}
                            />
                        ))}
                    </Tabs>
                    {doverChartData.map((chartData, index) => (
                        <div
                            role="tabpanel"
                            hidden={selectedTab !== index}
                            id={`tabpanel-${index}`}
                            aria-labelledby={`tab-${index}`}
                            key={index}>
                            {selectedTab === index && (
                                <div>
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: chartData.labels }]}
                                        series={[{ data: chartData.data }]}
                                        height={200}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </map-container>
            </map-row>)}

            <map-column>
                <map-container>
                    <ScenariosPanel setScenarioId={setScenarioId} scenarioId={scenarioId} setScenario={setScenario} setIsScenarioRoadBlocksOpen={setIsScenarioRoadBlocksOpen} setIsAddScenarioOpen={setIsAddScenarioOpen} setIsEditScenarioOpen={setIsEditScenarioOpen} siteConfig={siteConfig} />
                </map-container>
            </map-column>


            <simulation-panel>

                <map-container>
                    <SimulationSpeedPanel simulationSpeed={simulationSpeed} setSimulationSpeed={setSimulationSpeed} customTime={customTime} enableScheduler={enableScheduler} setEnableScheduler={setEnableScheduler} truckSpawnRate={truckSpawnRate} setTruckSpawnRate={setTruckSpawnRate} scenarioId={scenarioId} />
                </map-container>
            </simulation-panel>

            <roadblock-dialog>
                <map-container>
                    <ScenarioRoadBlocks
                        isOpen={isScenarioRoadBlocksOpen}
                        setIsScenarioRoadBlocksOpen={setIsScenarioRoadBlocksOpen}
                        accidentMarkers={accidentMarkers}
                        activeMarker={activeMarker}
                        setActiveMarker={setActiveMarker}
                        openMarker={openMarker}
                    // scenarioId={scenarioId}
                    // addAccidentMarker={addAccidentMarker}
                    // clearAccidents={clearAccidents}
                    />
                </map-container>
            </roadblock-dialog>


            <div style={{ top: "100px", left: "800px", position: "absolute", zIndex: 99999 }}>
                <AddMarkerPanel toggleAddingAccident={toggleAddingAccident} addingAccident={addingAccident} scenarioId={scenarioId} />
            </div>

            <div style={{ top: "150px", left: "800px", position: "absolute", zIndex: 99999 }}>
                <AddRoutePanel
                    toggleAddingRoute={toggleAddingRoute}
                    addingRoute={addingRoute}
                    scenarioId={scenarioId}
                    createRoute={createRoute}
                    routingWaypoints={routingWaypoints}
                    setRoutingWaypoints={setRoutingWaypoints}
                    onRouteFinish={onRouteFinish}
                    routingControlRef={routingControlRef}
                />
            </div>

            <div>
                <AddScenarioModal scenarioId={scenarioId} scenario={scenario} isEditScenarioOpen={isEditScenarioOpen} setIsEditScenarioOpen={setIsEditScenarioOpen} isAddScenarioOpen={isAddScenarioOpen} setIsAddScenarioOpen={setIsAddScenarioOpen} />
            </div>


        </div>
    );
}

export default TAS;
