import React, { useState, useRef, useEffect } from "react";
import styles from "./ivs.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  useMap,
} from "react-leaflet";
import "leaflet-rotatedmarker";
import L from "leaflet";
import arrowSvg from "./arrow.svg";
import shadow from "./truck.svg";

function IVSMapModal({ isOpen, onClose, initialLocation, initialBearing }) {
  const defaultCenter = {
    lat: 51.505,
    lng: -0.09,
  };

  const [markerPosition, setMarkerPosition] = useState(
    initialLocation
      ? { lat: initialLocation[0], lng: initialLocation[1] }
      : defaultCenter
  );
  const [angle, setAngle] = useState(initialBearing || 0);

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  const markerIcon = new L.icon({
    iconUrl: arrowSvg,
    iconRetinaUrl: arrowSvg,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: shadow,
    shadowSize: new L.Point(32, 32),
    shadowAnchor: [16, 15],
    iconSize: new L.Point(32, 32),
    className: "arrowMarker",
  });

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setRotationAngle(angle);
    }
  }, [angle]);

  if (!isOpen) return null;

  const getReturnValues = () => ({
    bearing: +angle,
    position: [markerPosition.lat, markerPosition.lng],
  });

  const MapController = () => {
    const map = useMap();
    useEffect(() => {
      map.setView([markerPosition.lat, markerPosition.lng], map.getZoom());
    }, [map]);
    return null;
  };

  return (
    <div
      className={styles.modalOverlay}
      onClick={() => onClose(getReturnValues())}
    >
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Add pins
          <CloseOutlinedIcon
            onClick={() => onClose(getReturnValues())}
            className={styles.closeIcon}
          />
        </settings-row>
        <div style={{ position: "relative" }}>
          <MapContainer
            center={[markerPosition.lat, markerPosition.lng]}
            zoom={13}
            scrollWheelZoom={true}
            className={styles.map}
            zoomControl={false}
            ref={mapRef}
          >
            <MapController />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <ZoomControl position="topright" className={styles.zoomControl} />

            <Marker
              position={markerPosition}
              draggable={true}
              ref={markerRef}
              rotationAngle={angle}
              icon={markerIcon}
              eventHandlers={{
                dragend: () => {
                  if (markerRef.current) {
                    setMarkerPosition(markerRef.current.getLatLng());
                  }
                },
              }}
            ></Marker>
          </MapContainer>

          <div className={styles.mapRangeContainer}>
            <label className={styles.angleSliderLabel}>Angle: {angle}</label>
            <input
              className={styles.angleSlider}
              type="range"
              min="0"
              max="359"
              step="1"
              value={angle}
              onChange={(e) => {
                setAngle(+e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IVSMapModal;

// import React, { useState, useRef } from "react";
// import styles from "./ivs.module.css";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
// import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
// import Tooltip from "@mui/material/Tooltip";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup,
//   ZoomControl,
// } from "react-leaflet";
// import "leaflet-rotatedmarker";
// import L from "leaflet";
// import arrowSvg from "./arrow.svg";
// import shadow from "./truck.svg";

// function IVSMapModal({ isOpen, onClose }) {
//   const center = {
//     lat: 51.505,
//     lng: -0.09,
//   };

//   const map = useRef(null);
//   const marker = useRef(null);
//   const [markerPosition, setMarkerPosition] = useState({ ...center });
//   const [angle, setAngle] = useState(0);

//   const markerIcon = new L.icon({
//     iconUrl: arrowSvg,
//     iconRetinaUrl: arrowSvg,
//     iconAnchor: null,
//     popupAnchor: null,
//     shadowUrl: shadow,
//     shadowSize: new L.Point(32, 32),
//     shadowAnchor: [16, 15],
//     iconSize: new L.Point(32, 32),
//     className: "arrowMarker",
//   });

//   if (!isOpen) return null;

//   const getReturnValues = () => ({
//     bearing: +angle,
//     position: [markerPosition.lat, markerPosition.lng],
//   });

//   return (
//     <div
//       className={styles.modalOverlay}
//       onClick={() => onClose(getReturnValues())}
//     >
//       <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
//         <settings-row>
//           Add pins
//           <CloseOutlinedIcon
//             onClick={() => onClose(getReturnValues())}
//             className={styles.closeIcon}
//           />
//         </settings-row>
//         <div style={{ position: "relative" }}>
//           <MapContainer
//             center={center}
//             zoom={13}
//             scrollWheelZoom={true}
//             className={styles.map}
//             zoomControl={false}
//             ref={map}
//           >
//             <TileLayer
//               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             />

//             <ZoomControl position="topright" className={styles.zoomControl} />

//             <Marker
//               position={markerPosition}
//               draggable={true}
//               ref={marker}
//               rotationAngle={angle}
//               icon={markerIcon}
//               eventHandlers={{
//                 dragend: () => {
//                   if (marker.current !== null) {
//                     setMarkerPosition(marker.current.getLatLng());
//                   }
//                 },
//               }}
//             ></Marker>
//           </MapContainer>

//           <div className={styles.mapRangeContainer}>
//             <label className={styles.angleSliderLabel}>Angle: {angle}</label>
//             <input
//               className={styles.angleSlider}
//               type="range"
//               min="0"
//               max="359"
//               step="1"
//               value={angle}
//               onChange={(e) => {
//                 setAngle(e.target.value);
//                 marker.current.setRotationAngle(e.target.value);
//               }}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default IVSMapModal;
