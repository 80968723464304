import React, { useState, useEffect } from 'react';
import styles from './add-scenarios.module.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { firestore } from '../../../firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc, updateDoc, getDocs, writeBatch, arrayUnion, } from 'firebase/firestore';

function AddScenarioModal({ scenarioId, scenario, isEditScenarioOpen, setIsEditScenarioOpen, isAddScenarioOpen, setIsAddScenarioOpen }) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (isEditScenarioOpen && scenario) {
            setName(scenario.name);
            setDescription(scenario.description);
        } else {
            setName('');
            setDescription('');
        }
    }, [isAddScenarioOpen, isEditScenarioOpen, scenario]);

    // const save = () => {
    //     const newScenario = {
    //         name: name,
    //         description: description
    //     }

    //     const accidentRef = collection(firestore, 'scenarios');
    //     addDoc(accidentRef, newScenario);
    //     setIsAddScenarioOpen(false);
    // }
    const close = () => {
        setIsAddScenarioOpen(false);
        setIsEditScenarioOpen(false);
    };

    const save = async () => {
        const scenarioData = {
            name: name,
            description: description
        };

        if (isEditScenarioOpen) {
            const scenarioRef = doc(firestore, 'scenarios', scenarioId);
            // Spread existing scenario properties and overwrite with new edits
            const updatedScenarioData = { ...scenario, ...scenarioData };
            updateDoc(scenarioRef, updatedScenarioData);
        } else {
            const scenariosRef = collection(firestore, 'scenarios');
            const scenarioDocRef = await addDoc(scenariosRef, scenarioData);

            // Now, add the scenario ID to the scenarios array on all geopoints with isBase property set to true
            const accidentsCollection = collection(firestore, 'geopoints');
            const q = query(accidentsCollection, where('isBase', '==', true));
            const querySnapshot = await getDocs(q);

            const batch = writeBatch(firestore);
            querySnapshot.forEach((doc) => {
                const geopointRef = doc.ref;
                batch.update(geopointRef, {
                    scenarios: arrayUnion(scenarioDocRef)
                });
            });

            await batch.commit();

        }
        setIsAddScenarioOpen(false);
        setIsEditScenarioOpen(false);
    };

    if (!isAddScenarioOpen && !isEditScenarioOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <settings-row>
                    <p>Add scenario</p>
                    <CloseOutlinedIcon onClick={() => setIsAddScenarioOpen(false)} className={styles.closeIcon} />
                </settings-row>
                <row>
                    <column>
                        <label>Scenario name</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </column>
                </row>

                <row>
                    <column>
                        <label>Description</label>
                        <textarea rows="4" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </column>
                </row>
                <row>

                    {/* <label class="checkbox-container">Indefinitely
                        <input type="checkbox" name="indefinitely" />
                        <span class="checkbox-checkmark"></span>
                    </label> */}
                </row>

                {/* <row>
                    <half-column>

                        <column>
                            <label for="fromDate">From</label>
                            <input type="date" id="fromDate" name="fromDate" />
                        </column>
                    </half-column>

                    <half-column>
                        <column>
                            <label for="toDate">To</label>
                            <input type="date" id="toDate" name="toDate" />
                        </column>

                    </half-column>
                </row> */}


                <popup-row>
                    <button className="button whitebutton" onClick={() => close()}>Cancel</button>
                    <button className="button bluebutton" onClick={() => save()}>Save scenario</button>
                </popup-row>
            </div>
        </div>
    );
}

export default AddScenarioModal;
