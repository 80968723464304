import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './schedule.module.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { firestore } from "../../firesbase.js";
import { collection, getDocs, doc, deleteDoc, query, orderBy } from "firebase/firestore";

function Schedule() {
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    const fetchMessages = async () => {
        const messagesCollection = collection(firestore, 'messages');
        const messagesQuery = query(messagesCollection, orderBy('timestamp', 'desc'));
        const messagesSnapshot = await getDocs(messagesQuery);
        const messagesList = messagesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setMessages(messagesList);
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    const getName = (message) => {
        return message.name || message.messageName || 'Unnamed Message';
    };

    const formatTimestamp = (timestamp) => {
        if (timestamp && timestamp.toDate) {
            const date = timestamp.toDate();
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        }
        return 'N/A';
    };

    const handleEdit = (message) => {
        if (message.type == 'global') {
            navigate('/inappmessages', {
                state: {
                    editMessage: message,
                    blocks: JSON.parse(message.blocks || '[]'),
                    messageTitle: message.messageTitle,
                    name: message.name || message.messageName,
                    preheaderText: message.preheaderText
                }
            });
        } else {
            navigate('/ivs2', { state: { editMessage: message } });
        }
    };

    const handleDelete = async (messageId) => {
        if (window.confirm('Are you sure you want to delete this message?')) {
            try {
                await deleteDoc(doc(firestore, 'messages', messageId));
                console.log('Document successfully deleted!');
                await fetchMessages();
            } catch (error) {
                console.error('Error removing document: ', error);
            }
        }
    };

    return (
        <row>
            <h1>Message schedule</h1>
            <row>
                <column>
                    <dashboard-container>
                        <table id="events" className="events-table" cellSpacing="0" width="100%">
                            <thead>
                                <tr>
                                    <th className="type">Type</th>
                                    <th className="name">Name</th>
                                    <th className="title">Title</th>
                                    <th className="schedule">Created</th>
                                    <th className="actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messages.map(message => (
                                    <tr key={message.id}>
                                        <td className="type">
                                            <span className={`${styles.actionIcon} ${styles.editIcon}`}>
                                                <EditOutlinedIcon onClick={() => handleEdit(message)} />
                                            </span>
                                            {message.type.charAt(0).toUpperCase() + message.type.slice(1)}
                                        </td>
                                        <td className="name">{getName(message)}</td>
                                        <td className="title">{message.messageTitle}</td>
                                        <td className="created">{formatTimestamp(message.timestamp)}</td>
                                        <td className="actions">
                                            <span className={`${styles.actionIcon} ${styles.editIcon}`}>
                                                <EditOutlinedIcon onClick={() => handleEdit(message)} />
                                            </span>
                                            <span className={`${styles.actionIcon} ${styles.deleteIcon}`}>
                                                <DeleteOutlineOutlinedIcon onClick={() => handleDelete(message.id)} />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </dashboard-container>
                </column>
            </row>
            <row></row>
        </row>
    );
}

export default Schedule;