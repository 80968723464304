import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import Reports from '../reports/reports.js';
import Live from '../live/live.js';
import Sidebar from '../sidebar/sidebar.js';
import Dashboard from '../dashboard/dashboard.js';
import Login from '../login/login.js'; // Ensure this component is correctly imported
import { PrivateRoute } from '../private-route/private-route.js';
import TAS from '../tas/tas.js';
import TopNavBar from '../top-navbar/top-navbar.js';
import Admin from '../admin/admin.js';
import TruckStopsList from '../truckstops/truckstops.js';
import Schedule from '../schedule/schedule.js';
import InAppMessages from '../messages/inappmessages/inappmessages.js';
import SMSMessages from '../messages/smsmessages/smsmessages.js';
import PushMessages from '../messages/pushmessages/pushmessages.js';
import NewTruckStop from '../newtruckstop/newtruckstop.js';
import Settings from '../settings/settings.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NECRoutes({ currentUser = currentUser, siteConfig = siteConfig }) {

    let menuConfig = siteConfig.currentPreset.menuConfig;

    return (
        <Routes>
            <Route path="/login" element={currentUser ? (
                <Navigate to="/" replace />
            ) : (
                <Login />
            )
            } />
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <div className="App">
                            <div class="wrapper">
                                <div class="section">
                                    <TopNavBar />
                                    <div class="sidebar">
                                        <Sidebar menuConfig={menuConfig} />
                                    </div>
                                    <TAS siteConfig={siteConfig}/>
                                </div>
                            </div>
                        </div>
                    </PrivateRoute>
                } />
            <Route
                path="/tas"
                element={
                    <PrivateRoute>
                        <div className="App">
                            <div class="wrapper">
                                <div class="section">
                                    <TopNavBar />
                                    <div class="sidebar">
                                        <Sidebar menuConfig={menuConfig} />
                                    </div>
                                    <TAS siteConfig={siteConfig}/>
                                </div>
                            </div>
                        </div>
                    </PrivateRoute>
                } />
        </Routes>
    );
}

export default NECRoutes;