import React, { useState, useEffect } from "react";
import styles from "./live.module.css";
import Tooltip from "@mui/material/Tooltip";
import ConfirmScenarioPopup from "./confirmscenariopopup";
import {
  dbGetCurrentScenario,
  dbSetActiveScenario,
  getFirestoreDocs,
} from "../utils/db";

function ActiveScenariosPanel({ scenarioChangeCallback }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState({});
  const [liveScenario, setLiveScenario] = useState({});
  const [allScenarios, setAllScenarios] = useState([]);

  useEffect(() => {
    getCurrentScenario();
    getScenarios();
  }, []);

  const handleRowClick = (scenario) => {
    setSelectedScenario(scenario);
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    setLiveScenario(selectedScenario);
    setIsModalOpen(false);

    // Move selected scenario to the top
    const updatedList = allScenarios.filter(
      (item) => item.id !== selectedScenario.id,
    );
    setAllScenarios([selectedScenario, ...updatedList]);

    const newId = selectedScenario.id;
    await dbSetActiveScenario(newId);
    scenarioChangeCallback instanceof Function && scenarioChangeCallback(newId);
  };

  const getCurrentScenario = async () => {
    const data = await dbGetCurrentScenario();
    setLiveScenario(data);
  };

  const getScenarios = async () => {
    const data = await getFirestoreDocs("scenarios", [], (doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    setAllScenarios(data);
  };

  return (
    <div className={styles.scenariosListContainer}>
      <h1>Select Scenario</h1>

      <div className={styles.scenariosContainer}>
        {allScenarios.map((scenario, index) => (
          <Tooltip
            key={index}
            title="Pull in the description from the scenario to go here"
            placement="right"
            arrow
          >
            <div
              className={`${styles.scenarioRow} ${selectedScenario.id === scenario.id ? styles.selectedScenario : ""}`}
              onClick={() => handleRowClick(scenario)}
            >
              <div className={styles.scenarioContent}>
                <p>
                  {scenario.data.name}
                  {liveScenario.id === scenario.id && (
                    <span className={styles.liveText}> LIVE</span>
                  )}
                </p>
              </div>
            </div>
          </Tooltip>
        ))}
      </div>

      <ConfirmScenarioPopup
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        scenario={selectedScenario}
      />
    </div>
  );
}

export default ActiveScenariosPanel;
