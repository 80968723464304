import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styles from './sidebar.module.css';
import { useNavigate } from 'react-router-dom';

function Sidebar({ menuConfig, currentUser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [filteredMenuConfig, setFilteredMenuConfig] = useState([]);

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.subMenu) {
      toggleDropdown(menuItem.id);
    } else {
      navigate(menuItem.url);
    }
  };

  const handleSubItemClick = (subItem, event) => {
    event.stopPropagation();
    navigate(subItem.url);
  };

  const toggleDropdown = (menuItemId) => {
    setOpenDropdowns((prevOpenDropdowns) => {
      if (prevOpenDropdowns.includes(menuItemId)) {
        return prevOpenDropdowns.filter((id) => id !== menuItemId);
      } else {
        return [...prevOpenDropdowns, menuItemId];
      }
    });
  };

  // Filter menuConfig based on the user's role
  useEffect(() => {
    if (currentUser) {
      setFilteredMenuConfig(menuConfig.filter(
        (menuItem) => !menuItem.role || menuItem.role.includes(currentUser.role)
      ));
    }
  }, [menuConfig, currentUser]);

  return (
    <row>
      <row>
        <img src="eloy-portals.png" className={styles.logo} width="60%" alt="Logo" />
      </row>
      <row>
        <ul>
          {filteredMenuConfig.map((menuItem) => (
            <React.Fragment key={menuItem.id}>
              <li
                className={`${styles.hoverstyle} ${menuItem.subMenu ? styles.dropdownBtn : ''} ${openDropdowns.includes(menuItem.id) ? styles.show : ''
                  }`}
                onClick={() => handleMenuItemClick(menuItem)}
              >
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={menuItem.icon} />
                </span>
                <span className={styles.item}>{menuItem.text}</span>
                {menuItem.subMenu && (
                  <FontAwesomeIcon icon={faCaretDown} className={styles.dropdownIcon} />
                )}
              </li>
              {menuItem.subMenu && (
                <div
                  className={`${styles.dropdownContainer} ${openDropdowns.includes(menuItem.id) ? styles.show : ''
                    }`}
                >
                  <ul>
                    {menuItem.subMenu.map((subItem) => (
                      <li
                        key={subItem.id}
                        className={styles.hoverstyle}
                        onClick={(e) => handleSubItemClick(subItem, e)}
                      >
                        <span className={styles.dropdownItem}>{subItem.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </React.Fragment>
          ))}
        </ul>
      </row>
    </row>
  );

}

export default Sidebar;


// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import styles from './sidebar.module.css';
// import { useNavigate } from 'react-router-dom';

// function Sidebar({ menuConfig, currentUser }) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [openDropdowns, setOpenDropdowns] = useState([]);

//   const handleMenuItemClick = (menuItem) => {
//     if (menuItem.subMenu) {
//       toggleDropdown(menuItem.id);
//     } else {
//       navigate(menuItem.url);
//     }
//   };

//   const handleSubItemClick = (subItem, event) => {
//     event.stopPropagation();
//     navigate(subItem.url);
//   };

//   const toggleDropdown = (menuItemId) => {
//     setOpenDropdowns((prevOpenDropdowns) => {
//       if (prevOpenDropdowns.includes(menuItemId)) {
//         return prevOpenDropdowns.filter((id) => id !== menuItemId);
//       } else {
//         return [...prevOpenDropdowns, menuItemId];
//       }
//     });
//   };

//   return (
//     <row>
//       <row>
//         <img src="eloy-portals.png" className={styles.logo} width="60%" alt="Logo" />
//       </row>
//       <row>
//         <ul>
//           {menuConfig.map((menuItem) => (
//             <React.Fragment key={menuItem.id}>
//               <li
//                 className={`${styles.hoverstyle} ${menuItem.subMenu ? styles.dropdownBtn : ''} ${
//                   openDropdowns.includes(menuItem.id) ? styles.show : ''
//                 }`}
//                 onClick={() => handleMenuItemClick(menuItem)}
//               >
//                 <span className={styles.icon}>
//                   <FontAwesomeIcon icon={menuItem.icon} />
//                 </span>
//                 <span className={styles.item}>{menuItem.text}</span>
//                 {menuItem.subMenu && (
//                   <FontAwesomeIcon icon={faCaretDown} className={styles.dropdownIcon} />
//                 )}
//               </li>
//               {menuItem.subMenu && (
//                 <div
//                   className={`${styles.dropdownContainer} ${
//                     openDropdowns.includes(menuItem.id) ? styles.show : ''
//                   }`}
//                 >
//                   <ul>
//                     {menuItem.subMenu.map((subItem) => (
//                       <li
//                         key={subItem.id}
//                         className={styles.hoverstyle}
//                         onClick={(e) => handleSubItemClick(subItem, e)}
//                       >
//                         <span className={styles.dropdownItem}>{subItem.text}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </React.Fragment>
//           ))}
//         </ul>
//       </row>
//     </row>
//   );
// }

// export default Sidebar;