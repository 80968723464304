// Function to decode polyline
export function decodePath(encodedPath) {
  let index = 0;
  const len = encodedPath.length;
  let lat = 0;
  let lng = 0;
  const path = [];

  while (index < len) {
    let b;
    let shift = 0;
    let result = 0;

    do {
      b = encodedPath.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;

    do {
      b = encodedPath.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    path.push([lat / 1e5, lng / 1e5]);
  }

  return path;
}
