import { Truck } from './trucks.js';
import { getFirestore, collection, getDocs, query, where, doc } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import { decodePath } from '../../utils/utils.js';
import { CustomTime } from './custom-time.js';
import { RouteService } from '../../../api/route-service.js';
import { ScheduleService } from '../../../api/schedule-service.js';

import 'leaflet-geometryutil';
import L from 'leaflet';
import { GlobalVariables } from '../../utils/global-variables.js'
import { SiteConfig } from '../../../site-config.js';

export class LiveScheduler {
    constructor(services, customTime, setDoverChartData, enableScheduler) {
        this.portalTimeBins = []
        this.portals = [];

        this.services = services;
        this.customTime = customTime;
        this.schedulerStartTime = this.customTime.getCurrentTime();
        console.log(SiteConfig);
        // this.routeService = new RouteService(SiteConfig.routeServerBaseUrl);
        // this.maxTrucksPerTimeSlot = 10;
        this.waitTimeAtService = 15; // 30 minutes stop time at the service
        this.setDoverChartData = setDoverChartData;
        this.enableScheduler = enableScheduler;
        this.scheduleService = new ScheduleService(SiteConfig.scheduleServerBaseUrl);
        this.setDoverChartData([]);
    }

    async scheduleTruck(truck) {
        console.log('scheduleTruck');
        const destinationId = truck.portalPoint.id;
        const schedule = await this.scheduleService.schedule(truck.currentLocation.latitude, truck.currentLocation.longitude, destinationId, true, {});
        // console.log(schedule);
        truck.scheduleId = schedule.id;

        const routeObject = JSON.parse(schedule.route);
        for (var legIndex in routeObject.legs) {
            routeObject.legs[legIndex].departureTime = new Date(routeObject.legs[legIndex].departureTime);
            routeObject.legs[legIndex].arrivalTime = new Date(routeObject.legs[legIndex].arrivalTime);
        }
        truck.updateRoute(truck.currentLocation, truck.portalPoint, routeObject);
        // console.log(routeObject);
        // const scheduleId = routeObject.legs[routeObject.legs.length - 1].to.id;
        // const to = { scheduleId: scheduleId };

        // Construct the route with legs
        // const routeWithLegs = {
        //     legs: [
        //         {
        //             from: truck.currentLocation,
        //             to: selectedService,
        //             route: serviceRoute,
        //             arrivalTime: this.getArrivalTime(serviceRoute)
        //         },
        //         {
        //             from: selectedService,
        //             to: portalPoint,
        //             route: portalRoute,
        //             departureTime: departureTimeFromService,
        //             arrivalTime: earliestAvailableTime
        //         }
        //     ]
        // };


        // truck.slotIndex = portalSlotIndex;

        // // Update the truck route with the new route object containing legs
        // truck.updateRoute(truck.currentLocation, portalPoint, routeWithLegs);
        return true;

        // check if the scheduled arrival time bins have been created for this portal
        // truck.portalId
        // if there is no existing timebins object then we create it
        // this will just be the indexed using the portalid
        // The object at this stage will just be an empty array
        // but once the time of arrival at the destination is calculated then
        // this will be the index of the time bin in 30 minute increments from the momeent the first truck is added
        // then we will fetch the route from the truck location to the portal location
        // then we will find the time index of the arrival time as estimated from the route
        // then we will look into the portal timebins, using this index and retrieve the number of trucks
        // arriving at this time.
        // If the number of trucks is below the maxTrucksPerTimeSlot threshold then we will go ahead and book in the truck
        // This involves adding one to the portalTimeBins and updating the route on the truck object
        // if this time bucket is booked out then we want to find routes from the current truck location to the services
        // and also the route from the services to the portal
        // From this we will have new times of arrival, one for each of the services
        // Then we need to add a minimum stop time for the truck at the services 30 minutes.
        // Then we need to look into the portalTimeBins[truck.portalId] and retrieve the number of trucks booked into these two slots.
        // Allocating the time slot involves adding a delay to the route at the truck stop so that the truck arrives
        // at the designated time. We need to construct a route object that has legs. The first leg is the journey to
        // the truck stop and the second the joureny to the portal. We add an additional parameter departureTime to the second leg
        // to indicate the time at which the truck should leave the truck stop.
        // Of these two initial time slot checks we should allocate the truck to the earliest
        // However if both time slots are fully booked then we need to go forwards in time through the time slots until
        // we get to a vaccant slot. Then we pick the earliest one - if two are found.
        // And as just described, we nee to construct the route object in legs format and add the departureTime such that the truck arrives at the portal in the middle of its time slot
        // Here are the functions and signatures that can be used:
        // const route = await this.routeService.fetchRoute(truck.currentLocation, portalPoint);
        // const path = decodePath(route.points);
        // truck.updateRoute(truck.currentLocation, portalPoint, route, path);
        // as you can see the update route takes a single path, we need legs now in all cases, even if there is just one
        // so perhaps we need to construct a new kind of route object that is different from the returned from fetchRoute and contains the legs.
        // Please change the signature on the updateRoute function to accept the new kind of route object, currentLocation, portalPoint which prob just removes path and make sure you pass a consistent object with legs in all calls.
        // The legs object should be an array.
        // Please add it to the route object as new property and just pass that around.
        // Once you have calculated the new routes includeing the stop at the services, dont forget that you still need to look into the arrivaltimes and check if there are slot available at the time.
        // and then follow the logic above to find the next available slot


        // this.setFlowyValuesDover([Math.random() * 500, 497, 506, 672, 645, 587, 484, 425, 427, 436, 578, 401, 399, 365, 426, 564, 635, 425, 564, 398, 365, 376, 345, 404, 398, 365]);
        // {
        //     labels: [], //flowxValuesDover,
        //     datasets: [{
        //         backgroundColor: [], //barColorsDover,
        //         data: [], //flowyValuesDover
        //     }]
        // }
        const prevSlotIndex = truck.slotIndex;

        const portalId = truck.portalPoint.id;
        const portalPoint = truck.portalPoint;
        const lastUpdateTime = truck.lastUpdateTime;
        const maxTrucksPerTimeSlot = Math.round(truck.portalPoint.portalFlow / (60 / 15));

        this.portals[portalId] = truck.portalPoint;

        if (!this.portalTimeBins[portalId]) {
            this.portalTimeBins[portalId] = [];
        }



        const route = await this.routeService.fetchRoute(truck.currentLocation, portalPoint);
        if (!route) {
            return false;
        }
        route.path = decodePath(route.points);
        const arrivalTime = this.getArrivalTime(route);
        const slotIndex = this.getTimeSlotIndex(arrivalTime);

        if (this.portalTimeBins[portalId][slotIndex] == undefined) {
            this.portalTimeBins[portalId][slotIndex] = 0;
        }

        // if(prevSlotIndex && this.portalTimeBins[portalId][prevSlotIndex] > 0){
        // if (prevSlotIndex) {
        //     this.portalTimeBins[portalId][prevSlotIndex]--;
        // }

        if (!this.enableScheduler || this.services.length == 0 || this.portalTimeBins[portalId][slotIndex] < maxTrucksPerTimeSlot) {
            if (prevSlotIndex) {
                this.portalTimeBins[portalId][prevSlotIndex]--;
            }
            this.portalTimeBins[portalId][slotIndex]++;
            console.log(`portalid:${portalId}\tslotIndex: ${slotIndex}\ttrucks:${this.portalTimeBins[portalId][slotIndex]} init`);
            const routeWithLegs = {
                legs: [
                    {
                        from: truck.currentLocation,
                        to: portalPoint,
                        route: route,
                        arrivalTime: arrivalTime
                    }
                ]
            };
            truck.slotIndex = slotIndex;
            truck.lastUpdateTime = lastUpdateTime;
            truck.updateRoute(truck.currentLocation, portalPoint, routeWithLegs);
        } else {

            const selectedServiceIndex = Math.floor(Math.random() * this.services.length);
            const selectedService = this.services[selectedServiceIndex];


            const serviceRoute = await this.routeService.fetchRoute(truck.currentLocation, selectedService);
            if (!serviceRoute) {
                return false;
            }
            serviceRoute.path = decodePath(serviceRoute.points);
            const portalRoute = await this.routeService.fetchRoute(selectedService, portalPoint);
            if (!portalRoute) {
                return false;
            }
            portalRoute.path = decodePath(portalRoute.points);
            const portalArrivalTime = this.getArrivalTime(serviceRoute, portalRoute, this.waitTimeAtService);
            let portalSlotIndex = this.getTimeSlotIndex(portalArrivalTime);
            let foundSlot = false;
            let earliestAvailableSlotIndex = -1;
            let earliestAvailableTime = Number.MAX_SAFE_INTEGER;

            // check if the time bin exists
            // if it does then see if there are less than the mass bookings
            // if is a space then we record the slot index
            // if not we increment the slot to check
            // var found = false;
            while (!foundSlot) {
                if (this.portalTimeBins[portalId][portalSlotIndex]) {
                    if (this.portalTimeBins[portalId][portalSlotIndex] < maxTrucksPerTimeSlot) {
                        foundSlot = true;
                        if (prevSlotIndex) {
                            this.portalTimeBins[portalId][prevSlotIndex]--;
                        }
                        this.portalTimeBins[portalId][portalSlotIndex]++;
                        console.log(`portalid:${portalId}\tslotIndex: ${portalSlotIndex}\ttrucks:${this.portalTimeBins[portalId][portalSlotIndex]} second`);
                    } else {
                        portalSlotIndex++;
                    }
                } else {
                    foundSlot = true;
                    if (prevSlotIndex) {
                        this.portalTimeBins[portalId][prevSlotIndex]--;
                    }
                    this.portalTimeBins[portalId][portalSlotIndex] = 1;
                    console.log(`portalid:${portalId}\tslotIndex: ${portalSlotIndex}\ttrucks:${this.portalTimeBins[portalId][portalSlotIndex]} first`);
                }
            }

            //this.getSlotTime(portalSlotIndex);

            // // Iterate through the portal slots to find the earliest available slot
            // for (let i = portalSlotIndex; i < this.portalTimeBins[portalId].length; i++) {
            //     if (this.portalTimeBins[portalId][i] < this.maxTrucksPerTimeSlot) {
            //         const slotTime = this.getSlotTime(i);
            //         if (slotTime < earliestAvailableTime) {
            //             earliestAvailableTime = slotTime;
            //             earliestAvailableSlotIndex = i;
            //             foundSlot = true;
            //             break;
            //         }
            //     }
            // }

            if (foundSlot) {
                // Calculate the departure time from the service to match the slot time
                const earliestAvailableTime = this.getSlotTime(portalSlotIndex);
                // const departureTimeFromService = earliestAvailableTime - this.calculateTravelTime(portalRoute);
                const departureTimeFromService = new Date(earliestAvailableTime.getTime() - this.calculateTravelTime(portalRoute) * 1000)

                serviceRoute.path = decodePath(serviceRoute.points)
                portalRoute.path = decodePath(portalRoute.points)

                // Construct the route with legs
                const routeWithLegs = {
                    legs: [
                        {
                            from: truck.currentLocation,
                            to: selectedService,
                            route: serviceRoute,
                            arrivalTime: this.getArrivalTime(serviceRoute)
                        },
                        {
                            from: selectedService,
                            to: portalPoint,
                            route: portalRoute,
                            departureTime: departureTimeFromService,
                            arrivalTime: earliestAvailableTime
                        }
                    ]
                };


                truck.slotIndex = portalSlotIndex;

                // Update the truck route with the new route object containing legs
                truck.updateRoute(truck.currentLocation, portalPoint, routeWithLegs);
                // this.portalTimeBins[portalId][portalSlotIndex]++;
            } else {
                // Handle case where no slots are available
                console.error("No available slots found for the truck.");
            }
        }

        this.updateChartData();

        return true;
    }

    // getSlotTime(slotIndex) {
    //     const startTime = this.schedulerStartTime;
    //     const slotDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
    //     const slotStartTime = new Date(startTime.getTime() + slotIndex * slotDuration);
    //     const slotMiddleTime = new Date(slotStartTime.getTime() + slotDuration / 2);
    //     return slotMiddleTime;
    // }

    updateChartData() {
        const currentTime = this.customTime.getCurrentTime();
        const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
        const endOfDay = new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000);

        const labels = [];

        let portalKeys = Object.keys(this.portals);
        let chartData = [];

        for (let i = 0; i < portalKeys.length; i++) {
            const portalId = portalKeys[i];
            const data = [];
            for (let time = startOfDay; time < endOfDay; time = new Date(time.getTime() + this.waitTimeAtService * 60 * 1000)) {
                const hour = time.getHours();
                const minute = time.getMinutes();
                const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                labels.push(formattedTime);

                const slotIndex = this.getTimeSlotIndex(time);
                if (slotIndex >= 0) {
                    const truckCount = this.portalTimeBins[portalId] && this.portalTimeBins[portalId][slotIndex] ? this.portalTimeBins[portalId][slotIndex] : 0;
                    data.push(truckCount);
                }
                else {
                    data.push(0);
                }
            }

            chartData.push({
                id: portalId,
                name: this.portals[portalId].name,
                description: this.portals[portalId].description,
                labels: labels,
                data: data,
            });
        }

        this.setDoverChartData(chartData);
    }

    getSlotTime(slotIndex) {
        const startTime = this.schedulerStartTime;
        const slotDuration = this.waitTimeAtService * 60 * 1000; // 30 minutes in milliseconds
        const slotStartTime = new Date(startTime.getTime() + slotIndex * slotDuration);
        const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
        const randomOffset = Math.random() * slotDuration;
        const randomTime = new Date(slotStartTime.getTime() + randomOffset);
        return randomTime;
    }

    getTimeSlotIndex(arrivalTime) {
        const startTime = this.schedulerStartTime;
        const timeDifference = arrivalTime - startTime;
        const slotIndex = Math.floor(timeDifference / (this.waitTimeAtService * 60 * 1000)); // Convert milliseconds to 30-minute slots
        return slotIndex; // Ensure the slot index is not negative
    }

    calculateTravelTime(route) {
        let totalTravelTime = 0;
        for (let i = 0; i < route.path.length - 1; i++) {
            const currentPoint = route.path[i];
            const nextPoint = route.path[i + 1];
            const segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));
            const speedInterval = route.details.average_speed.find(interval => interval[0] <= i && i < interval[1]);
            const speed = speedInterval ? speedInterval[2] / 3.6 : 1; // Default speed to 1 m/s if not found
            const travelTime = segmentDistance / speed;
            totalTravelTime += travelTime;
        }
        return totalTravelTime;
    }

    getArrivalTime(route, additionalRoute = null, waitTime = 0) {
        let totalTravelTime = this.calculateTravelTime(route);

        if (additionalRoute) {
            totalTravelTime += this.calculateTravelTime(additionalRoute);
        }

        // Convert wait time from minutes to milliseconds and add to total travel time
        totalTravelTime += waitTime * 60;

        // Calculate the arrival time based on the current time and the total travel time
        const arrivalTime = new Date(this.customTime.getCurrentTime().getTime() + totalTravelTime * 1000);

        return arrivalTime;
    }
}



