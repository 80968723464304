import React, { useEffect, useState } from 'react';
import { collection, doc, deleteDoc, addDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import { RouteService } from '../../../api/route-service.js';
import styles from './simulation-speed-panel.module.css';

function SimulationSpeedPanel({ simulationSpeed, setSimulationSpeed, customTime, enableScheduler, setEnableScheduler, truckSpawnRate, setTruckSpawnRate, scenarioId, isTesting }) {

    const [currentTime, setCurrentTime] = useState(customTime.getCurrentTime());
    const isDisabled = scenarioId === null;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(customTime.getCurrentTime());
        }, 1000);  // Update every second

        return () => clearInterval(intervalId);  // Cleanup on unmount
    }, [customTime]);

    return (

        <div className={`${styles.simulationSpeedListContainer} ${isDisabled ? styles.disabledPanel : ''}`}>
            <column>
                <row>
                    <h2>Simulation speed</h2>
                </row>

                {/* SLIDER */}

                <input
                    type="range"
                    value={simulationSpeed}
                    onChange={(e) => setSimulationSpeed(e.target.value)}
                    min="1"
                    max="300"
                    step="1"
                    className={styles.simulationSpeedSlider}
                    disabled={isDisabled}
                />
                <label className={styles.simulationSpeedLabel}>
                    Simulation speed multiplier: {simulationSpeed}
                </label>

                <row>
                    <label className={styles.simulationSpeedLabel}>
                        Time: {currentTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </label>
                </row>


                {/* SCHEDULER */}

                {!isTesting && (
                    <label className={styles.checkboxContainer}>
                        {enableScheduler ? 'Scheduler enabled' : 'Scheduler disabled'}
                        <input
                            type="checkbox"
                            checked={enableScheduler}
                            onChange={(e) => setEnableScheduler(e.target.checked)}
                            disabled={isDisabled}
                        />
                        <span className={styles.checkboxCheckmark}></span>
                    </label>
                )}

                {/* SPAWN RANTE */}
                <row>
                    <h2>Truck spawn rate</h2>
                </row>
                {/* <label className={styles.simulationSpeedCheckboxLabel}>(trucks per hour)</label> */}
                <input
                    type="range"
                    value={truckSpawnRate}
                    onChange={(e) => setTruckSpawnRate(e.target.value)}
                    min="1"
                    max="100"
                    step="1"
                    className={styles.simulationSpeedSlider}
                    disabled={isDisabled}
                />
                <label className={styles.simulationSpeedLabel}>
                    Trucks per hour: {truckSpawnRate}
                </label>
                {/* </div> */}
            </column>
        </div>
    );
}

export { SimulationSpeedPanel };