import { useMapEvents } from 'react-leaflet';

export function MapEventsHandler({ onMapClick, onMapDrag, addingRoute, handleRouteMapClick, handleAddWaypoint  }) {
    useMapEvents({
        click: (e) => {
            if (addingRoute) {
              handleRouteMapClick(e);
            } else {
              onMapClick(e);
            }
          },      
        drag: onMapDrag,
        contextmenu: (e) => {
            handleAddWaypoint(e);
          },
      
        // move
    });

    return null; // This component does not render anything
}