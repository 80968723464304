// import L from 'leaflet';

// const PortalIcon = new L.Icon({
//     iconUrl: 'logout_black_24dp.svg',
//     iconRetinaUrl: 'logout_black_24dp.svg',
//     iconAnchor: null,
//     popupAnchor:  [-0, -0],
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(30, 30),
//     className: 'leaflet-div-icon'
// });

// export { PortalIcon };



import L from 'leaflet';
import serviceIconUrl from './ferry.svg';

const PortalIcon = new L.Icon({
  iconUrl: serviceIconUrl,
  iconRetinaUrl: serviceIconUrl, 
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(28, 28),
  className: 'leaflet-div-icon',

});

export { PortalIcon };


