import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import styles from './reports.module.css';
import Chart from 'chart.js/auto';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';

const Reports = () => {
    const historicalChartRef = useRef(null);
    const journeyChartRef = useRef(null);
    const oSChartRef = useRef(null);    

    const historicalxValues = ['Apr 1', 'Apri 2', 'Apr 3', 'Apr 4', 'Apr 5', 'Apr 6', 'Apri 7', 'Apr 8', 'Apr 9', 'Apr 10', 'Apr 11', 'Apr 12', 'Apr 13', 'Apr 14', 'Apr 15'];
    const historicalyValues = [417, 418, 518, 525, 426, 453, 428, 438, 414, 434, 425, 516, 517, 449, 428];

    const journeyxValues = ['Apr 1', 'Apri 2', 'Apr 3', 'Apr 4', 'Apr 5', 'Apr 6', 'Apri 7', 'Apr 8', 'Apr 9', 'Apr 10', 'Apr 11', 'Apr 12', 'Apr 13', 'Apr 14', 'Apr 15'];
    const journeyy1Values = [180, 187, 210, 206, 179, 168, 175, 181, 183, 179, 174, 214, 219, 177, 165];
    const journeyy2Values = [231, 233, 257, 246, 212, 225, 226, 225, 219, 222, 230, 258, 246, 212, 211];

    var loadxValues = ["Android", "iOS",];
    var loadyValues = [195, 107,];
    var barColors = [
        "#ffd7b5",
        "#a0e4fd",
    ];
    

    useEffect(() => {
        const historicalChart = new Chart(historicalChartRef.current, {
            type: "line",
            data: {
                labels: historicalxValues,

                datasets: [{

                    fill: false,
                    lineTension: 0,
                    backgroundColor: "#024761",
                    borderColor: "#024761",
                    borderWidth: 2,
                    data: historicalyValues,
                    pointRadius: 3,
                    responsive: true,
                    maintainAspectRatio: true,
                }, {
                }]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: { min: 350, max: 550 }
                    }],
                    xAxes: [{
                        display: true,
                        ticks: { display: true },
                        gridLines: {
                            display: false
                        }
                    }]
                }
            }
        });

        const journeyChart = new Chart(journeyChartRef.current, {
            type: "line",
            data: {
                labels: journeyxValues,
               
                datasets: [{
                   
                    fill: false,
                    lineTension: 0,
                    backgroundColor: "#024761",
                    borderColor: "#024761",
                    borderWidth: 2,
                    data: journeyy1Values,
                    pointRadius: 3,
                    responsive: true,
                    maintainAspectRatio: true,
                }, {
                   
                    fill: false,
                    lineTension: 0,
                    backgroundColor: "#FF5733",
                    borderColor: "#FF5733",
                    borderWidth: 2,
                    borderDash: [2,5],
                    data: journeyy2Values,
                    pointRadius: 3,
                    responsive: true,
                    maintainAspectRatio: true,
                }]
            },
            options: {
                legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: { min: 140, max: 280 }
                    }],
                    xAxes: [{
                        display: true,
                        ticks: { display: true },
                        gridLines: {
                            display: false
                        }
                    }]
                }
            }
        });

        const OSChart = new Chart(oSChartRef.current, {
            type: "doughnut",
            data: {
                labels: loadxValues,
                datasets: [{
                    backgroundColor: barColors,
                    data: loadyValues
                }]
            },
            options: {
                title: {
                    display: false,
                    maintainAspectRatio: true,
        
                },
                legend: {
                    position: 'top'
                }
            }
        });

        return () => {
            historicalChart.destroy();
            journeyChart.destroy();
            OSChart.destroy();            
        };
    }, []);


    return (
      
            <double-column>
                <row>
                    <h1>Reports</h1>


                    <row>
                        <column>
                            <dashboard-container>
                                <row>
                                    <h2>Historical flow rate</h2><br />
                                </row>

                                <reports-row>
                                    <timescale-buttons>

                                        <button class="button">Today</button>
                                        <button class="button">Last 7 days</button>
                                        <button class="button">Last 30 days</button>
                                        <button class="button">This month</button>
                                        <button class="button">Last month</button>
                                        <button class="button">Custom</button>

                                    </timescale-buttons>
                                    <export-buttons-row>

                                        <export-button>
                                            <a button onclick="downloadXLS()">
                                                {/* <span class="material-symbols-outlined ios_share">
                                                    ios_share
                                                </span> */}
                                                <IosShareOutlinedIcon/>
                                                <span class="text">Export to XLS</span>
                                            </a>
                                        </export-button>
                                        <export-button>
                                            <a button onclick="downloadPDF()">
                                            
                                                <IosShareOutlinedIcon/>
                                                <span class="text">Export to PDF</span>
                                            </a>
                                        </export-button>
                                    </export-buttons-row>
                                </reports-row>

                                <row>
                                    <canvas ref={historicalChartRef} className={styles.historicalFlowChart}></canvas>
                                
                                </row>
                            </dashboard-container>
                        </column>
                    </row>

                    <row>
                        <column>
                            <dashboard-container>
                                <row>
                                    <h2>Journey times</h2>
                                </row>
                                <reports-row>
                                    <timescale-buttons>

                                        <button class="button">Previous year</button>

                                        <button class="button">Previous period</button>

                                    </timescale-buttons>
                                    <export-buttons-row>

                                    <export-button>
                                            <a button onclick="downloadPDF()">
                                            
                                                <IosShareOutlinedIcon/>
                                                <span class="text">Export to XLS</span>
                                            </a>
                                        </export-button>
                                        <export-button>
                                            <a button onclick="downloadXLS()">
                                              
                                                <IosShareOutlinedIcon/>
                                                <span class="text">Export to PDF</span>
                                            </a>
                                        </export-button>
                                    </export-buttons-row>
                                </reports-row>
                                <row>
                                
                                    <canvas ref={journeyChartRef}></canvas>
                                    {/* <canvas id="journeyChart"></canvas> */}
                                </row>
                            </dashboard-container>
                        </column>

                        <column>
                            <dashboard-container>
                                <row>
                                    <h2>xxx</h2>
                                </row>

                            </dashboard-container>
                        </column>
                    </row>

                    <half-column>
                        <dashboard-container>

                            <row>
                                <h2>Operating systems</h2>
                            </row>
                            <canvas ref={oSChartRef}></canvas>
                        </dashboard-container>
                    </half-column>


                    <column>
                        <dashboard-container>
                            <row>

                            </row>

                        </dashboard-container>
                    </column>

                    <column>
                        <dashboard-container>
                            <row>

                            </row>

                        </dashboard-container>
                    </column>

                </row>
            </double-column>
      
    );
}

export default Reports;