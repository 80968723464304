import React, { useEffect, useState } from "react";
import styles from "./matrix.module.css";
import { firestore } from "../../firesbase.js";
import { collection, getDocs } from "firebase/firestore";

function Matrix({ callback }) {
  const [scenarios, setScenarios] = useState([]);
  const [adjList, setAdjList] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    getScenarios();
  }, []);

  const getScenarios = async () => {
    const querySnapshot = await getDocs(collection(firestore, "scenarios"));
    const data = [];
    querySnapshot.forEach((doc) =>
      data.push({
        id: doc.id,
        data: doc.data(),
      }),
    );
    setScenarios(data);

    const n = data.length;
    // make n x n array of false
    setAdjList([...Array(n)].map((_) => Array(n).fill(false)));
  };

  return (
    <>
      <style type="text/css" style={{ display: "none" }}>
        {`
.matrixRow {
  --hover-trace: #eee;
  --heading-trace: #024761;

  display: contents;

  & > * {
    background-color: white;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
  }

  /* row trace */
  &:hover > *,
  /* under column trace */
  &:hover ~ * > *:nth-child(${offset}),
  /* over column trace */
  *:has(~ &:hover) > *:nth-child(${offset}) {
    background-color: var(--hover-trace);
  }

  /* heading color change */
  &:hover :first-child,
  *:has(~ &:hover):first-child :nth-child(${offset}) {
    background-color: var(--heading-trace);
    color: white;
  }

  /* hover under mouse */
  & > *:hover {
    background-color: #BBBBBB;
  }
}
        `}
      </style>
      <div
        className={styles.matrix}
        style={{
          gridTemplateColumns: `repeat(${scenarios.length + 1}, 1fr)`,
          width: "100%",
        }}
      >
        <div className="matrixRow">
          <div className={styles.empty}></div>
          {scenarios.map((obj) => (
            <div className={styles.matrixHeading}>{obj?.data?.name}</div>
          ))}
        </div>

        {scenarios.map((obj, i) => (
          <div className="matrixRow">
            <div className={styles.matrixHeading}>{obj?.data?.name}</div>
            {scenarios.map((_, j) =>
              i == j ? (
                <div className={styles.empty}></div>
              ) : (
                <label
                  className="checkbox-container"
                  onMouseOver={() => setOffset(j + 2)}
                >
                  <input
                    type="checkbox"
                    onClick={() =>
                      setAdjList((prev) => {
                        const newList = prev.with(
                          i,
                          prev[i].with(j, !prev[i][j]),
                        );
                        // get [[id, id], ...] from [[bool, bool, ...], ...]
                        const ids = newList.flatMap((row, n) =>
                          row.reduce(
                            (innerAcc, isTrue, m) =>
                              isTrue
                                ? [
                                    ...innerAcc,
                                    `${scenarios[m].id},${scenarios[n].id}`,
                                  ]
                                : innerAcc,
                            [],
                          ),
                        );
                        console.log(ids);
                        callback(ids);
                        return newList;
                      })
                    }
                  />
                  <span
                    className="checkbox-checkmark"
                    style={{ position: "relative" }}
                  ></span>
                </label>
              ),
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default Matrix;
