import React, { useState } from "react";
import styles from "./admin.module.css";
import Modal from "./invite-team-popup/invite-team-popup.js";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Tooltip from "@mui/material/Tooltip";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Typography from "@mui/material/Typography";

import EmailTab from "./emailtab/emailtab.js";

import InBoxTab from "./inboxtab/inboxtab.js";
import UnreadTab from "./unreadtab/unreadtab.js";
import SentTab from "./senttab/senttab.js";
import DraftsTab from "./draftstab/draftstab.js";
import StarredTab from "./starredtab/starredtab.js";
import ArchiveTab from "./archivetab/archivetab.js";
import TrashTab from "./trashtab/trashtab.js";

import { createTheme } from "@mui/material";
import { useEffect } from "react";
import { dbUsersPagination } from "../utils/db";
import { and, doc, where } from "firebase/firestore";
import { firestore } from "../../firesbase";

function CustomTabPanel(props) {
  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  });

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography sx={{ textTransform: "none" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Admin() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [usersState, setUsersState] = useState(null);
  const [nextPageFunc, setNextPageFunc] = useState(null);
  const [prevPageFunc, setPrevPageFunc] = useState(null);
  const [paginationText, setPaginationText] = useState("loading...");
  const [searchText, setSearchText] = useState("");
  const [roleOption, setRoleOption] = useState("any");

  useEffect(() => {
    getPagination();
  }, []);

  const getPagination = async (...constraints) => {
    const [initialState, prev, next] = await dbUsersPagination(...constraints);
    setPrevPageFunc((_) => prev);
    setNextPageFunc((_) => next);
    setUsersState(initialState);
    setPaginationText(initialState.getPageCountText());
  };

  const getNextUsers = async () => {
    const newState = await nextPageFunc(usersState);
    setUsersState(newState);
    setPaginationText(newState.getPageCountText());
  };

  const getPrevUsers = async () => {
    const newState = await prevPageFunc(usersState);
    setUsersState(newState);
    setPaginationText(newState.getPageCountText());
  };

  const updateSearch = async (newRoleOption = roleOption) => {
    console.log(`${newRoleOption} ${searchText}`);
    const roleWhere = where("role", "==", newRoleOption);
    const emailWhere = where("email", ">=", searchText);

    if (newRoleOption === "any" && searchText === "") {
      await getPagination();
    } else if (searchText === "") {
      await getPagination(roleWhere);
    } else if (newRoleOption === "any") {
      await getPagination(emailWhere);
    } else {
      await getPagination(and(roleWhere, emailWhere));
    }
  };

  const roleSelectChange = ({ target }) => {
    setRoleOption(target.value);
    updateSearch(target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <row>
      <h1>Admin</h1>
      <row>
        <row>
          <column>
            <row>
              <row>
                <dashboard-container>
                  <row>
                    <h3>Tasks</h3>
                  </row>
                  <table
                    id="events"
                    class="events-table"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th class="number"></th>
                        <th class="title">Title</th>
                        <th class="priority">Priority</th>
                        <th class="category">Category</th>
                        <th class="type">Type</th>
                        <th class="duedate">Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="number">
                          <p>1</p>
                        </td>
                        <td class="title">TAS not working</td>
                        <td class="priority">
                          <button
                            className={`${styles.priorityButton} ${styles.urgent}`}
                          >
                            Urgent
                          </button>
                        </td>
                        <td class="category">TAS</td>
                        <td class="type">
                          <Tooltip title="Bug" arrow>
                            <BugReportOutlinedIcon />
                          </Tooltip>
                        </td>
                        <td class="duedate">15 Apr 2024</td>
                      </tr>

                      <tr>
                        <td class="number">
                          <p>2</p>
                        </td>
                        <td class="title">New language request - Greek</td>
                        <td class="priority">
                          <button
                            className={`${styles.priorityButton} ${styles.request}`}
                          >
                            Request
                          </button>
                        </td>
                        <td class="category">App</td>
                        <td class="type">
                          <Tooltip title="Request" arrow>
                            <ContactSupportOutlinedIcon />
                          </Tooltip>
                        </td>
                        <td class="duedate">17 Apr 2024</td>
                      </tr>

                      <tr>
                        <td class="number">
                          <p>3</p>
                        </td>
                        <td class="title">Truck stops not updating</td>
                        <td class="priority">
                          <button
                            className={`${styles.priorityButton} ${styles.important}`}
                          >
                            Important
                          </button>
                        </td>
                        <td class="category">App</td>
                        <td class="type">
                          <Tooltip title="Bug" arrow>
                            <BugReportOutlinedIcon />
                          </Tooltip>
                        </td>
                        <td class="duedate">20 Apr 2024</td>
                      </tr>
                      <tr>
                        <td class="number">
                          <p>4</p>
                        </td>
                        <td class="title">
                          I have an idea for the next update
                        </td>
                        <td class="priority">
                          <button
                            className={`${styles.priorityButton} ${styles.nice}`}
                          >
                            Nice to have
                          </button>
                        </td>
                        <td class="category">Web portal</td>
                        <td class="type">
                          <Tooltip title="Feedback" arrow>
                            <ChatOutlinedIcon />
                          </Tooltip>
                        </td>
                        <td class="duedate">30 Apr 2024</td>
                      </tr>
                    </tbody>
                  </table>
                </dashboard-container>
              </row>
            </row>
          </column>
          <half-column>
            <row>
              <dashboard-container>
                <row>
                  <h3>Team</h3>
                </row>
                <profile-container>
                  <row>
                    <img src="man-port.jpg" class="pfp-image"></img>
                  </row>
                  <row>John Smith - Traffic manager</row>
                  <row>Role: Editor</row>
                </profile-container>
                <profile-container>
                  <row>
                    <img src="woman-port.jpg" class="pfp-image"></img>
                  </row>
                  <row>Jane Smith - Content manager</row>
                  <row>Role: Admin</row>
                </profile-container>
                <profile-container>
                  <row>
                    <img src="man2-port.jpg" class="pfp-image"></img>
                  </row>
                  <row>Joe Smith - TAS administrator</row>
                  <row>Role: Admin</row>
                </profile-container>

                <row>
                  <row>
                    <button class="button bluebutton" onClick={handleOpenModal}>
                      Invite team member
                    </button>
                  </row>
                </row>
                <row></row>
              </dashboard-container>
            </row>
          </half-column>
        </row>
        <row>
          <quarter-column>
            <dashboard-container>
              <row>
                <h3>Messages</h3>
              </row>
              <Box sx={{ borderBottom: 0, borderColor: "" }}>
                <Tabs
                  orientation="vertical"
                  indicatorColor="transparent"
                  className={styles.tabs}
                  value={value}
                  onChange={handleChange}
                  aria-label="Tabs"
                >
                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 0 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <InboxOutlinedIcon className={styles.tabIcon} />
                          <span>Inbox</span>
                        </div>
                        <div className={styles.blueCircle}>3</div>
                      </div>
                    }
                    {...a11yProps(0)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 1 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <VisibilityOffOutlinedIcon
                            className={styles.tabIcon}
                          />
                          <span>Unread</span>
                        </div>
                      </div>
                    }
                    {...a11yProps(1)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 1 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <SendOutlinedIcon className={styles.tabIcon} />
                          <span>Sent</span>
                        </div>
                      </div>
                    }
                    {...a11yProps(2)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 0 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <EditNoteOutlinedIcon className={styles.tabIcon} />
                          <span>Drafts</span>
                        </div>
                        <div className={styles.blueCircle}>4</div>
                      </div>
                    }
                    {...a11yProps(0)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 1 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <StarBorderOutlinedIcon className={styles.tabIcon} />
                          <span>Starred</span>
                        </div>
                      </div>
                    }
                    {...a11yProps(3)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 1 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <DeleteOutlineOutlinedIcon
                            className={styles.tabIcon}
                          />
                          <span>Archive</span>
                        </div>
                      </div>
                    }
                    {...a11yProps(3)}
                  />

                  <Tab
                    disableRipple
                    className={`${styles.tab} ${value === 1 ? styles.activeTab : ""}`}
                    label={
                      <div className={styles.tabButton}>
                        <div className={styles.tabLabel}>
                          <DeleteOutlineOutlinedIcon
                            className={styles.tabIcon}
                          />
                          <span>Trash</span>
                        </div>
                      </div>
                    }
                    {...a11yProps(3)}
                  />

                  {/* <Tab className={`${styles.tab} ${value === 1 ? styles.activeTab : ''}`} label="Unread" {...a11yProps(1)} /> */}
                  {/* <Tab className={`${styles.tab} ${value === 1 ? styles.activeTab : ''}`} label="Sent" {...a11yProps(2)} /> */}

                  {/* <Tab className={`${styles.tab} ${value === 1 ? styles.activeTab : ''}`} label="Trash" {...a11yProps(3)} /> */}
                  {/* <Tab className={`${styles.tab} ${value === 2 ? styles.activeTab : ''}`} label="Settings" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
            </dashboard-container>
          </quarter-column>

          <column>
            <dashboard-container>
              <email-button-row>
                <button class="button email-button">
                  <EditNoteOutlinedIcon />
                  Compose
                </button>
                <button class="button email-button">
                  <DeleteOutlineOutlinedIcon />
                  <ArchiveOutlinedIcon />
                  <VisibilityOffOutlinedIcon />
                </button>
                <button class="button email-button">
                  <AddOutlinedIcon />
                  More
                </button>
              </email-button-row>

              <CustomTabPanel value={value} index={0}>
                <InBoxTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <UnreadTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <SentTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DraftsTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <StarredTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <ArchiveTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={6}>
                <TrashTab />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={2}>
                        <InAppMessagesSettingsTab />
                    </CustomTabPanel> */}
            </dashboard-container>
          </column>
        </row>

        <row>
          <dashboard-container>
            <row>
              <h3>Support</h3>
            </row>
            <row>
              <third-column>
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search"
                ></input>
              </third-column>
              <column>
                <row>
                  Filter by
                  <button class="button email-button">
                    Priority
                    <ExpandMoreOutlinedIcon />
                  </button>
                  <button class="button email-button">
                    Category
                    <ExpandMoreOutlinedIcon />
                  </button>
                  <button class="button email-button">
                    Type
                    <ExpandMoreOutlinedIcon />
                  </button>
                  <button class="button email-button">
                    Status
                    <ExpandMoreOutlinedIcon />
                  </button>
                </row>
              </column>
            </row>
            <row>
              <button class="button email-button">
                <EditNoteOutlinedIcon />
                Create a new ticket
              </button>
            </row>
            <table
              id="events"
              class="events-table"
              cellspacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th class="number"></th>
                  <th class="title">Title</th>
                  <th class="priority">Priority</th>
                  <th class="category">Category</th>
                  <th class="type">Type</th>
                  <th class="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="number">
                    <p>1</p>
                  </td>
                  <td class="title">TAS not working</td>
                  <td class="priority">
                    <button
                      className={`${styles.priorityButton} ${styles.urgent}`}
                    >
                      Urgent
                    </button>
                  </td>
                  <td class="category">TAS</td>
                  <td class="type">
                    <Tooltip title="Bug" arrow>
                      <BugReportOutlinedIcon />
                    </Tooltip>
                  </td>
                  <td class="status">In progress</td>
                </tr>

                <tr>
                  <td class="number">
                    <p>2</p>
                  </td>
                  <td class="title">New language request - Greek</td>
                  <td class="priority">
                    <button
                      className={`${styles.priorityButton} ${styles.request}`}
                    >
                      Request
                    </button>
                  </td>
                  <td class="category">App</td>
                  <td class="type">
                    <Tooltip title="Feature" arrow>
                      <ContactSupportOutlinedIcon />
                    </Tooltip>
                  </td>
                  <td class="status">Assigned</td>
                </tr>

                <tr>
                  <td class="number">
                    <p>3</p>
                  </td>
                  <td class="title">Truck stops not updating</td>
                  <td class="priority">
                    <button
                      className={`${styles.priorityButton} ${styles.important}`}
                    >
                      Important
                    </button>
                  </td>
                  <td class="category">App</td>
                  <td class="type">
                    <Tooltip title="Bug" arrow>
                      <BugReportOutlinedIcon />
                    </Tooltip>
                  </td>
                  <td class="status">In progress</td>
                </tr>
                <tr>
                  <td class="number">
                    <p>4</p>
                  </td>
                  <td class="title">I have an idea for the next update</td>
                  <td class="priority">
                    <button
                      className={`${styles.priorityButton} ${styles.nice}`}
                    >
                      Nice to have
                    </button>
                  </td>
                  <td class="category">Web portal</td>
                  <td class="type">
                    <Tooltip title="Feedback" arrow>
                      <ChatOutlinedIcon />
                    </Tooltip>
                  </td>
                  <td class="status">Raised</td>
                </tr>
              </tbody>
            </table>
          </dashboard-container>
        </row>
        <row>
          <dashboard-container>
            <row>
              <h3>Users</h3>
            </row>
            <row>
              <third-column>
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                ></input>
                <button
                  class="button bluebutton"
                  style={{ display: "inline" }}
                  onClick={() => updateSearch()}
                >
                  Search
                </button>
              </third-column>
              <column>
                <row>
                  Filter by
                  <label style={{ margin: 0 }}>Role:</label>
                  <select value={roleOption} onChange={roleSelectChange}>
                    <option value="any">any</option>
                    <option value="admin">admin</option>
                    <option value="app_user">app_user</option>
                  </select>
                </row>
              </column>
            </row>
            {usersState !== null && (
              <>
                <table id="events" cellspacing="0" width="100%">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersState.items.map((user) => (
                      <tr>
                        <td>
                          <p>{user.id}</p>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.displayName}</td>
                        <td>{user.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    class="button bluebutton"
                    onClick={() => getPrevUsers()}
                  >
                    Prev
                  </button>
                  <span style={{ padding: "4px 16px" }}>{paginationText}</span>
                  <button
                    class="button bluebutton"
                    onClick={() => getNextUsers()}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
          </dashboard-container>
        </row>
      </row>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}></Modal>
    </row>
  );
}

export default Admin;

