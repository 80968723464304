import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import styles from './login.module.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          // New sign-in will be persisted with session persistence.
          var userCredentials = signInWithEmailAndPassword(auth, email, password);
          navigate('/');
          return userCredentials;
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
        });


      // navigate('/');
    } catch (error) {
      console.error("Error signing in with email and password", error);
    }
  };



  return (
    <div>
      <div className={styles.loginBackground}></div>
      <log-in-container>
        <row>
          <half-column>
            <img src="eloy petrol blue.png" alt="Eloy Logo" className={styles.center} />
            <h1 className={styles.titlePadding}>Welcome back</h1>
            <sign-in>Sign in to your acccount to access your traffic management dashboard</sign-in>
            <row>


            </row>
          </half-column>

          <half-column>
            <h1 className={styles.titlePadding}>Sign in</h1>



            <label for="username">Username</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <label for="password">Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <div type="submit" class="button bluebutton" onClick={signIn}>Sign in</div>
            <row>
              <half-column>


                <label className={styles.signinCheckboxContainer}>Remember me

                  <input
                    type="checkbox"


                  />
                  <span className={styles.signinCheckboxCheckmark}></span>
                </label>


              </half-column>
              <half-column><span className={styles.forgot}><a href="#">Forgot password?</a></span>


              </half-column>
            </row>

          </half-column>
        </row>
      </log-in-container>
    </div>
  );
}

export default Login;