class RouteService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async fetchRoute(spawnPoint, portalPoint) {
    const requestBody = {
      points: [
        [spawnPoint.longitude, spawnPoint.latitude],
        [portalPoint.longitude, portalPoint.latitude]
      ],
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed']
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.paths[0]; // Assuming the API returns a list of paths and we take the first one
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

  async fetchEdgeIds(points) {
    const requestBody = {
      points: points,
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed'],
      extra: "getedges",
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data; // Assuming the API returns a list of paths and we take the first one
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

  async addAccident(accident) {
    const requestBody = {
      points: [
        [accident.lng, accident.lat],
        [accident.lng, accident.lat]
      ],
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed'],
      extra: "addaccident",
      latitude: accident.lat,
      longitude: accident.lng,
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      //const data = await response.json();
      //return data.paths[0]; // Assuming the API returns a list of paths and we take the first one
      return true;
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

  async clearAccidents() {
    const requestBody = {
      points: [
        [0.0, 0.0],
        [0.0, 0.0]
      ],
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed'],
      extra: "clearaccidents",
      // latitude: accident.lat,
      // longitude: accident.lng,
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      // const data = await response.json();
      return true;
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

  async clearRoutes() {
    const requestBody = {
      points: [
        [0.0, 0.0],
        [0.0, 0.0]
      ],
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed'],
      extra: "clearroutes",
      // latitude: accident.lat,
      // longitude: accident.lng,
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      // const data = await response.json();
      return true;
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

  async addRoute(edgeIds) {
    const requestBody = {
      points: [
        [0,0],
        [0,0]
      ],
      profile: 'car',
      details: ['road_class', 'road_environment', 'max_speed', 'average_speed'],
      extra: "addroute",
      edgeIds: edgeIds
    };

    try {
      const response = await fetch(`${this.baseUrl}/route`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        return null;
        // throw new Error('Network response was not ok');
      }

      //const data = await response.json();
      //return data.paths[0]; // Assuming the API returns a list of paths and we take the first one
      return true;
    } catch (error) {
      console.error('Failed to fetch route:', error);
      return null;
      // throw error;
    }
  }

}

export { RouteService };