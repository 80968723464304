class CustomTime {
    constructor() {
        this.realLastUpdateTime = Date.now();
        this.imaginaryLastUpdateTime = Date.now();
        this.updateSpeed = 1;
    }

    setUpdateSpeed(speed) {
        this.updateSpeed = speed;
    }

    getCurrentTime() {
        const realCurrentTime = Date.now();
        const realTimeDiff = realCurrentTime - this.realLastUpdateTime;
        const imaginaryTimeDiff = realTimeDiff * this.updateSpeed;
        this.imaginaryLastUpdateTime += imaginaryTimeDiff;
        this.realLastUpdateTime = realCurrentTime;
        // return this.imaginaryLastUpdateTime;
        return new Date(this.imaginaryLastUpdateTime);
    }
}

export { CustomTime };