import React, { useEffect, useState } from "react";
import styles from "./ivs.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Tooltip from "@mui/material/Tooltip";
import { firestore } from "../../../firesbase.js";
import { collection, getDocs } from "firebase/firestore";

function IVSScenarioModal({ isOpen, onClose }) {
  const [droppedItems, setDroppedItems] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [allScenarios, setAllScenarios] = useState({});

  useEffect(() => {
    getScenarios();
  }, []);

  const getScenarios = async () => {
    const querySnapshot = await getDocs(collection(firestore, "scenarios"));
    const data = {};
    querySnapshot.forEach((doc) => {
      data[doc.id] = doc.data().name;
    });
    setAllScenarios(data);
    setButtonList(Object.keys(data));
  };

  if (!isOpen) return null;

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", item);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (e, area) => {
    e.preventDefault();
    const item = e.dataTransfer.getData("text");

    if (area === "droppable" && !droppedItems.includes(item)) {
      setDroppedItems((prevItems) => [...prevItems, item]);
      setButtonList((prevList) => prevList.filter((button) => button !== item));
    } else if (area === "buttons" && !buttonList.includes(item)) {
      setButtonList((prevList) => [...prevList, item]);
      setDroppedItems((prevItems) =>
        prevItems.filter((droppedItem) => droppedItem !== item),
      );
    }
    e.dataTransfer.clearData();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const getIcon = (button) => {
    // if (button === "Scenario 4") {
    //   return <BlockOutlinedIcon />;
    // }
    //
    // if (button === "Scenario 6") {
    //   return <WarningAmberOutlinedIcon />;
    // } else {
    //   return <AddOutlinedIcon />;
    // }
    return <AddOutlinedIcon />;
  };

  return (
    <div className={styles.modalOverlay} onClick={() => onClose(droppedItems)}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Add scenarios
          <CloseOutlinedIcon
            onClick={() => onClose(droppedItems)}
            className={styles.closeIcon}
          />
        </settings-row>

        <row>
          <column
            onDrop={(e) => handleDrop(e, "buttons")}
            onDragOver={handleDragOver}
          >
            {buttonList.map((id, index) => (
              <row key={index}>
                <button
                  className="button audiencebutton"
                  draggable
                  onDragStart={(e) => handleDragStart(e, id)}
                  type="button"
                >
                  {allScenarios[id]} {getIcon(id)}
                </button>
              </row>
            ))}
          </column>
          <column
            className={styles.droppableArea}
            onDrop={(e) => handleDrop(e, "droppable")}
            onDragOver={handleDragOver}
          >
            {droppedItems.length === 0 && <p>Drag and drop scenarios</p>}
            {droppedItems.map((id, index) => (
              <div
                key={index}
                className={styles.droppedItem}
                draggable
                onDragStart={(e) => handleDragStart(e, id)}
              >
                {allScenarios[id]}
              </div>
            ))}
          </column>
        </row>
      </div>
    </div>
  );
}

export default IVSScenarioModal;
