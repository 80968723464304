import React, { useEffect, useRef, useState, useCallback } from 'react';
import styles from './drop-pin.module.css';
import Tooltip from '@mui/material/Tooltip';
import { firestore } from '../../../firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc } from 'firebase/firestore';
import { BarChart } from '@mui/x-charts/BarChart';
import { GoogleMap, useJsApiLoader, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';



import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import LandslideOutlinedIcon from '@mui/icons-material/LandslideOutlined';
import FloodOutlinedIcon from '@mui/icons-material/FloodOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';

const LIBRARIES = ['places'];

function TruckModal({ truckMarker, instructionText, bearing, truckMarkerPosition, setActiveMarker, position, exitTimes }) {
    const [truckMarker2, setTruckMarker2] = useState(truckMarker);
    console.log(truckMarker);

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const ref = useRef(null);
    const [relativePosition, setRelativePosition] = useState({ x: 0, y: 0 });
    const [apiLoaded, setApiLoaded] = useState(false);
    const [delayedTruckMarkerPosition, setDelayedTruckMarkerPosition] = useState(null);
    const truckMarkerPositionSubject = useRef(new Subject());
    const [streetViewPosition, setStreetViewPosition] = useState(null);
    useEffect(() => {
        const subscription = truckMarkerPositionSubject.current
            .pipe(
                throttleTime(1000),
                distinctUntilChanged((prev, curr) => prev.lat === curr.lat && prev.lng === curr.lng)
            )
            .subscribe((position) => {
                setDelayedTruckMarkerPosition(position);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        truckMarkerPositionSubject.current.next(truckMarkerPosition);
    }, [truckMarkerPosition]);

    useEffect(() => {
        if (delayedTruckMarkerPosition) {
            setStreetViewPosition({
                lat: delayedTruckMarkerPosition.lat,
                lng: delayedTruckMarkerPosition.lng,
            });
        }
    }, [delayedTruckMarkerPosition]);


    const [delayedTruckMarkerBearing, setDelayedTruckMarkerBearing] = useState(null);
    const truckMarkerBearingSubject = useRef(new Subject());
    const [streetViewBearing, setStreetViewBearing] = useState(null);


    useEffect(() => {
        const subscription = truckMarkerBearingSubject.current
            .pipe(
                throttleTime(1000),
                distinctUntilChanged((prev, curr) => prev === curr)
            )
            .subscribe((bearing) => {
                setDelayedTruckMarkerBearing(bearing);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        truckMarkerBearingSubject.current.next(bearing);
    }, [bearing]);

    useEffect(() => {
        if (delayedTruckMarkerBearing) {
            setStreetViewBearing(bearing);
        }
    }, [delayedTruckMarkerBearing]);

    const [isOpen, setIsOpen] = useState(false); // State to track if the dropdown is open

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB6u7S8lT2HhvYAV2ysoD2ZHI-_IlwTHMs"
    })

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

    useEffect(() => {
        if (position) {

            let offsetX = width - 238;
            let offsetY = -height / 1.08;


            if (document.body.classList.contains('active')) {
                offsetX = width - 463;
                let offsetY = -height / 1.08;

            }

            const relX = position.x + offsetX;
            const relY = position.y + offsetY - 25 - 170;

            setRelativePosition({ x: relX, y: relY });
        }
    }, [position, height, width]);

    if (!position) return null;

    const style = {
        position: 'absolute',
        left: relativePosition.x + 'px',
        top: relativePosition.y + 'px',
        zIndex: 999999999,
    };

    const getColorForValue = (value) => {
        if (value >= 0 && value < 16) {
            return '#d2e6bd';
        } else if (value >= 16 && value < 20) {
            return '#f9e4ae';
        } else {
            return '#f7c7c9';
        }
    };

    return (
        <div>
            <droppin-dialog className={styles.dropPinDialog} style={style} ref={ref}>
                <div className={styles.dropPinContainer}>
                    <column>
                        <row>
                            <column>
                                <label htmlFor="pinName">{instructionText}</label>
                            </column>
                        </row>

                        {isLoaded && streetViewPosition && streetViewBearing && (
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '200px' }}
                                center={streetViewPosition}
                                zoom={14}
                            >
                                <StreetViewPanorama
                                    position={streetViewPosition}
                                    visible={true}
                                    options={{
                                        addressControl: false,
                                        enableCloseButton: false,
                                        showRoadLabels: false,
                                        panControl: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                        linksControl: false,
                                        motionTracking: false,
                                        motionTrackingControl: false,
                                        pov: {
                                            heading: streetViewBearing,
                                            pitch: 0,
                                        },
                                    }}
                                />
                            </GoogleMap>
                        )
                        }
                    </column>
                </div>
            </droppin-dialog>
        </div>
    );
}

export default TruckModal;

