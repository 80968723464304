import React from 'react';
import { signOut, getAuth } from 'firebase/auth';
import styles from './top-navbar.module.css';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

function TopNavBar() {
    const auth = getAuth();

    const handleSignOut = () => {
        signOut(auth).then(() => {
            console.log('Sign out successful');
        }).catch((error) => {
            console.error('Sign out error:', error);
        });
    };

    const handleToggleBodyActive = () => {
        document.body.classList.toggle("active");
    };

    return (
        <div className={styles.topNavbar}>
            <div className={styles.navRow}>
                <column>
                    <row>
                        <div className={styles.menuIcon} onClick={handleToggleBodyActive}>
                            <MenuOutlinedIcon />
                        </div>
                    </row>
                </column>
                <column>
                    <button-row>
                        <div className={styles.iconBadgeContainer}>
                            <NotificationsNoneOutlinedIcon className={styles.notificationsIcon} />
                            <div className={styles.iconBadge}>7</div>
                        </div>
                        <button class="button bluebutton" onClick={handleSignOut} >Sign out</button>
                    </button-row>
                </column>
            </div>
        </div>
    );
}

export default TopNavBar;
