import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import styles from "./contenttab.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function InAppMessagesContentTab() {
  const onDragStart = (e) => {
    e.dataTransfer.setData("text", e.target.dataset.contentType);
  };
  return (
    <tabcontent>
      <row className="email-icons-container">
        <row>
          <column>
            <email-container
              data-content-type="title"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.001.jpeg" width="100px" />
              TITLE
            </email-container>
          </column>

          <column>
            <email-container
              data-content-type="paragraph"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.002.jpeg" width="100px" />
              PARAGRAPH
            </email-container>
          </column>

          <column>
            <email-container
              data-content-type="list"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.003.jpeg" width="100px" />
              LIST
            </email-container>
          </column>
        </row>

        <row>
          <column>
            <email-container
              data-content-type="image"
              draggable
              onDragStart={onDragStart}
            >
              <column>
                <img src="email-icons.004.jpeg" width="100px" />
                IMAGE
              </column>
            </email-container>
          </column>

          <column>
            <email-container
              data-content-type="button"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.005.jpeg" width="100px" />
              BUTTON
            </email-container>
          </column>

          <column>
            <email-container
              data-content-type="divider"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.006.jpeg" width="100px" />
              DIVIDER
            </email-container>
          </column>
        </row>

        <row>
          <column>
            <email-container
              data-content-type="spacer"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.007.jpeg" width="100px" />
              SPACER
            </email-container>
          </column>
          <column>
            <email-container
              data-content-type="html"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.008.jpeg" width="100px" />
              HTML
            </email-container>
          </column>
          <column>
            <email-container
              data-content-type="video"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.009.jpeg" width="100px" />
              VIDEO
            </email-container>
          </column>
        </row>

        <row>
          <column>
            <email-container
              data-content-type="icons"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.010.jpeg" width="100px" />
              ICONS
            </email-container>
          </column>
          <column>
            <email-container
              data-content-type="logo"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.011.jpeg" width="100px" />
              LOGO
            </email-container>
          </column>
          <column>
            <email-container
              data-content-type="text"
              draggable
              onDragStart={onDragStart}
            >
              <img src="email-icons.012.jpeg" width="100px" />
              TEXT
            </email-container>
          </column>
        </row>
      </row>
    </tabcontent>
  );
}

export default InAppMessagesContentTab;

