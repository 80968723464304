import React, { useEffect, useState } from "react";
import styles from "./ivs2.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { firestore } from "../../../firesbase.js";
import { collection, getDocs } from "firebase/firestore";

function IVSScenarioModal2({ isOpen, onClose, selectedScenarios, onUpdateSelectedScenarios }) {
  const [buttonList, setButtonList] = useState([]);
  const [allScenarios, setAllScenarios] = useState({});

  useEffect(() => {
    getScenarios();
  }, []);

  useEffect(() => {
    if (isOpen) {
      updateButtonList();
    }
  }, [selectedScenarios, isOpen, allScenarios]);

  const getScenarios = async () => {
    const querySnapshot = await getDocs(collection(firestore, "scenarios"));
    const data = {};
    querySnapshot.forEach((doc) => {
      data[doc.id] = doc.data().name;
    });
    setAllScenarios(data);
  };

  const updateButtonList = () => {
    setButtonList(Object.keys(allScenarios).filter(id => !selectedScenarios.includes(id)));
  };

  if (!isOpen) return null;

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", item);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (e, area) => {
    e.preventDefault();
    const item = e.dataTransfer.getData("text/plain");
    if (area === "droppable" && !selectedScenarios.includes(item)) {
      onUpdateSelectedScenarios([...selectedScenarios, item]);
    } else if (area === "buttons" && selectedScenarios.includes(item)) {
      onUpdateSelectedScenarios(selectedScenarios.filter(scenario => scenario !== item));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    onClose();
  };

  const handleRemove = (id) => {
    onUpdateSelectedScenarios(selectedScenarios.filter(scenario => scenario !== id));
  };

  return (
    <div className={styles.modalOverlay} onClick={handleClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Add scenarios
          <CloseOutlinedIcon onClick={handleClose} className={styles.closeIcon} />
        </settings-row>

        <row>
          <column
            onDrop={(e) => handleDrop(e, "buttons")}
            onDragOver={handleDragOver}
            className={styles.draggableArea}
          >
            {buttonList.length === 0 ? <p>No available scenarios</p> : buttonList.map((id, index) => (
              <button
                key={index}
                className="button audiencebutton"
                draggable
                onDragStart={(e) => handleDragStart(e, id)}
                type="button"
              >
                {allScenarios[id]} <AddOutlinedIcon />
              </button>
            ))}
          </column>

          <column
            onDrop={(e) => handleDrop(e, "droppable")}
            onDragOver={handleDragOver}
            className={styles.droppableArea}
          >
            {selectedScenarios.length === 0 && <p>Drag and drop scenarios</p>}
            {selectedScenarios.map((id, index) => (
              <div key={index} className={styles.droppedItem} draggable onDragStart={(e) => handleDragStart(e, id)}>
                {allScenarios[id]}
                <CloseOutlinedIcon
                  onClick={() => handleRemove(id)}
                  className={styles.closeScenarioIcon}
                />
              </div>
            ))}
          </column>
        </row>
      </div>
    </div>
  );
}

export default IVSScenarioModal2;
