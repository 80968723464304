// import { CustomTime } from './custom-time.js';
import L from 'leaflet'; // Assuming Leaflet is used for map operations
import 'leaflet-geometryutil';
import { SimulationUIManager } from './simulation-ui-manager.js'; // Ensure this is correctly imported based on your project structure

class Truck {
  constructor(spawnPoint, portalPoint, route, path, customTime, simulationUiManager) {
    this.id = `vehicle_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`; // Enhanced unique vehicle ID
    this.spawnPoint = spawnPoint;
    this.currentLocation = this.spawnPoint;
    this.portalPoint = portalPoint;
    // this.route = route;
    // this.path = path;
    this.pathIndex = 0;
    this.distanceTraveled = 0;
    this.totalDistanceTraveled = 0;
    this.customTime = customTime;
    this.lastUpdateTime = customTime.getCurrentTime();
    this.simulationUiManager = simulationUiManager;
    this.legIndex = 0;
    // this.estimatedTimeOfArrival = new Date(this.lastUpdateTime.getTime() + route.time);

    // simulationUiManager.addMarker({
    //   id: this.id,
    //   position: {lat: this.currentLocation.latitude, lng: this.currentLocation.longitude},
    // });

    // this.marker = L.marker(path[0]).addTo(simulationUiManager.map).bindPopup('');
    this.intervalId = null;
    this.markerAdded = false;
    this.timeDelayForAppPause = 0;
  }

  updateRoute(spawnPoint, portalPoint, route) {
    this.spawnPoint = spawnPoint;
    this.portalPoint = portalPoint;
    this.route = route;
    // this.route = route.legs[0];
    // this.path = route.legs[0].route.path;
    this.pathIndex = 0;
    this.distanceTraveled = 0
    this.totalDistanceTraveled = 0;
    this.legIndex = 0;
    if (!this.markerAdded && !this.isDummy) {
      let newPoint = L.latLng(this.route.legs[this.legIndex].route.path[0]);
      // this.currentLocation = { latitude: newPoint.lat, longitude: newPoint.lng };
      this.simulationUiManager.addMarker({
        id: this.id,
        position: newPoint,
        instructionText: '',
        bearing: 10,
        isDummy: this.isDummy
      });
      this.markerAdded = true;
    }
  }

  resetLocation() {
    this.distanceTraveled = 0
    this.totalDistanceTraveled = 0;
    this.legIndex = 0;
    this.pathIndex = 0;
    this.lastUpdateTime = this.customTime.getCurrentTime();
  }



  move() {

    if (!this.route.legs[this.legIndex]) {
      return 'finished';
    }

    // check if it is time to leave the services and start the second leg
    if (this.route.legs[this.legIndex].departureTime) {
      //log the departure time and the current time
      // console.log("departure time: " + this.route.legs[this.legIndex].departureTime + " current time: " + this.customTime.getCurrentTime());
      if (this.route.legs[this.legIndex].departureTime > this.customTime.getCurrentTime()) { // && this.legIndex > 0
        return 'parked';
      }
      else {
        if (!this.route.legs[this.legIndex].started) {
          this.lastUpdateTime = this.customTime.getCurrentTime();
          this.route.legs[this.legIndex].started = true;
          this.id = this.id + "_new"
          this.simulationUiManager.addMarker({
            id: this.id,
            position: this.route.legs[this.legIndex].route.path[0],
          });
        }
      }
    }

    let path = this.route.legs[this.legIndex].route.path;
    let details = this.route.legs[this.legIndex].route.details;
    let instructions = this.route.legs[this.legIndex].route.instructions;

    const currentTime = this.customTime.getCurrentTime();
    const elapsedTime = ((currentTime - this.lastUpdateTime) / 1000);
    this.lastUpdateTime = currentTime;

    if (this.pathIndex >= path.length - 1) {
      clearInterval(this.intervalId);
      // this.marker.remove();
      this.simulationUiManager.removeMarker(this.id);
      this.simulationUiManager.removeVehicleFromPanel(this);
      this.legIndex++;
      this.distanceTraveled = 0;
      this.totalDistanceTraveled = 0;
      this.pathIndex = 0;
      return 'endofleg';
    }

    const speedInterval = details.average_speed.find(interval => interval[0] <= this.pathIndex && this.pathIndex < interval[1]);
    const speed = speedInterval ? speedInterval[2] / 3.6 : 0;
    this.speed = speed;

    const currentPoint = path[this.pathIndex];
    const nextPoint = path[this.pathIndex + 1];
    let segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));

    this.distanceTraveled += speed * elapsedTime;
    this.totalDistanceTraveled = this.totalDistanceTraveled + speed * elapsedTime;

    while (this.distanceTraveled >= segmentDistance) {
      this.pathIndex++;
      this.distanceTraveled -= segmentDistance;

      if (this.pathIndex >= path.length - 1) {
        clearInterval(this.intervalId);
        // this.marker.remove();
        this.simulationUiManager.removeMarker(this.id);
        this.simulationUiManager.removeVehicleFromPanel(this);
        this.legIndex++;
        this.distanceTraveled = 0;
        this.pathIndex = 0;
        return 'endofleg';
      }

      const currentPoint = path[this.pathIndex];
      const nextPoint = path[this.pathIndex + 1];
      segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));
    }

    if (this.pathIndex < path.length - 1) {
      const currentPoint = path[this.pathIndex];
      const nextPoint = path[this.pathIndex + 1];
      const bearing = L.GeometryUtil.bearing(L.latLng(currentPoint), L.latLng(nextPoint));
      const newPoint = L.GeometryUtil.destination(L.latLng(currentPoint), bearing, this.distanceTraveled);
      // this.marker.setLatLng(newPoint);
      this.currentLocation = { latitude: newPoint.lat, longitude: newPoint.lng };

      // find the instruction corresponding to this distance travelled:
      let instructionText = '';
      for (var i = 0; i < instructions.length; i++) {
        if (instructions[i].interval[0] <= this.pathIndex && this.pathIndex < instructions[i].interval[1]) {
          instructionText = instructions[i].text;
          break;
        }
      }

      this.simulationUiManager.updateMarker(this.id, newPoint, instructionText, bearing, this.isDummy);

      return 'moving';
    }
  }


  move_testing() {

    const currentTime = this.customTime.getCurrentTime();
    if (this.paused) {
      this.paused = false;
      this.lastUpdateTime = currentTime;
    }

    if (!this.route.legs[this.legIndex]) {
      return 'finished';
    }

    let path = this.route.legs[this.legIndex].route.path;
    let details = this.route.legs[this.legIndex].route.details;
    let instructions = this.route.legs[this.legIndex].route.instructions;

    const elapsedTime = ((currentTime - this.lastUpdateTime) / 1000);
    this.lastUpdateTime = currentTime;

    if (this.pathIndex >= path.length - 1) {
      // clearInterval(this.intervalId);
      // this.marker.remove();
      // this.simulationUiManager.removeMarker(this.id);
      // this.simulationUiManager.removeVehicleFromPanel(this);

      // this.legIndex++;
      // this.distanceTraveled = 0;
      // this.totalDistanceTraveled = 0;
      // this.pathIndex = 0;
      // return 'endofleg';
      // path[path.length - 1]
      const currentPoint = path[path.length - 1];
      let newPoint = L.latLng(currentPoint);
      this.currentLocation = { latitude: newPoint.lat, longitude: newPoint.lng };
      this.simulationUiManager.updateMarker(this.id, newPoint, '', 0.0, this.isDummy);
      return '';
    }

    const speedInterval = details.average_speed.find(interval => interval[0] <= this.pathIndex && this.pathIndex < interval[1]);
    const speed = speedInterval ? speedInterval[2] / 3.6 : 0;
    this.speed = speed;

    const currentPoint = path[this.pathIndex];
    const nextPoint = path[this.pathIndex + 1];
    let segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));

    this.distanceTraveled += speed * elapsedTime;
    this.totalDistanceTraveled = this.totalDistanceTraveled + speed * elapsedTime;

    while (this.distanceTraveled >= segmentDistance) {
      this.pathIndex++;
      this.distanceTraveled -= segmentDistance;

      if (this.pathIndex >= path.length - 1) {
        // clearInterval(this.intervalId);
        // this.marker.remove();
        // this.simulationUiManager.removeMarker(this.id);
        // this.simulationUiManager.removeVehicleFromPanel(this);

        // this.legIndex++;
        // this.distanceTraveled = 0;
        // this.pathIndex = 0;
        // return 'endofleg';
        const currentPoint = path[path.length - 1];
        let newPoint = L.latLng(currentPoint);
        this.currentLocation = { latitude: newPoint.lat, longitude: newPoint.lng };
        this.simulationUiManager.updateMarker(this.id, newPoint, '', 0.0, this.isDummy);
        return '';
      }

      const currentPoint = path[this.pathIndex];
      const nextPoint = path[this.pathIndex + 1];
      segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));
    }

    if (this.pathIndex < path.length - 1) {
      const currentPoint = path[this.pathIndex];
      const nextPoint = path[this.pathIndex + 1];
      const bearing = L.GeometryUtil.bearing(L.latLng(currentPoint), L.latLng(nextPoint));
      const newPoint = L.GeometryUtil.destination(L.latLng(currentPoint), bearing, this.distanceTraveled);
      // this.marker.setLatLng(newPoint);
      this.currentLocation = { latitude: newPoint.lat, longitude: newPoint.lng };

      // find the instruction corresponding to this distance travelled:
      let instructionText = '';
      for (var i = 0; i < instructions.length; i++) {
        if (instructions[i].interval[0] <= this.pathIndex && this.pathIndex < instructions[i].interval[1]) {
          instructionText = instructions[i].text;
          break;
        }
      }

      this.simulationUiManager.updateMarker(this.id, newPoint, instructionText, bearing, this.isDummy);

      return 'moving';
    }
  }

  // move() {

  //   let path = this.route.legs[legIndex].route.path;
  //   let details = this.route.legs[legIndex].route.details;

  //   const currentTime = this.customTime.getCurrentTime();
  //   const elapsedTime = ((currentTime - this.lastUpdateTime) / 1000);
  //   this.lastUpdateTime = currentTime;

  //   if (this.pathIndex >= this.path.length - 1) {
  //     clearInterval(this.intervalId);
  //     // this.marker.remove();
  //     this.simulationUiManager.removeMarker(this.id);
  //     this.simulationUiManager.removeVehicleFromPanel(this);
  //     return;
  //   }

  //   const speedInterval = this.route.route.details.average_speed.find(interval => interval[0] <= this.pathIndex && this.pathIndex < interval[1]);
  //   const speed = speedInterval ? speedInterval[2] / 3.6 : 0;
  //   this.speed = speed;

  //   const currentPoint = this.path[this.pathIndex];
  //   const nextPoint = this.path[this.pathIndex + 1];
  //   let segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));

  //   this.distanceTraveled += speed * elapsedTime;

  //   while (this.distanceTraveled >= segmentDistance) {
  //     this.pathIndex++;
  //     this.distanceTraveled -= segmentDistance;

  //     if (this.pathIndex >= this.path.length - 1) {
  //       clearInterval(this.intervalId);
  //       // this.marker.remove();
  //       this.simulationUiManager.removeMarker(this.id);
  //       this.simulationUiManager.removeVehicleFromPanel(this);
  //       return;
  //     }

  //     const currentPoint = this.path[this.pathIndex];
  //     const nextPoint = this.path[this.pathIndex + 1];
  //     segmentDistance = L.latLng(currentPoint).distanceTo(L.latLng(nextPoint));
  //   }

  //   if (this.pathIndex < this.path.length - 1) {
  //     const currentPoint = this.path[this.pathIndex];
  //     const nextPoint = this.path[this.pathIndex + 1];
  //     const bearing = L.GeometryUtil.bearing(L.latLng(currentPoint), L.latLng(nextPoint));
  //     const newPoint = L.GeometryUtil.destination(L.latLng(currentPoint), bearing, this.distanceTraveled);
  //     // this.marker.setLatLng(newPoint);
  //     this.currentLocation = newPoint;
  //     this.simulationUiManager.updateMarker(this.id, newPoint);
  //   }
  // }

  scheduleMovement(updateSpeed) {
    this.intervalId = setInterval(() => {
      this.move();
    }, updateSpeed);
  }
}

export { Truck };