import React, { useEffect, useState } from 'react';
import { collection, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import { ScheduleService } from '../../../api/schedule-service.js';
import { SiteConfig } from '../../../site-config.js';
import styles from './users.module.css';

const UsersPanel = ({ resetUserLocation }) => {
    const [users, setUsers] = useState([]);
    const scheduleService = new ScheduleService(SiteConfig.scheduleServerBaseUrl);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(firestore, 'users'), (snapshot) => {
            const dbUsers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(dbUsers);
        });

        return () => unsubscribe();
    }, []);

    const toggleSimulated = async (userId, currentValue) => {
        const userRef = doc(firestore, 'users', userId);
        await updateDoc(userRef, {
            isSimulated: !currentValue
        });
    };

    const handleResetSchedule = async () => {
        try {
            await scheduleService.resetSchedule();
            // alert('Schedule reset successfully');
        } catch (error) {
            console.error('Error resetting schedule:', error);
            alert('Failed to reset schedule');
        }
    };

    return (
        <div style={{ padding: '1rem' }}>
            <h1>Users</h1>

            <ul style={{ listStyle: 'none', padding: 0, maxHeight: '200px', overflowY: 'auto' }}>
                {users.map((user) => (
                    <li key={user.id} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0.5rem',
                        backgroundColor: '#f6f7f899',
                        marginBottom: '0.5rem',
                        borderRadius: '0.25rem'
                    }}>
                        <span>{user.email}</span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label className={styles.checkboxContainer} style={{ marginRight: '1rem' }}>
                                <span>Simulated</span>
                                <input
                                    type="checkbox"
                                    id={`simulated-${user.id}`}
                                    checked={user.isSimulated}
                                    onChange={() => toggleSimulated(user.id, user.isSimulated)}
                                />
                                <span className={styles.checkboxCheckmark}></span>
                            </label>
                            <div className={styles.resetButton}
                                onClick={() => resetUserLocation(user.id)}
                            // style={{
                            //     padding: '0.25rem 0.5rem',
                            //     backgroundColor: '#024761',
                            //     color: 'white',
                            //     border: 'none',
                            //     borderRadius: '0.25rem',
                            //     cursor: 'pointer',
                            //     fontSize: '16px',
                            //     fontFamily: 'Roboto'
                            // }}
                            >
                                Reset Location
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {/* 
                <bluebutton
                    onClick={handleResetSchedule}
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded font-bold hover:bg-red-600 transition-colors"
                >
                    Reset Scheduler
                </bluebutton> */}

            <button

                onClick={handleResetSchedule}

                class="button bluebutton" id="closePopup"> Reset Scheduler</button>
        </div>
    );
};

export { UsersPanel };