import React, { useEffect, useState } from "react";
import styles from "./createaudiencepopup.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AudienceList from "./audiencelist";
import Constraint from "./constraint";
import { firestore } from "../../../../firesbase.js";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { dbCreateNewAudience, dbUpdateAudience } from "../../../utils/db";

function CreateAudiencePopUp({ isOpen, onClose, existingAudience = null }) {
  const [showAudienceList, setShowAudienceList] = useState(true);
  const [values, setValues] = useState({});
  const [constraints, setConstraints] = useState(
    existingAudience?.constraints ?? {
      types: [],
      isTrue: [],
      values: [],
      combinators: [],
    },
  );

  const getValues = async () => {
    const names = [
      ["destinations", "Destinations"],
      ["fuel_types", "FuelType"],
      ["loads", "Load"],
      ["vehicle_sizes", "VehicleSize"],
    ];
    const newValues = {};
    for (const [tableName, objName] of names) {
      const querySnapshot = await getDocs(collection(firestore, tableName));
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data().name);
      });
      newValues[objName] = data;
    }
    setValues(newValues);
  };

  useEffect(() => {
    getValues();
  }, []);

  const handleComponentChange = (component) => {
    setConstraints((prev) => ({
      types: [...prev.types, component],
      isTrue: [...prev.isTrue, "is"],
      values: [...prev.values, null],
      combinators: [...prev.combinators],
    }));
    setShowAudienceList(false);
  };

  const handleRemoveComponent = (index) => {
    setConstraints((prev) => {
      const updated = { ...prev };
      for (const key of Object.keys(updated)) {
        updated[key] = updated[key].filter((_, i) => i !== index);
      }
      if (updated.types.length === 0) {
        setShowAudienceList(true);
      }
      return updated;
    });
  };

  const saveAudience = async () => {
    if (existingAudience === null) {
      const name = document.getElementById("audience-name").value;
      await dbCreateNewAudience({ ...constraints, name });
    } else {
      await dbUpdateAudience(existingAudience.id, { ...constraints });
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          Create audience
          <CloseOutlinedIcon onClick={onClose} className={styles.closeIcon} />
        </settings-row>

        <input
          id="audience-name"
          type="text"
          placeholder="Enter name here..."
        />

        {Object.keys(values).length !== 0 &&
          constraints.types.map((component, index) => {
            const initialValues = {
              initialFirstValue: constraints.isTrue[index],
              initialSecondValue: constraints.values[index],
              initialCombinator: constraints.combinators[index],
            };
            return (
              <Constraint
                key={index}
                onAnd={() => {
                  setShowAudienceList(true);
                  setConstraints((prev) => {
                    const newConstraints = { ...prev };
                    newConstraints.combinators[index] = "and";
                    return newConstraints;
                  });
                }}
                onOr={() => {
                  setShowAudienceList(true);
                  setConstraints((prev) => {
                    const newConstraints = { ...prev };
                    newConstraints.combinators[index] = "or";
                    return newConstraints;
                  });
                }}
                onRemove={() => handleRemoveComponent(index)}
                values={values[component]}
                title={component}
                onDropdownChange={(newIsTrue, newValue) => {
                  setConstraints((prev) => {
                    const newConstraints = { ...prev };
                    newConstraints.isTrue[index] = newIsTrue;
                    newConstraints.values[index] = newValue;
                    return newConstraints;
                  });
                }}
                {...initialValues}
              />
            );
          })}

        {/* Display the Audience List */}
        {showAudienceList && (
          <AudienceList onComponentChange={handleComponentChange} />
        )}

        <div
          style={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="button blueButton"
            type="button"
            onClick={() => {
              saveAudience();
              onClose();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateAudiencePopUp;
