import { faHome, faDesktop, faRoad, faTruck, faEnvelope, faChartSimple, faUserShield, faCog, faCaretDown, faEye, faTrafficLight } from '@fortawesome/free-solid-svg-icons';

export const SitePresets = {
    PORTS: {
        // routeServerBaseUrl: 'http://routing-server-dev-env.eba-c5hz57tc.us-east-2.elasticbeanstalk.com:8989',
        // routeServerBaseUrl: 'http://localhost:8989', // add in correct routing server for branch here somehow (http://routing-server-dev-env.eba-c5hz57tc.us-east-2.elasticbeanstalk.com:8989/)
        routeServerBaseUrl: process.env.REACT_APP_ROUTE_SERVER_BASE_URL || 'http://routing-server-dev-env.eba-c5hz57tc.us-east-2.elasticbeanstalk.com:8989', // 'http://localhost:8989',
        scheduleServerBaseUrl: process.env.REACT_APP_SCHEDULE_SERVER_BASE_URL || 'http://localhost:8080',


        // scheduleServerBaseUrl: 'http://api-tas-portal-dev-env.eba-mkxy2w5a.us-east-2.elasticbeanstalk.com',
        theme: 'default',
        features: {
            enableDashboard: true,
            enableReports: true,
            enableLiveUpdates: true,
            tas: {
                nameText: 'TAS'
            }
        },
        menuConfig: [
            {
                id: 'dashboard',
                text: 'Dashboard',
                icon: faDesktop,
                url: '/dashboard',
                role: ['admin', 'user']
            },
            {
                id: 'testing-tas',
                text: 'Testing TAS',
                icon: faTrafficLight,
                url: '/testing-tas',
                role: ['admin']
            },
            {
                id: 'tas',
                text: 'TAS',
                icon: faRoad,
                url: '/tas',
                role: ['admin', 'user']
            },
            {
                id: 'liveview',
                text: 'Live view',
                icon: faEye,
                url: '/live',
                role: ['admin', 'user']
            },
            {
                id: 'truckstops',
                text: 'Truck stops',
                icon: faTruck,
                url: '/truckstops',
                role: ['admin', 'user']
            },
            // {
            //     id: 'messages',
            //     text: 'Messages',
            //     icon: faEnvelope,
            //     url: '/ivs',
            //     role: ['admin', 'user']
            //     // subMenu: [
            //     //     {
            //     //         id: 'pushmessages',
            //     //         text: 'Push',
            //     //         url: '/pushmessages',
            //     //     },
            //     //     {
            //     //         id: 'inappmessages',
            //     //         text: 'In app',
            //     //         url: '/inappmessages',
            //     //     },
            //     //     {
            //     //         id: 'smsmessages',
            //     //         text: 'SMS',
            //     //         url: '/smsmessages',
            //     //     },
            //     //     {
            //     //         id: 'ivs',
            //     //         text: 'IVS',
            //     //         url: '/ivs',
            //     //     },
            //     //     {
            //     //         id: 'schedule',
            //     //         text: 'Schedule',
            //     //         url: '/schedule',
            //     //     },
            //     // ],
            // },
            {
                id: 'messages',
                text: 'Messages',
                icon: faEnvelope,
                // url: '/ivs',
                role: ['admin', 'user'],
                subMenu: [
                    {
                        id: 'createMessage',
                        text: 'Create IVS',
                        url: '/ivs2',
                    },
                    {
                        id: 'createMessage',
                        text: 'Create InApp',
                        url: '/inappmessages',
                    },
                    {
                        id: 'scheduleMessages',
                        text: 'Schedule',
                        url: '/schedule',
                    }
                ],
            },
            {
                id: 'reports',
                text: 'Reports',
                icon: faChartSimple,
                url: '/reports',
                role: ['admin', 'user']
            },
            {
                id: 'admin',
                text: 'Admin',
                icon: faUserShield,
                url: '/admin',
                role: ['admin', 'user']
            },
            // {
            //     id: 'settings',
            //     text: 'Settings',
            //     icon: faCog,
            //     url: '/settings',
            // },
        ],
        firebaseConfig: {
            apiKey: "AIzaSyADE6zgF2V5cZIrRFjAwMHJUNCGVmkkUaM",
            authDomain: "dover-tas-portal-dev.firebaseapp.com",
            projectId: "dover-tas-portal-dev",
            storageBucket: "dover-tas-portal-dev.appspot.com",
            messagingSenderId: "1068363330840",
            appId: "1:1068363330840:web:dc2a6261e51c4142a71a6d",
            measurementId: "G-VW09QS63PX"
        }

    },
    NEC: {
        routeServerBaseUrl: 'http://routingserver-env.eba-ij6x6fhx.us-east-2.elasticbeanstalk.com:8989',
        theme: 'blue',
        features: {
            enableDashboard: true,
            enableReports: false,
            enableLiveUpdates: true,
            tas: {
                nameText: 'Parking'
            }
        },
        menuConfig: [
            // {
            //   id: 'dashboard',
            //   text: 'Dashboard',
            //   icon: faDesktop,
            //   url: '/dashboard',
            // },
            {
                id: 'tas',
                text: 'Parking',
                icon: faRoad,
                url: '/tas',
            }
        ],
        firebaseConfig: {
            apiKey: "AIzaSyBiz-xkLcDp-0EbR1t1nnXTveaE2dMxbm8",
            authDomain: "events-portal-fcafe.firebaseapp.com",
            projectId: "events-portal-fcafe",
            storageBucket: "events-portal-fcafe.appspot.com",
            messagingSenderId: "599048522601",
            appId: "1:599048522601:web:b32fc86bf7444d29d30bf8",
            measurementId: "G-0NTK3ZQZEE"
        }

    }
};

export class SiteConfig {
    static currentPreset = SitePresets.PORTS;

    static get routeServerBaseUrl() {
        return this.currentPreset.routeServerBaseUrl;
    }

    static get scheduleServerBaseUrl() {
        return this.currentPreset.scheduleServerBaseUrl;
    }

    static get theme() {
        return this.currentPreset.theme;
    }

    static get features() {
        return this.currentPreset.features;
    }

    static get firebaseConfig() {
        return this.currentPreset.firebaseConfig;
    }

}
