import React, { useEffect, useState } from "react";
import styles from "./createaudiencepopup.module.css";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function Constraint({
  onAnd,
  onOr,
  onRemove,
  values,
  title,
  onDropdownChange,
  initialFirstValue = "is",
  initialSecondValue = null,
  initialCombinator = null,
}) {
  const [firstDropdownValue, setFirstDropdownValue] =
    useState(initialFirstValue);
  const [secondDropdownValue, setSecondDropdownValue] =
    useState(initialSecondValue);
  const [hideOrButton, setHideOrButton] = useState(false);
  const [hideAndButton, setHideAndButton] = useState(false);

  useEffect(() => {
    if (initialCombinator === "and") {
      handleAndButtonClick();
    } else if (initialCombinator === "or") {
      handleOrButtonClick();
    }
  }, []);

  const handleFirstDropdownClick = (value) => {
    setFirstDropdownValue(value);
    onDropdownChange(value, secondDropdownValue);
  };

  const handleSecondDropdownClick = (value) => {
    setSecondDropdownValue(value);
    onDropdownChange(firstDropdownValue, value);
  };

  const handleAndButtonClick = () => {
    onAnd();
    setHideOrButton(true);
  };

  const handleOrButtonClick = () => {
    onOr();
    setHideOrButton(true);
    setHideAndButton(true);
  };

  return (
    <div>
      <div className={styles.createRow}>
        <div className={styles.createColumn1}>
          <div className={styles.createName}>{title}</div>
        </div>
        <div className={styles.createColumn2}>
          <div className={styles.createRow}>
            <div className="dropdown">
              <button type="button" className="dropbtn">
                {firstDropdownValue} <ExpandMoreOutlinedIcon />
              </button>
              <div className="dropdown-content">
                <a onClick={() => handleFirstDropdownClick("is")}>is</a>
                <a onClick={() => handleFirstDropdownClick("is not")}>is not</a>
              </div>
            </div>
            <div className="dropdown">
              <button type="button" className="dropbtn">
                {secondDropdownValue} <ExpandMoreOutlinedIcon />
              </button>
              <div className="dropdown-content">
                {values.map((value, index) => (
                  <a
                    onClick={() => handleSecondDropdownClick(value)}
                    key={index}
                  >
                    {value}
                  </a>
                ))}
              </div>
            </div>
            <CloseOutlinedIcon onClick={onRemove} />
          </div>
        </div>
      </div>
      {!hideAndButton && (
        <button
          className={styles.andButton}
          onClick={handleAndButtonClick}
          disabled={hideOrButton}
          type="button"
        >
          AND
        </button>
      )}
      {!hideOrButton && (
        <button
          className={styles.orButton}
          onClick={handleOrButtonClick}
          type="button"
        >
          OR
        </button>
      )}
      {hideAndButton && <div class={styles.or}>OR</div>}
    </div>
  );
}

export default Constraint;
