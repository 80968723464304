import React, { useState } from "react";
import styles from "./invite-team-popup.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function InviteTeamPopUp({ children, isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <settings-row>
          <h3>Invite team member</h3>
          <CloseOutlinedIcon onClick={onClose} className={styles.closeIcon} />
        </settings-row>

        <row>
          <column>
            <label for="name">Name</label>
            <input type="email" id="name" name="name"></input>
          </column>
        </row>
        <row>
          <column>
            <label for="email">Email address</label>
            <input type="text" id="email" name="email"></input>
          </column>
        </row>

        <row>
          <row>Role</row>

          <column>
            <label class="radio-container">
              Administrator
              <input type="radio" name="radio"></input>
              <span class="checkmark"></span>
            </label>

            <label class="radio-container">
              Editor
              <input type="radio" name="radio"></input>
              <span class="checkmark"></span>
            </label>

            <label class="radio-container">
              User
              <input type="radio" name="radio"></input>
              <span class="checkmark"></span>
            </label>
          </column>
        </row>

        <row>
          <column>
            <label for="message">Message</label>
            <textarea rows="4"></textarea>
          </column>
        </row>

        <popup-row>
          <button class="button whitebutton" onClick={onClose}>
            Cancel
          </button>
          <button class="button bluebutton" onClick={onClose}>
            Send invitation
          </button>
        </popup-row>
      </div>
    </div>
  );
}

export default InviteTeamPopUp;
