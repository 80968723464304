// import { Truck } from './trucks.js';
// // import { CustomTime } from './custom_time.js';
// // import { getArrivalSlotIndex, portalSlots, updatePortalSlotsChart } from '../scheduler/scheduler.js';

// export class SimulationManager {
//   constructor(updateSpeed) {
//     this.trucks = [];
//     this.updateSpeed = updateSpeed;
//     this.simulationInterval = null;
//   }

//   addTruck(spawnPoint, portalPoint, route, path) {
//     const truck = new Truck(spawnPoint, portalPoint, route, path);
//     truck.scheduleMovement(this.updateSpeed);
//     this.trucks.push(truck);
//   }

//   startSimulation() {
//     this.simulationInterval = setInterval(() => {
//       this.trucks.forEach(truck => truck.move());
//     }, this.updateSpeed);
//   }

//   stopSimulation() {
//     clearInterval(this.simulationInterval);
//     this.trucks.forEach(truck => clearInterval(truck.intervalId));
//   }

//   updateSimulationSpeed(newSpeed) {
//     this.updateSpeed = newSpeed;
//     this.trucks.forEach(truck => {
//       clearInterval(truck.intervalId);
//       truck.scheduleMovement(this.updateSpeed);
//     });
//   }
// }

import { Truck } from './trucks.js';
// import { getFirestore, collection, getDocs, query, where, doc } from 'firebase/firestore';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc, setDoc, getDocs, Timestamp, writeBatch } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import { decodePath } from '../../utils/utils.js';
import { CustomTime } from './custom-time.js';
import { RouteService } from '../../../api/route-service.js';
import { ScheduleService } from '../../../api/schedule-service.js';
// import { Scheduler } from './scheduler.js'
import { GlobalVariables } from '../../utils/global-variables.js'
import { SiteConfig } from '../../../site-config.js';
import { LiveScheduler } from './live-scheduler.js'

export class SimulationManagerTesting {
  constructor(updateSpeed, simulationSpeed, truckSpawnRate, scenarioId, simulationUiManager, customTime, setDoverChartData, enableScheduler, setExitTimes, setParkedData) {
    this.trucks = [];
    this.updateSpeed = updateSpeed;
    this.simulationSpeed = simulationSpeed;
    this.truckSpawnRate = truckSpawnRate;
    this.truckSpawnTime = (1 / this.truckSpawnRate) * 60 * 60 * 1000
    this.simulationUiManager = simulationUiManager;
    this.simulationInterval = null;
    this.spawnInterval = null;
    this.scenarioId = scenarioId;
    this.customTime = customTime;
    this.routeService = new RouteService(SiteConfig.routeServerBaseUrl);
    this.scheduleService = new ScheduleService(SiteConfig.scheduleServerBaseUrl);
    this.setDoverChartData = setDoverChartData;
    this.enableScheduler = enableScheduler;
    this.setExitTimes = setExitTimes;
    this.exitTimes = {};
    this.setParkedData = setParkedData;
    this.parkedData = {};
    this.waitTimeAtService = 15;
  }



  // // Add this method to the class
  // delay(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  resetTruckLocation(userId) {
    // const truck = this.trucks.find(truck => truck.userId === userId);
    for (var truck in this.trucks) {
      if (this.trucks[truck].userId === userId) {
        this.trucks[truck].resetLocation();
        // also reset the leg index of the schedule in the db
        for (var schedule in this.schedules) {
          if (this.schedules[schedule].user === userId) {
            setDoc(doc(firestore, 'schedules', this.schedules[schedule].id), {
              legIndex: 0
            }, { merge: true });
          }
        }
      }
    }
  }

  async startSimulation() {
    if (!this.scenarioId) return;

    await this.getSimulationConfig();
    if (!this.portalPoints || !this.spawnPoints) return;
    if (this.portalPoints.length == 0 || this.spawnPoints.length == 0) return;

    await this.deleteDummySchedules();

    const spawnPoint = this.spawnPoints[0];
    this.simulationUiManager.setView(spawnPoint.latitude, spawnPoint.longitude, 11);

    this.scheduler = new LiveScheduler(this.services, this.customTime, this.setDoverChartData, this.enableScheduler);
    this.startTrucks();
    this.spawnTrucks();

    // listen to all active schedules
    const servicePointsSnapshot = await getDocs(collection(firestore, 'schedules'));
    // this.schedules = servicePointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    this.schedules = servicePointsSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(schedule => schedule.isDummy !== true);


    // add a listener to the schedules table to listen for any changes to the schedules and store them in the schedules array
    this.unsubscribe = onSnapshot(collection(firestore, 'schedules'), (snapshot) => {
      // let newScenarios = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      let newScenarios = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(schedule => schedule.isDummy !== true);

      // check if any scenarios have been removed
      for (var schedule in this.schedules) {
        if (!newScenarios.find(newScenario => newScenario.id === this.schedules[schedule].id)) {
          // then we have removed a scenario so we need to remove the truck
          for (var truck in this.trucks) {
            if (this.trucks[truck].userId === this.schedules[schedule].user) {
              this.simulationUiManager.removeMarker(this.trucks[truck].id);
              // this.trucks.splice(truck, 1);
              delete this.trucks[truck];
            }
          }
        }
      }
      this.schedules = newScenarios;
    });

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    while (true) {
      // update the simulatedtime using the custom time object
      await setDoc(doc(firestore, 'simulationtime', 'iTmCZ15ag3ggnA0tvrf3'), {
        time: Timestamp.fromDate(this.customTime.getCurrentTime())
      });
      // now loop the schedules array
      for (const schedule of this.schedules) {
        // if (schedule.isDummy) {
        //   // Create a truck for each schedule
        //   if (!this.trucks[schedule.id]) {
        //     const truck = new Truck(null, null, null, null, this.customTime, this.simulationUiManager);
        //     truck.userId = schedule.user;
        //     truck.updateRoute(null, null, JSON.parse(schedule.route));
        //     this.trucks[schedule.id] = truck;
        //     console.log(this.trucks);
        //     console.log(`Created new truck for schedule: ${schedule.id}`);




        //   }
        //   // else {
        //   //   var status = this.trucks[schedule.id].move(); if (status == 'finished') {
        //   //     // then we have finished a leg of the journey 
        //   //     console.log('finished');
        //   //     this.trucks.splice(this.trucks[schedule.id], 1);
        //   //     // for (var truck in this.trucks) {
        //   //     //   if (this.trucks[truck].userId === this.schedules[schedule].user) {
        //   //     //     this.simulationUiManager.removeMarker(this.trucks[truck].id);
        //   //     //     this.trucks.splice(truck, 1);
        //   //     //   }
        //   //     // }
        //   //   }
        //   // }
        // } else {
        // Create a truck for each schedule
        if (!this.trucks[schedule.id]) {
          const truck = new Truck(null, null, null, null, this.customTime, this.simulationUiManager);
          truck.userId = schedule.user;
          truck.updateRoute(null, null, JSON.parse(schedule.route));
          truck.isDummy = false;
          this.trucks[schedule.id] = truck;
          console.log(this.trucks);
          console.log(`Created new truck for schedule: ${schedule.id}`);
        }

        // Get the userId from the schedule
        const userId = schedule.user;
        const userRef = doc(firestore, 'users', userId);

        // Query the app_state table for the user
        const appStateQuery = query(collection(firestore, 'app_state'), where('user', '==', userRef));
        const appStateSnapshot = await getDocs(appStateQuery);

        if (!appStateSnapshot.empty) {
          const appStateDoc = appStateSnapshot.docs[0];

          if (schedule) {
            // in the case we are waiting we dont want passing time to have any effect on the location of the truck
            if (schedule.status == 'waiting' && this.trucks[schedule.id].status != 'waiting') {
              // this.trucks[schedule.id].resetLocation();
              this.trucks[schedule.id].distanceTraveled = 0
              this.trucks[schedule.id].totalDistanceTraveled = 0;
              // this.trucks[schedule.id].legIndex = 0;
              this.trucks[schedule.id].pathIndex = 0;
              // this.trucks[schedule.id].lastUpdateTime = this.customTime.getCurrentTime();
            }

            if (schedule.status != 'waiting' && this.trucks[schedule.id].status == 'waiting') {
              this.trucks[schedule.id].lastUpdateTime = this.customTime.getCurrentTime();
              this.trucks[schedule.id].legIndex = schedule.legIndex;
            }

            this.trucks[schedule.id].status = schedule.status;

            if (appStateDoc.data().isDriving && schedule.status != 'waiting') {
              // Logic to calculate the new location
              // const newLocation = this.calculateNewLocation();
              var status = this.trucks[schedule.id].move_testing();

              if (status == 'endofleg') {
                // then we have finished a leg of the journey 
              }

              let newLocation = this.trucks[schedule.id].currentLocation;
              console.log(newLocation);
              // Update the simulatedLocation table in the database
              await setDoc(doc(firestore, 'simulatedLocation', userId), {
                location: newLocation,
                timestamp: Date.now()
              });
            } else if (!appStateDoc.data().isDriving) {
              this.trucks[schedule.id].paused = true;
            }
          }
          // }
        }
        await delay(500);
      }

      this.scheduleService.getScheduleState().then((scheduleState) => {
        this.setDoverChartData(scheduleState);
      });

      await delay(500);
    }
  }

  // New method to delete dummy schedules
  async deleteDummySchedules() {
    const schedulesRef = collection(firestore, 'schedules');
    // const dummySchedulesQuery = query(schedulesRef, where('isDummy', '==', true));
    const dummySchedulesQuery = query(schedulesRef);
    const dummySchedulesSnapshot = await getDocs(dummySchedulesQuery);

    const batch = writeBatch(firestore);
    dummySchedulesSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log(`Deleted ${dummySchedulesSnapshot.size} dummy schedules.`);
  }


  async getSimulationConfig() {
    const scenarioRef = doc(firestore, 'scenarios', this.scenarioId);

    const servicePointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'service'), where('scenarios', 'array-contains', scenarioRef)));
    this.services = servicePointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const spawnPointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'spawn'), where('scenarios', 'array-contains', scenarioRef)));
    this.spawnPoints = spawnPointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const portalPointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'portal'), where('scenarios', 'array-contains', scenarioRef)));
    this.portalPoints = portalPointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  // updateRoutes() {
  //   //update the routing on trucks periodically
  //   let truckIndex = 0;
  //   this.updateRoutesInterval = setInterval(async () => {


  //     await this.getSimulationConfig();
  //     this.scheduler.services = this.services;

  //     if (this.trucks.length > 0) {
  //       const truck = this.trucks[truckIndex];
  //       if (truck && truck.currentLocation) {
  //         await this.scheduler.scheduleTruck(truck);
  //       }
  //       truckIndex = (truckIndex + 1) % this.trucks.length; // Move to the next truck, wrap around if at the end
  //     }
  //   }, 100);
  // }

  async addTruck(spawnPoint, portalPoint, route, path) {
    const truck = new Truck(spawnPoint, portalPoint, route, path, this.customTime, this.simulationUiManager);
    truck.isDummy = true;
    let success = await this.scheduler.scheduleTruck(truck);
    if (success) {
      this.simulationUiManager.addVehicleToPanel(truck); // Assuming UIManager handles UI updates
      // truck.scheduleMovement(this.updateSpeed);
      this.trucks.push(truck);
    }
  }

  startTrucks() {
    this.simulationInterval = setInterval(async () => {
      for (var i = 0; i < this.trucks.length; i++) {
        let thisTruck = this.trucks[i];
        if (thisTruck.isDummy) {
          console.log('thisTruck.move();');
          let status = thisTruck.move();
          console.log('status: ' + status);
          if (status == 'finished') {
            this.trucks.splice(i, 1);
            i--; // Adjust index after removal
            // then we also need to remove the schedule for this truck
            const schedulesSnapshot = await getDocs(collection(firestore, 'schedules'));
            // this.schedules = servicePointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            let dummySchedules = schedulesSnapshot.docs
              .map(doc => ({ id: doc.id, ...doc.data() }))
              .filter(schedule => schedule.isDummy == true);

            for (var schedule in dummySchedules) {
              if (dummySchedules[schedule].id === thisTruck.scheduleId) {
                deleteDoc(doc(firestore, 'schedules', dummySchedules[schedule].id));
              }
            }
          }
        }
      }
    }, this.updateSpeed);
  }

  async spawnTrucks() {
    if (this.spawnInterval) {
      clearInterval(this.spawnInterval);
    }
    this.spawnInterval = setInterval(async () => {

      const portalPoint = this.portalPoints[Math.floor(Math.random() * this.portalPoints.length)];
      const spawnPoint = this.spawnPoints[Math.floor(Math.random() * this.spawnPoints.length)];

      // console.log('scheduleTruck');
      this.addTruck(spawnPoint, portalPoint, null, null);
      // clearInterval(this.spawnInterval);
    }, this.truckSpawnTime / this.simulationSpeed); // Adjust interval dynamically based on simulation speed
  }

  // cancelIntervals() {
  //   clearInterval(this.simulationInterval);
  //   clearInterval(this.spawnInterval);
  //   clearInterval(this.updateRoutesInterval);
  // }

  // clearTrucks() {
  //   for (var i = 0; i < this.trucks.length; i++) {
  //     this.simulationUiManager.removeMarker(this.trucks[i].id);
  //   }
  //   this.trucks = [];
  // }

  // stopSimulation() {
  //   this.cancelIntervals();
  //   this.trucks.forEach(truck => clearInterval(truck.intervalId));
  // }

  // updateSimulationSpeed(speed, truckSpawnRate) {
  //   this.simulationSpeed = speed;
  //   this.customTime.setUpdateSpeed(speed);

  //   this.truckSpawnRate = truckSpawnRate;
  //   this.truckSpawnTime = (1 / this.truckSpawnRate) * 60 * 60 * 1000

  //   // clearInterval(this.simulationInterval);
  //   // clearInterval(this.spawnInterval);
  //   // this.startSimulation(); // Restart simulation with new speed

  //   // if (this.scenarioId && this.portalPoints && this.spawnPoints) {
  //   //   if (this.portalPoints.length > 0 && this.spawnPoints.length > 0) {
  //   //     this.startTrucks();
  //   //     this.spawnTrucks();
  //   //   }
  //   // }
  // }

  updateSimulationSpeed(speed, truckSpawnRate) {
    this.simulationSpeed = speed;
    this.customTime.setUpdateSpeed(speed);

    this.truckSpawnRate = truckSpawnRate;
    this.truckSpawnTime = (1 / this.truckSpawnRate) * 60 * 60 * 1000

    clearInterval(this.simulationInterval);
    clearInterval(this.spawnInterval);
    // this.startSimulation(); // Restart simulation with new speed

    if (this.scenarioId && this.portalPoints && this.spawnPoints) {
      if (this.portalPoints.length > 0 && this.spawnPoints.length > 0) {
        this.startTrucks();
        this.spawnTrucks();
      }
    }
  }

  updateScenarioId(scenarioId) {
    this.scenarioId = scenarioId;
    // this.cancelIntervals();
    // this.clearTrucks();
    this.startSimulation(); // Restart simulation with new speed
  }

  updateEnableScheduler(enableScheduler) {
    this.enableScheduler = enableScheduler;
    if (this.scheduler) {
      this.scheduler.enableScheduler = this.enableScheduler;
    }
  }
}