import React, { useState } from "react";
import styles from "./selectaudience.module.css";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SelectAudiencePopUp from "../audience/selectaudiencepopup.js";

const initialAvailableAudiences = [
  "Audience 1",
  "Audience 2",
  "Audience 3",
  "Audience 4",
  "Audience 5",
];

function SelectAudience() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [includedAudiences, setIncludedAudiences] = useState([]);
  const [excludedAudiences, setExcludedAudiences] = useState([]);
  const [availableAudiences, setAvailableAudiences] = useState(
    initialAvailableAudiences,
  );
  const [isInclude, setIsInclude] = useState(true);

  const handleOpenModal = (include) => {
    setIsInclude(include);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectAudience = (audience) => {
    if (isInclude) {
      setIncludedAudiences((prev) => [...prev, audience]);
    } else {
      setExcludedAudiences((prev) => [...prev, audience]);
    }
    setAvailableAudiences((prev) => prev.filter((a) => a !== audience));
    handleCloseModal();
  };

  const handleRemoveAudience = (e, audience, isInclude) => {
    e.stopPropagation();
    if (isInclude) {
      setIncludedAudiences((prev) => prev.filter((a) => a !== audience));
    } else {
      setExcludedAudiences((prev) => prev.filter((a) => a !== audience));
    }
    setAvailableAudiences((prev) => [...prev, audience]);
  };

  return (
    <row>
      <column>
        <row>
          <h3>Audience</h3>
        </row>
        <row>
          <row>
            <p>
              Who <strong>should</strong> receive this message?
            </p>
          </row>
          <row>
            <label className="checkbox-container">
              Send to everyone
              <input type="checkbox" name="checkbox" />
              <span className="checkbox-checkmark"></span>
            </label>
          </row>
          <row>
            {includedAudiences.length > 0 && (
              <div className={styles.audienceList}>
                {includedAudiences.map((audience, index) => (
                  <span key={index} className={styles.audienceItem}>
                    {audience}
                    <CloseOutlinedIcon
                      onClick={(e) => handleRemoveAudience(e, audience, true)}
                      className={styles.closeaudienceIcon}
                    />
                  </span>
                ))}
              </div>
            )}
            <PersonAddAlt1OutlinedIcon onClick={() => handleOpenModal(true)} />
            <p>Include audience</p>
          </row>
          <row>
            <p>
              Who <strong>should not</strong> receive this message?
            </p>
          </row>
          <row>
            {excludedAudiences.length > 0 && (
              <div className={styles.audienceList}>
                {excludedAudiences.map((audience, index) => (
                  <span key={index} className={styles.audienceItem}>
                    {audience}
                    <CloseOutlinedIcon
                      onClick={(e) => handleRemoveAudience(e, audience, false)}
                      className={styles.closeaudienceIcon}
                    />
                  </span>
                ))}
              </div>
            )}
            <PersonRemoveOutlinedIcon onClick={() => handleOpenModal(false)} />
            <p>Exclude audience</p>
          </row>
        </row>
      </column>

      <SelectAudiencePopUp
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSelectAudience={handleSelectAudience}
        availableAudiences={availableAudiences}
      />
    </row>
  );
}

export default SelectAudience;
