import React from "react";
import styles from "./live.module.css";

function ConfirmScenarioPopup({ open, onClose, onConfirm, scenario }) {
  if (!open) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalBody}>
          <p>Are you sure you want to select {scenario.data.name}?</p>

          <popup-row>
            <button onClick={onClose} className="button whitebutton">
              Cancel
            </button>

            <button onClick={onConfirm} className="button bluebutton">
              Confirm
            </button>
          </popup-row>
        </div>
      </div>
    </div>
  );
}

export default ConfirmScenarioPopup;
