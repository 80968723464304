import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import styles from './add-marker-panel.module.css';

function AddMarkerPanel({ toggleAddingAccident, addingAccident, scenarioId }) {
    const isDisabled = scenarioId === null;
    const tooltipTitle = isDisabled ? "Please select a scenario to add a marker" : "";

    return (
        <div className={`${styles.addMarkerPanelContainer}`}>
            <Tooltip title={tooltipTitle} arrow>
                <div> {/* Non-disabled wrapper for the tooltip */}
                    <IconButton  style={{ fontSize: '16px' }} disableRipple className={styles.addMarkerButton} onClick={toggleAddingAccident} disabled={isDisabled} >
                        {isDisabled ? 
                            <LocationOffIcon style={{ color: 'grey' }} /> : 
                            <PinDropIcon style={{ color: '#024761' }} />}
                        {addingAccident ? "Cancel" : "Add Marker"}
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );
}

export { AddMarkerPanel };