// import { Truck } from './trucks.js';
// // import { CustomTime } from './custom_time.js';
// // import { getArrivalSlotIndex, portalSlots, updatePortalSlotsChart } from '../scheduler/scheduler.js';

// export class SimulationManager {
//   constructor(updateSpeed) {
//     this.trucks = [];
//     this.updateSpeed = updateSpeed;
//     this.simulationInterval = null;
//   }

//   addTruck(spawnPoint, portalPoint, route, path) {
//     const truck = new Truck(spawnPoint, portalPoint, route, path);
//     truck.scheduleMovement(this.updateSpeed);
//     this.trucks.push(truck);
//   }

//   startSimulation() {
//     this.simulationInterval = setInterval(() => {
//       this.trucks.forEach(truck => truck.move());
//     }, this.updateSpeed);
//   }

//   stopSimulation() {
//     clearInterval(this.simulationInterval);
//     this.trucks.forEach(truck => clearInterval(truck.intervalId));
//   }

//   updateSimulationSpeed(newSpeed) {
//     this.updateSpeed = newSpeed;
//     this.trucks.forEach(truck => {
//       clearInterval(truck.intervalId);
//       truck.scheduleMovement(this.updateSpeed);
//     });
//   }
// }

import { Truck } from './trucks.js';
import { getFirestore, collection, getDocs, query, where, doc } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import { decodePath } from '../../utils/utils.js';
import { CustomTime } from './custom-time.js';
import { RouteService } from '../../../api/route-service.js';
import { Scheduler } from './scheduler.js'
import { LiveScheduler } from './live-scheduler.js'

import { GlobalVariables } from '../../utils/global-variables.js'
import { SiteConfig } from '../../../site-config.js';

export class SimulationManager {
  constructor(updateSpeed, simulationSpeed, truckSpawnRate, scenarioId, simulationUiManager, customTime, setDoverChartData, enableScheduler, setExitTimes, setParkedData) {
    this.trucks = [];
    this.updateSpeed = updateSpeed;
    this.simulationSpeed = simulationSpeed;
    this.truckSpawnRate = truckSpawnRate;
    this.truckSpawnTime = (1 / this.truckSpawnRate) * 60 * 60 * 1000
    this.simulationUiManager = simulationUiManager;
    this.simulationInterval = null;
    this.spawnInterval = null;
    this.scenarioId = scenarioId;
    this.customTime = customTime;
    this.routeService = new RouteService(SiteConfig.routeServerBaseUrl);
    this.setDoverChartData = setDoverChartData;
    this.enableScheduler = enableScheduler;
    this.setExitTimes = setExitTimes;
    this.exitTimes = {};
    this.setParkedData = setParkedData;
    this.parkedData = {};
    this.waitTimeAtService = 15;
  }

  async addTruck(spawnPoint, portalPoint, route, path) {
    const truck = new Truck(spawnPoint, portalPoint, route, path, this.customTime, this.simulationUiManager);
    let success = await this.scheduler.scheduleTruck(truck);
    if (success) {
      this.simulationUiManager.addVehicleToPanel(truck); // Assuming UIManager handles UI updates
      // truck.scheduleMovement(this.updateSpeed);
      this.trucks.push(truck);
    }
  }

  async startSimulation(isTesting) {

    if (!this.scenarioId) return;

    await this.getSimulationConfig();
    if (!this.portalPoints || !this.spawnPoints) return;
    // console.log(this.portalPoints);
    // console.log(this.spawnPoints);
    if (this.portalPoints.length == 0 || this.spawnPoints.length == 0) return;


    if (!isTesting) {
      this.scheduler = new Scheduler(this.services, this.customTime, this.setDoverChartData, this.enableScheduler);
      // testy();
    } else {
      this.scheduler = new LiveScheduler(this.services, this.customTime, this.setDoverChartData, this.enableScheduler);
    }

    // //if we are triggering startSimulation from the scenario update, then there might be trucks that need to have portals updated
    // this.trucks.forEach( async truck => {
    //     if (truck.currentLocation){
    //         const portalPoint = this.portalPoints[Math.floor(Math.random() * this.portalPoints.length)];
    //         const route = await this.routeService.fetchRoute(truck.currentLocation, portalPoint);
    //         const path = decodePath(route.points);
    //         truck.updateRoute(truck.currentLocation, portalPoint, route, path);
    //     }
    // });

    if (this.spawnPoints != undefined && this.portalPoints != undefined) {
      if (this.spawnPoints.length > 0 && this.portalPoints.length > 0) {

        // move the map to show all the points
        const spawnPoint = this.spawnPoints[0];
        this.simulationUiManager.setView(spawnPoint.latitude, spawnPoint.longitude);

        this.startTrucks();

        // this.updateRoutes();

        // update the routing on trucks periodically
        // let truckIndex = 0;
        // this.simulationInterval = setInterval(async () => {
        //     if (this.trucks.length > 0) {
        //         const truck = this.trucks[truckIndex];
        //         if (truck.currentLocation) {
        //             const route = await this.routeService.fetchRoute(truck.currentLocation, truck.portalPoint);
        //             const path = decodePath(route.points);
        //             truck.updateRoute(truck.currentLocation, truck.portalPoint, route, path);
        //         }
        //         truckIndex = (truckIndex + 1) % this.trucks.length; // Move to the next truck, wrap around if at the end
        //     }
        // }, 100);

        this.spawnTrucks();
      }
    }
  }

  async getSimulationConfig() {
    const scenarioRef = doc(firestore, 'scenarios', this.scenarioId);

    const servicePointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'service'), where('scenarios', 'array-contains', scenarioRef)));
    this.services = servicePointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const spawnPointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'spawn'), where('scenarios', 'array-contains', scenarioRef)));
    this.spawnPoints = spawnPointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const portalPointsSnapshot = await getDocs(query(collection(firestore, 'geopoints'), where('markerType', '==', 'portal'), where('scenarios', 'array-contains', scenarioRef)));
    this.portalPoints = portalPointsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  updateRoutes() {
    //update the routing on trucks periodically
    let truckIndex = 0;
    this.updateRoutesInterval = setInterval(async () => {


      await this.getSimulationConfig();
      this.scheduler.services = this.services;

      if (this.trucks.length > 0) {
        const truck = this.trucks[truckIndex];
        if (truck && truck.currentLocation) {
          await this.scheduler.scheduleTruck(truck);
        }
        truckIndex = (truckIndex + 1) % this.trucks.length; // Move to the next truck, wrap around if at the end
      }
    }, 100);
  }

  startTrucks() {
    this.simulationInterval = setInterval(() => {
      for (var i = 0; i < this.trucks.length; i++) {
        let thisTruck = this.trucks[i];
        let status = thisTruck.move();
        if (status == 'finished') {
          const currentTime = this.customTime.getCurrentTime();
          if (!this.exitTimes[thisTruck.portalPoint.id] || this.exitTimes[thisTruck.portalPoint.id].graphDate != currentTime.getDate()) {
            const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
            const endOfDay = new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000);
            let labels = [];
            let data = [];
            for (let time = startOfDay; time < endOfDay; time = new Date(time.getTime() + this.waitTimeAtService * 60 * 1000)) {
              const hour = time.getHours();
              const minute = time.getMinutes();
              const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              labels.push(formattedTime);
              data.push(0);
            }
            this.exitTimes[thisTruck.portalPoint.id] = {
              description: thisTruck.description,
              labels: labels,
              data: data,
              graphDate: currentTime.getDate()
            };
          }

          const slotIndex = this.scheduler.getTimeSlotIndex(this.customTime.getCurrentTime());
          this.exitTimes[thisTruck.portalPoint.id].data[slotIndex]++;
          this.setExitTimes(this.exitTimes);
          this.trucks.splice(i, 1);
          i--; // Adjust index after removal
        }
        if (status == 'parked') {
          let service = thisTruck.route.legs[thisTruck.legIndex].from;
          if (!this.exitTimes[service.id]) {

            const currentTime = this.customTime.getCurrentTime();
            const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
            const endOfDay = new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000);
            let labels = [];
            let data = [];
            for (let time = startOfDay; time < endOfDay; time = new Date(time.getTime() + this.waitTimeAtService * 60 * 1000)) {
              const hour = time.getHours();
              const minute = time.getMinutes();
              const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              labels.push(formattedTime);
              data.push(0);
            }
            this.exitTimes[service.id] = {
              description: thisTruck.description,
              labels: labels,
              data: data,
              graphDate: currentTime.getDate(),
              trucksCurrentlyParked: []
            };
          }

          const slotIndex = this.scheduler.getTimeSlotIndex(this.customTime.getCurrentTime());

          const parkedIndex = this.exitTimes[service.id].trucksCurrentlyParked.findIndex(truck => truck.id === thisTruck.id);
          if (parkedIndex == -1) {
            this.exitTimes[service.id].trucksCurrentlyParked.push(thisTruck);
            this.exitTimes[service.id].data[slotIndex] = this.exitTimes[service.id].trucksCurrentlyParked.length;
            this.setExitTimes(this.exitTimes);
          }
        }

        if (status == 'moving') {
          // if the truck is moving then check to see if we need to remove the car from parked at the serices
          let service = thisTruck.route.legs[thisTruck.legIndex].from;
          if (this.exitTimes[service.id]) {
            const parkedIndex = this.exitTimes[service.id].trucksCurrentlyParked.findIndex(truck => truck.id === thisTruck.id);
            if (parkedIndex !== -1) {
              this.exitTimes[service.id].trucksCurrentlyParked.splice(parkedIndex, 1);
            }
            const slotIndex = this.scheduler.getTimeSlotIndex(this.customTime.getCurrentTime());
            this.exitTimes[service.id].data[slotIndex] = this.exitTimes[service.id].trucksCurrentlyParked.length;
          }
        }


      }
    }, this.updateSpeed);
  }

  async spawnTrucks() {
    if (this.spawnInterval) {
      clearInterval(this.spawnInterval);
    }
    this.spawnInterval = setInterval(async () => {



      const portalPoint = this.portalPoints[Math.floor(Math.random() * this.portalPoints.length)];
      const spawnPoint = this.spawnPoints[Math.floor(Math.random() * this.spawnPoints.length)];

      // // Fetch route and path here, similar to your existing addVehicle function
      // const route = await this.routeService.fetchRoute(this.spawnPoint, portalPoint); // Assuming fetchRoute is defined
      // const path = decodePath(route.points);

      // const truck = new Truck(this.spawnPoint, portalPoint, route, path, this.customTime, this.simulationUiManager);
      // this.scheduler.scheduleTruck(truck);
      var route = null;
      var path = null;

      this.addTruck(spawnPoint, portalPoint, route, path);
    }, this.truckSpawnTime / this.simulationSpeed); // Adjust interval dynamically based on simulation speed
  }

  cancelIntervals() {
    clearInterval(this.simulationInterval);
    clearInterval(this.spawnInterval);
    clearInterval(this.updateRoutesInterval);
  }

  clearTrucks() {
    for (var i = 0; i < this.trucks.length; i++) {
      this.simulationUiManager.removeMarker(this.trucks[i].id);
    }
    this.trucks = [];
  }

  stopSimulation() {
    this.cancelIntervals();
    this.trucks.forEach(truck => clearInterval(truck.intervalId));
  }

  updateSimulationSpeed(speed, truckSpawnRate) {
    this.simulationSpeed = speed;
    this.customTime.setUpdateSpeed(speed);

    this.truckSpawnRate = truckSpawnRate;
    this.truckSpawnTime = (1 / this.truckSpawnRate) * 60 * 60 * 1000

    clearInterval(this.simulationInterval);
    clearInterval(this.spawnInterval);
    // this.startSimulation(); // Restart simulation with new speed

    if (this.scenarioId && this.portalPoints && this.spawnPoints) {
      if (this.portalPoints.length > 0 && this.spawnPoints.length > 0) {
        this.startTrucks();
        this.spawnTrucks();
      }
    }
  }

  updateScenarioId(scenarioId, isTesting) {
    this.scenarioId = scenarioId;
    this.cancelIntervals();
    this.clearTrucks();
    this.startSimulation(isTesting); // Restart simulation with new speed
  }

  updateEnableScheduler(enableScheduler) {
    this.enableScheduler = enableScheduler;
    if (this.scheduler) {
      this.scheduler.enableScheduler = this.enableScheduler;
    }
  }
}