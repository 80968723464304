// import L from 'leaflet';

// const SpawnIcon = new L.Icon({
//     iconUrl: 'play_circle_black_24dp.svg',
//     iconRetinaUrl: 'play_circle_black_24dp.svg',
//     iconAnchor: null,
//     popupAnchor:  [-0, -0],
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(32, 32),
//     className: 'leaflet-div-icon'
// });

// export { SpawnIcon };


import L from 'leaflet';
import serviceIconUrl from './warehouse.svg';

const SpawnIcon = new L.Icon({
  iconUrl: serviceIconUrl,
  iconRetinaUrl: serviceIconUrl, 
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(28, 28),
  className: 'leaflet-div-icon',

});

export { SpawnIcon };
