import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ivs.module.css";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Modal from "../audience/selectaudiencepopup.js";
import IVSScenarioModal from "./ivsscenariomodal.js";
import IVSMapModal from "./ivsmapmodal.js";
import { firestore } from "../../../firesbase.js";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import Matrix from "../matrix";

function IVS() {
  const location = useLocation();
  const navigate = useNavigate();
  const editMessage = location.state?.editMessage;

  const [isIncludeModalOpen, setIsIncludeModalOpen] = useState(false);
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const [scenarioIds, setScenarioIds] = useState(editMessage?.scenarioIds || []);
  const [includeAudienceIds, setIncludeAudienceIds] = useState(editMessage?.includeAudienceIds || []);
  const [excludeAudienceIds, setExcludeAudienceIds] = useState(editMessage?.excludeAudienceIds || []);
  const [locationData, setLocationData] = useState(editMessage?.location ? { position: editMessage.location, bearing: editMessage.bearing } : null);
  const [transitions, setTransitions] = useState(editMessage?.scenarioTransitions || []);
  const [sendToEveryone, setSendToEveryone] = useState(editMessage?.sendToEveryone || false);
  const [dropdownValue, setDropdownValue] = useState(editMessage?.messageType || "info");

  useEffect(() => {
    if (editMessage) {
      document.getElementById("messageName").value = editMessage.name || editMessage.messageName || "";
      document.getElementById("messageTitle").value = editMessage.messageTitle || "";
      document.getElementById("messageText").value = editMessage.messageText || "";
      const scheduleRadio = document.querySelector(`input[name="schedule"][value="${editMessage.schedule}"]`);
      if (scheduleRadio) scheduleRadio.checked = true;
    }
  }, [editMessage]);

  const handleOpenIncludeModal = () => {
    setIsIncludeModalOpen(true);
  };

  const handleOpenExcludeModal = () => {
    setIsExcludeModalOpen(true);
  };

  const handleCloseIncludeModal = (audienceIds) => {
    setIsIncludeModalOpen(false);
    setIncludeAudienceIds(audienceIds);
  };

  const handleCloseExcludeModal = (audienceIds) => {
    setIsExcludeModalOpen(false);
    setExcludeAudienceIds(audienceIds);
  };

  const handleOpenScenarioModal = () => {
    setIsScenarioModalOpen(true);
  };

  const handleCloseScenarioModal = (scenarioIds) => {
    setIsScenarioModalOpen(false);
    setScenarioIds(scenarioIds);
  };

  const handleOpenMapModal = () => {
    setIsMapModalOpen(true);
  };

  const handleCloseMapModal = (locationData) => {
    setIsMapModalOpen(false);
    setLocationData(locationData);
  };

  const handleDropdownClick = (value) => {
    setDropdownValue(value);
  };

  const handleSendToEveryoneChange = (event) => {
    setSendToEveryone(event.target.checked);
  };

  const submitForm = async () => {
    const data = new FormData(document.ivsForm);
    const ivsData = {
      type: "ivs",
      name: data.get("messageName"),
      messageType: dropdownValue,
      messageTitle: data.get("messageTitle"),
      messageText: data.get("messageText"),
      includeAudienceIds,
      excludeAudienceIds,
      scenarioIds,
      scenarioTransitions: transitions,
      location: locationData?.position,
      bearing: locationData?.bearing,
      schedule: data.get("schedule"),
      sendToEveryone: sendToEveryone
    };
    try {
      if (editMessage) {
        // Update existing document
        const docRef = doc(firestore, "messages", editMessage.id);
        await updateDoc(docRef, ivsData);
        console.log("Document updated with ID: ", editMessage.id);
      } else {
        // Add new document
        const docRef = await addDoc(collection(firestore, "messages"), ivsData);
        console.log("Document written with ID: ", docRef.id);
      }
      // Navigate back to the schedule page after successful submission
      navigate('/schedule');
    } catch (e) {
      console.error("Error adding/updating document: ", e);
    }
  };

  return (
    <form name="ivsForm">
      <row>
        <double-column>
          <row>
            <h1>{editMessage ? "Edit IVS" : "New IVS"}</h1>
            <row>
              <column>
                <label htmlFor="messageName">Message name</label>
                <input type="text" id="messageName" name="messageName"></input>
              </column>
              <column></column>
              <column></column>
            </row>

            <row>
              <column>
                <dashboard-container>
                  <row>
                    <h3>Audience</h3>
                  </row>
                  <row>
                    <row>
                      <p>
                        Who <strong>should</strong> receive this message?
                      </p>
                    </row>
                    <row>
                      <label className="checkbox-container">
                        Send to everyone
                        <input
                          type="checkbox"
                          name="sendToEveryone"
                          checked={sendToEveryone}
                          onChange={handleSendToEveryoneChange}
                        />
                        <span className="checkbox-checkmark"></span>
                      </label>
                    </row>
                    <row onClick={handleOpenIncludeModal}>
                      <PersonAddAlt1OutlinedIcon />
                      <p>Include audience</p>
                    </row>
                    <row>
                      <p>
                        Who <strong>should not</strong> receive this message?
                      </p>
                    </row>
                    <row onClick={handleOpenExcludeModal}>
                      <PersonRemoveOutlinedIcon />
                      <p>Exclude audience</p>
                    </row>
                  </row>
                </dashboard-container>
              </column>
            </row>
            <dashboard-container>
              <row>
                <column>
                  <h3>Message</h3>
                  <row>
                    <label htmlFor="messageType">Type of message</label>
                    <row>
                      <div className="dropdown">
                        <button className="dropbtn" type="button">
                          {dropdownValue} <ExpandMoreOutlinedIcon />
                        </button>
                        <div className="dropdown-content">
                          <a onClick={() => handleDropdownClick("info")}>
                            Info
                          </a>
                          <a onClick={() => handleDropdownClick("hazard")}>
                            Hazard
                          </a>
                          <a onClick={() => handleDropdownClick("alert")}>
                            Alert
                          </a>
                        </div>
                      </div>
                    </row>
                    <column>
                      <label htmlFor="messageTitle">Title</label>
                      <input
                        type="text"
                        id="messageTitle"
                        name="messageTitle"
                      ></input>
                    </column>
                  </row>
                </column>
                <column></column>
                <column></column>
              </row>
              <row>
                <half-column>
                  <label htmlFor="messageText">Message text</label>
                  <textarea
                    id="messageText"
                    name="messageText"
                    rows="8"
                    cols="10"
                  ></textarea>

                  <div className={styles.textareaTip}>Max 128 characters</div>

                  <row>
                    <row>
                      <p>Is this a location based message?</p>
                    </row>

                    <row>
                      <button
                        className="button bluebutton"
                        onClick={handleOpenMapModal}
                        type="button"
                      >
                        Yes
                      </button>
                    </row>

                    <p>Do you want to add this message to a scenario?</p>
                    <row>
                      <button
                        className="button bluebutton"
                        onClick={handleOpenScenarioModal}
                        type="button"
                      >
                        Yes
                      </button>
                    </row>
                  </row>
                </half-column>
              </row>
            </dashboard-container>
            <Matrix callback={(ids) => setTransitions(ids)} />
            <row>
              <column>
                <dashboard-container>
                  <row>
                    <h3>Delivery schedule</h3>
                  </row>
                  <row>
                    <row>
                      <p>When should this message start sending?</p>
                    </row>

                    <column>
                      <label className="radio-container">
                        Immediately
                        <input
                          type="radio"
                          name="schedule"
                          value="immediately"
                          defaultChecked={!editMessage}
                        ></input>
                        <span className="checkmark"></span>
                      </label>

                      <label className="radio-container">
                        Specific time
                        <input
                          type="radio"
                          name="schedule"
                          value="specificTime"
                        ></input>
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-container">
                        Link to a scenario
                        <input
                          type="radio"
                          name="schedule"
                          value="link"
                        ></input>
                        <span className="checkmark"></span>
                      </label>
                    </column>
                  </row>
                </dashboard-container>
                <bottom-row>
                  <button
                    className="button bluebutton"
                    type="button"
                    onClick={() => submitForm()}
                  >
                    {editMessage ? "Update" : "Save"}
                  </button>
                  <button
                    className="button whitebutton"
                    type="button"
                    onClick={() => navigate('/schedule')}
                  >
                    Cancel
                  </button>
                </bottom-row>
              </column>
            </row>
          </row>
        </double-column>
        <Modal
          isOpen={isIncludeModalOpen}
          onClose={handleCloseIncludeModal}
        ></Modal>
        <Modal
          isOpen={isExcludeModalOpen}
          onClose={handleCloseExcludeModal}
        ></Modal>
        <IVSScenarioModal
          isOpen={isScenarioModalOpen}
          onClose={handleCloseScenarioModal}
        ></IVSScenarioModal>
        <IVSMapModal
          isOpen={isMapModalOpen}
          onClose={handleCloseMapModal}
          initialLocation={locationData?.position}
          initialBearing={locationData?.bearing}
        />
      </row>
    </form>
  );
}

export default IVS;

// import React, { useState } from "react";
// import styles from "./ivs.module.css";
// import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
// import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
// import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
// import Modal from "../audience/selectaudiencepopup.js";
// import IVSScenarioModal from "./ivsscenariomodal.js";
// import IVSMapModal from "./ivsmapmodal.js";
// import { firestore } from "../../../firesbase.js";
// import { collection, addDoc } from "firebase/firestore";
// import Matrix from "../matrix";

// function IVS() {
//   const [isIncludeModalOpen, setIsIncludeModalOpen] = useState(false);
//   const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false);
//   const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
//   const [isMapModalOpen, setIsMapModalOpen] = useState(false);

//   const [scenarioIds, setScenarioIds] = useState([]);
//   const [includeAudienceIds, setIncludeAudienceIds] = useState([]);
//   const [excludeAudienceIds, setExcludeAudienceIds] = useState([]);
//   const [locationData, setLocationData] = useState(null);
//   const [transitions, setTransitions] = useState([]);
//   const [sendToEveryone, setSendToEveryone] = useState(false);
//   const [dropdownValue, setDropdownValue] = useState("info");

//   const handleOpenIncludeModal = () => {
//     setIsIncludeModalOpen(true);
//   };

//   const handleOpenExcludeModal = () => {
//     setIsExcludeModalOpen(true);
//   };

//   const handleCloseIncludeModal = (audienceIds) => {
//     setIsIncludeModalOpen(false);
//     setIncludeAudienceIds(audienceIds);
//   };

//   const handleCloseExcludeModal = (audienceIds) => {
//     setIsExcludeModalOpen(false);
//     setExcludeAudienceIds(audienceIds);
//   };

//   const handleOpenScenarioModal = () => {
//     setIsScenarioModalOpen(true);
//   };

//   const handleCloseScenarioModal = (scenarioIds) => {
//     setIsScenarioModalOpen(false);
//     setScenarioIds(scenarioIds);
//   };

//   const handleOpenMapModal = () => {
//     setIsMapModalOpen(true);
//   };

//   const handleCloseMapModal = (locationData) => {
//     setIsMapModalOpen(false);
//     setLocationData(locationData);
//   };

//   const handleDropdownClick = (value) => {
//     setDropdownValue(value);
//   };

//   const handleSendToEveryoneChange = (event) => {
//     setSendToEveryone(event.target.checked);
//   };

//   const submitForm = async () => {
//     const data = new FormData(document.ivsForm);
//     const ivsData = {
//       type: "ivs",
//       name: data.get("messageName"),
//       messageType: dropdownValue,
//       messageTitle: data.get("messageTitle"),
//       messageText: data.get("messageText"),
//       includeAudienceIds,
//       excludeAudienceIds,
//       scenarioIds,
//       scenarioTransitions: transitions,
//       location: locationData?.position,
//       bearing: locationData?.bearing,
//       schedule: data.get("schedule"),
//       sendToEveryone: sendToEveryone
//     };
//     try {
//       const docRef = await addDoc(collection(firestore, "messages"), ivsData);
//       console.log("Document written with ID: ", docRef.id);
//     } catch (e) {
//       console.error("Error adding document: ", e);
//     }
//   };

//   return (
//     <form name="ivsForm">
//       <row>
//         <double-column>
//           <row>
//             <h1>New IVS</h1>
//             <row>
//               <column>
//                 <label htmlFor="messageName">Message name</label>
//                 <input type="text" id="messageName" name="messageName"></input>
//               </column>
//               <column></column>
//               <column></column>
//             </row>

//             <row>
//               <column>
//                 <dashboard-container>
//                   <row>
//                     <h3>Audience</h3>
//                   </row>
//                   <row>
//                     <row>
//                       <p>
//                         Who <strong>should</strong> receive this message?
//                       </p>
//                     </row>
//                     <row>
//                       <label className="checkbox-container">
//                         Send to everyone
//                         <input
//                           type="checkbox"
//                           name="sendToEveryone"
//                           checked={sendToEveryone}
//                           onChange={handleSendToEveryoneChange}
//                         />
//                         <span className="checkbox-checkmark"></span>
//                       </label>
//                     </row>
//                     <row onClick={handleOpenIncludeModal}>
//                       <PersonAddAlt1OutlinedIcon />
//                       <p>Include audience</p>
//                     </row>
//                     <row>
//                       <p>
//                         Who <strong>should not</strong> receive this message?
//                       </p>
//                     </row>
//                     <row onClick={handleOpenExcludeModal}>
//                       <PersonRemoveOutlinedIcon />
//                       <p>Exclude audience</p>
//                     </row>
//                   </row>
//                 </dashboard-container>
//               </column>
//             </row>
//             <dashboard-container>
//               <row>
//                 <column>
//                   <h3>Message</h3>
//                   <row>
//                     <label htmlFor="messageType">Type of message</label>
//                     <row>
//                       <div className="dropdown">
//                         <button className="dropbtn" type="button">
//                           {dropdownValue} <ExpandMoreOutlinedIcon />
//                         </button>
//                         <div className="dropdown-content">
//                           <a onClick={() => handleDropdownClick("info")}>
//                             Info
//                           </a>
//                           <a onClick={() => handleDropdownClick("hazard")}>
//                             Hazard
//                           </a>
//                           <a onClick={() => handleDropdownClick("alert")}>
//                             Alert
//                           </a>
//                         </div>
//                       </div>
//                     </row>
//                     <column>
//                       <label htmlFor="messageTitle">Title</label>
//                       <input
//                         type="text"
//                         id="messageTitle"
//                         name="messageTitle"
//                       ></input>
//                     </column>
//                   </row>
//                 </column>
//                 <column></column>
//                 <column></column>
//               </row>
//               <row>
//                 <half-column>
//                   <label htmlFor="messageText">Message text</label>
//                   <textarea
//                     id="messageText"
//                     name="messageText"
//                     rows="8"
//                     cols="10"
//                   ></textarea>

//                   <div className={styles.textareaTip}>Max 128 characters</div>

//                   <row>
//                     <row>
//                       <p>Is this a location based message?</p>
//                     </row>

//                     <row>
//                       <button
//                         className="button bluebutton"
//                         onClick={handleOpenMapModal}
//                         type="button"
//                       >
//                         Yes
//                       </button>
//                     </row>

//                     <p>Do you want to add this message to a scenario?</p>
//                     <row>
//                       <button
//                         className="button bluebutton"
//                         onClick={handleOpenScenarioModal}
//                         type="button"
//                       >
//                         Yes
//                       </button>
//                     </row>
//                   </row>
//                 </half-column>
//               </row>
//             </dashboard-container>
//             <Matrix callback={(ids) => setTransitions(ids)} />
//             <row>
//               <column>
//                 <dashboard-container>
//                   <row>
//                     <h3>Delivery schedule</h3>
//                   </row>
//                   <row>
//                     <row>
//                       <p>When should this message start sending?</p>
//                     </row>

//                     <column>
//                       <label className="radio-container">
//                         Immediately
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="immediately"
//                           defaultChecked={true}
//                         ></input>
//                         <span className="checkmark"></span>
//                       </label>

//                       <label className="radio-container">
//                         Specific time
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="specificTime"
//                         ></input>
//                         <span className="checkmark"></span>
//                       </label>
//                       <label className="radio-container">
//                         Link to a scenario
//                         <input
//                           type="radio"
//                           name="schedule"
//                           value="link"
//                         ></input>
//                         <span className="checkmark"></span>
//                       </label>
//                     </column>
//                   </row>
//                 </dashboard-container>
//                 <bottom-row>
//                   <button
//                     className="button bluebutton"
//                     href="sign-in.html"
//                     type="button"
//                   >
//                     Review and send
//                   </button>
//                   <button
//                     className="button whitebutton"
//                     href="truck-stops.html"
//                     type="button"
//                     onClick={() => submitForm()}
//                   >
//                     Save
//                   </button>
//                 </bottom-row>
//               </column>
//             </row>
//           </row>
//         </double-column>
//         <Modal
//           isOpen={isIncludeModalOpen}
//           onClose={handleCloseIncludeModal}
//         ></Modal>
//         <Modal
//           isOpen={isExcludeModalOpen}
//           onClose={handleCloseExcludeModal}
//         ></Modal>
//         <IVSScenarioModal
//           isOpen={isScenarioModalOpen}
//           onClose={handleCloseScenarioModal}
//         ></IVSScenarioModal>

//         <IVSMapModal
//           isOpen={isMapModalOpen}
//           onClose={handleCloseMapModal}
//         ></IVSMapModal>
//       </row>
//     </form>
//   );
// }

// export default IVS;