// import L from 'leaflet';

// const BlockIcon = new L.Icon({
//     iconUrl: 'blockicon.png',
//     iconRetinaUrl: 'blockicon.png',
//     iconAnchor: null,
//     popupAnchor:  [-0, -0],
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(32, 32),
//     className: 'leaflet-div-icon'
// });

// export { BlockIcon };



import L from 'leaflet';
import serviceIconUrl from './block.svg';

const BlockIcon = new L.Icon({
  iconUrl: serviceIconUrl,
  iconRetinaUrl: serviceIconUrl, 
  iconAnchor: null,
  popupAnchor: [-0, -0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(28, 28),
  className: 'leaflet-div-icon',

});

export { BlockIcon };
