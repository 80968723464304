// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@300;400;500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
    /* height: 100vh; */
    letter-spacing: .2px;
    margin: 0;
    background: #f5f6fa;
}



container {
    /* margin: 30px;
  background: #f5f6fa;
  padding: 0px;
  line-height: 28px; */
}


html {
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}



/* LAYOUT */

row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5em;
}


bottom-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5em;
    margin-top: 30px;
    margin-bottom: 30px;

}

column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

half-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

quarter-column {
    display: flex;
    flex-direction: column;
    /* flex-basis: 100%; */

}



@media screen and (min-width: 800px) {
    column {
        flex: 1;
    }

    double-column {
        flex: 2;

    }

    half-column {
        flex: .5;
    }

    quarter-column {
        flex: .25;
    }


}

/* FONTS */

p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
    letter-spacing: .2px;
}

h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    color: #464B53;
    margin: 0;
}

h2 {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #7a7a7a;
    font-weight: 500;
    margin: 0;
}

h3 {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #7a7a7a;
    margin-bottom: 20px;
    margin-top: 20px;
}

h4 {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #D1D1D6;
}

a {
    line-height: 28px;
    display: block;
    color: #024761;
}

/* BUTTONS */

.button {
    background-color: #024761;
    border: 1px solid #024761;
    border-radius: 5px;
    color: white;
    padding: 4px 22px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 15px;
    cursor: pointer;
    line-height: 24px;
    align-items: center;
    justify-content: center;
}

.bluebutton {
    background-color: #024761;
}

.whitebutton {
    background-color: #ffffff;
    color: #024761;
}

/* White */
.nextbutton {
    background-color: #024761;
    margin-top: 50px;
    margin-bottom: 50px;
}

/* Blue */
.button4 {
    background-color: #e7e7e7;
    color: black;
}

/* Gray */
.button5 {
    background-color: #555555;
}

/* Black */

label {
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    color: #7a7a7a;
    margin-bottom: 5px;
}

input {
    /* border: 1px solid #7a7a7a4a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 2em; */
}

input[type="text"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #7a7a7a;
    margin-bottom: 2em;
}

input[type="number"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #7a7a7a;
    margin-bottom: 2em;
}

input[type="date"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 2em;
}


input[type="time"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 2em;
}

input[type="email"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #7a7a7a;
    margin-bottom: 2em;
}

input[type="password"] {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 2em;
}


textarea {
    border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 2em;
}

/* TABLE */


.events-table {
    margin: 30px 0 0 0;
}


#events th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #7a7a7a;
    border-bottom: 1px solid #7a7a7a4a;
    ;

}

#events td {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #7a7a7a
}


dashboard-container {
    background: white;
    padding: 20px;
    flex: 1;
}


map-container {
    background: white;
    /* padding: 15px; */
    flex: 1;
    z-index: 1000;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
    border-radius: 2px;
}

map-row {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin-left: 225px;
    transition: all 0.5s ease;
}


body.active map-row {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin-left: 0px;
}

map-column {
    /* background-color: red; */

    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 225px;
    margin-top: 40px;
    margin-bottom: 305px;
    transition: all 0.5s ease;


}

body.active map-column {

    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 0px;
    margin-top: 40px;


}


simulation-panel {

    /* background-color: red; */
    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 225px;
    /* margin-top: 380px; */
    margin-top: calc(10vh + 175px);
    /* margin-bottom: 305px; */
    transition: all 0.5s ease;


}

body.active simulation-panel {

    /* background-color: red; */
    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 0px;
    margin-top: calc(10vh + 175px);

    /* margin-top: calc(10vh + 190px); */
    margin-bottom: 305px;
    transition: all 0.5s ease;


}

simulation-panel2 {

    /* background-color: red; */
    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 225px;
    /* margin-top: 380px; */
    margin-top: 40px;
    /* margin-bottom: 305px; */
    transition: all 0.5s ease;


}

body.active simulation-panel2 {

    /* background-color: red; */
    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 0px;
    margin-top: 40px;

    /* margin-top: calc(10vh + 190px); */
    margin-bottom: 305px;
    transition: all 0.5s ease;


}

users-panel{

  /* background-color: red; */
  display: flex;
  flex: .25;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1000;
  flex-basis: 100%;
  overflow-y: auto;
  margin-left: 225px;
  /* margin-top: 380px; */
  margin-top: 300px;
  /* margin-bottom: 305px; */
  transition: all 0.5s ease;


}

body.active users-panel {

  /* background-color: red; */
  display: flex;
  flex: .25;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1000;
  flex-basis: 100%;
  overflow-y: auto;
  margin-left: 0px;
  margin-top: 300px;

  /* margin-top: calc(10vh + 190px); */
  margin-bottom: 305px;
  transition: all 0.5s ease;


}




  




body.active accident-column {

    display: flex;
    flex: .25;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
    flex-basis: 100%;
    overflow-y: auto;
    margin-left: 315px;


}


roadblock-dialog {
    position: absolute;
    top: 148px;
    left: 545px;
    background: #fff;
    padding: 15px;
    border-radius: 2px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
    display: flex;
    flex: .25;
    flex-direction: column;
    transition: all 0.5s ease;




}



body.active roadblock-dialog {
    position: absolute;
    top: 148px;
    left: 320px;

}



.form-col-half {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

.form-col-full {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}


.App .section {
    /* width: calc(100% - 225px); */
    margin-left: 225px;
    transition: all 0.5s ease;
    line-height: 28px;
    height: 100%;
}



/* Checkbox */

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 8px;

    /* margin-bottom: 100px; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 2px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: #f6f7f8;
    border-radius: 2px;
    margin-top: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #e5e8eb;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkbox-checkmark {
    background-color: #024761;
    border-color: #024761;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkbox-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Radio button */

.radio-container {
    display: block;
    font-size: 16px;
    position: relative;
    padding-left: 35px;
    padding-bottom: 0px;
    margin-bottom: 6px;
    margin-top: 6px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
}


.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    font-size: 16px;
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
    margin-top: 5px;
}



.radio-container input:checked~.checkmark {
    background-color: #024761;
    display: flex;
    align-items: center;
}


.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.radio-container input:checked~.checkmark:after {
    display: block;
}



bottom-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5em;
    margin-top: 30px;
    margin-bottom: 30px;


}

settings-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}


email-container {
    border-style: solid;
    border-color: #e5e8eb;
    border-width: 1px;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: 10px;
    cursor: pointer;
}

email-container[draggable] {
    cursor: grab;
}

email-container[draggable]:active {
    cursor: grabbing;
}

email-container:hover {
    border-style: solid;
    border-color: #e5e8eb;
    border-width: 1px;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: 10px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);


}

.email-icons-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
    /* justify-self: flex-start !important; */
    align-self: flex-start;
    object-fit: contain;
    pointer-events: none;
}

email-content-container {
    border-style: solid;
    border-color: #e5e8eb;
    border-width: 1px;
    background: #f6f7f8;
    text-align: center;
    display: flex;
    flex-basis: 100%;
    height: 150px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 80px;
    margin-left: 30px;
    margin-right: 30px;

}

img {
    /* display: block;
  margin-left: auto;
  margin-right: auto; */

}


.audiencebutton {
    background-color: #f6f7f899;
    border: none;
    border-radius: 2px;
    color: #7a7a7a;
    padding: 10px 22px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.audiencebutton:hover {
    background-color: #f6f7f8;
    border: none;
    border-radius: 2px;
    color: #7a7a7a;
    padding: 10px 22px;
    margin-top: 10px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


popup-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}


tabcontent {
    /* display: none; */
    flex: 1;

}

#simple-tabpanel-2 {

    width: 100%;
}

/* slider */

.slidecontainer {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
}



.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    background: #f6f7f8;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    font-size: 1px;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #024761;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #024761;
    cursor: pointer;
}


.emailbluebutton {
    background-color: #024761;
    font-size: 12px !important;
    padding: 4px 20px !important;
    border-radius: 0px !important;
}

.emailwhitebutton {
    background-color: #ffffff !important;
    color: #024761 !important;
    font-size: 12px !important;
    padding: 4px 18px !important;
    border-radius: 0px !important;
}

.dropdown .dropbtn {
    background-color: white;
    width: 160px;
    color: #7a7a7a;
    padding: 8px 8px;
    font-size: 14px;
    border-style: solid;
    border-color: #e5e8eb;
    border-width: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown {
    position: relative;
    display: inline-block;
    text-align: left;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #7a7a7a;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f6f7f8;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: white;
}




.pfp-image {
    border-radius: 10%;
    width: 20%;
}





/* START HERE */

nav-row {
    /* display: flex;
  flex-direction: row;
  width: 100%; */
}

button-row {
    /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end; */
}



body {
    /* background: #f5f6fa;
  height: fit-content; */

}

.wrapper .sidebar {
    background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease;
    z-index: 9999;
}


.wrapper .sidebar ul li {

    cursor: pointer;
}

.wrapper .sidebar ul li .icon {
    color: white;
    width: 30px;
    display: inline-block;
}


/* .sidebar a.active {
    background-color: red; 
    color: #333; 
   
} */


.wrapper .sidebar ul li:hover,
.wrapper .sidebar ul li.active {
    color: white;

    background: #cccccc6b;
    border-right: 2px solid #024761;
}

.wrapper .sidebar ul li:hover .icon,
.wrapper .sidebar ul li.active .icon {
    color: white;
}

.wrapper .sidebar ul li:hover:before,
.wrapper .sidebar ul li.active:before {
    display: block;
}

.wrapper .section {
    width: calc(100% - 225px);
    margin-left: 225px;
    transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
    background: white;
    height: 50px;
    /* display: flex; */
    justify-content: space-between;
    padding: 4px 20px;
    position: sticky;
    top: 0;
    z-index: 100;

}

.wrapper .section .container {
    /* margin: 30px; */
    background: #f5f6fa;
    padding: 30px;
    line-height: 28px;
}

.wrapper .section .tas-container {
    /* margin: 30px; */
    background: #f5f6fa;
    /* padding: 30px; */
    line-height: 28px;
}

body.active .wrapper .sidebar {
    left: -225px;
}




body.active .wrapper .section {
    margin-left: 0;
    width: 100%;
}

/* Sidebar styles */
.sidebar {
    background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease;
    text-align: left;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    position: relative;
}

.sidebar ul li {
    display: block;
    /* padding: 200px 40px; */
    border-bottom: 1px solid #024761;
    color: white;
    font-size: 16px;
    text-decoration: none;
}


/* Sidebar styles */
.sidebar {
    background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease;
}

.sidebar ul {
    list-style: none;

}



.sidebar ul li.hoverstyle:hover {
    background: #cccccc6b;

    border-right: 2px solid #024761;
    color: white;
    padding-bottom: 18px;
}




.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}


.sidebar ul li {
    display: block;
    padding: 15px 30px;
    color: white;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    background: none;
    border: none;
    background: none;
    width: 163px;
    cursor: pointer;

}


.dropdown-btn {
    display: block;
    padding: 12px 30px;
    color: white;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    background: none;
    border: none;
    background: none;

}


.dropdown-btn {

    padding: 13px 50px;
    padding-left: 0;
}


.dropdown-btn .icon {
    margin-right: 10px;
}

.icon {
    padding-right: 20px;
}

.dropdownContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.show {
    max-height: 500px;

    transition: max-height 0.5s ease-in;
}


.logo {

    margin-left: 30px;
    margin-right: 50px;
    margin-bottom: 30px;
}










.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
    border-radius: 2px;
    background-clip: padding-box;
    border: none;
    margin-top: 20px;
}



.leaflet-marker-pane img {
    /* max-width: none !important;
    max-height: none !important; */
    width: auto;
    padding: 3px !important;
    border: none;
    border-radius: 50%;
    /* width: 32px !important;
    height: 32px !important; */
    /* background-color: transparent !important; */
}

.leaflet-marker-pane .arrowMarker {
    padding: 0 !important;
}

.truckicon {
    background-color: transparent !important;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
