import React, { useState, useRef, useEffect } from 'react';
import styles from './circularslider.module.css';

const CircularSlider = ({ initialAngle, onChange }) => {
    const [angle, setAngle] = useState(initialAngle);
    const circleRef = useRef(null);
    const isDragging = useRef(false);

    useEffect(() => {
        setAngle(initialAngle);
    }, [initialAngle]);

    useEffect(() => {
        const updateAngle = (newAngle) => {
            setAngle(newAngle);
            onChange(newAngle);
        };

        const handleMouseMove = (e) => {
            if (isDragging.current && circleRef.current) {
                const rect = circleRef.current.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const dx = e.clientX - centerX;
                const dy = e.clientY - centerY;
                const newAngle = Math.atan2(dy, dx) * (180 / Math.PI) + 90;
                updateAngle((newAngle + 360) % 360);
            }
        };

        const handleMouseDown = (e) => {
            isDragging.current = true;
            handleMouseMove(e);
        };

        const handleMouseUp = () => {
            isDragging.current = false;
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        if (circleRef.current) {
            circleRef.current.addEventListener('mousedown', handleMouseDown);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            if (circleRef.current) {
                circleRef.current.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, [onChange]);

    return (
        <div className={styles.circularSliderContainer}>
            <div className={styles.sliderWrapper}>
                <svg
                    ref={circleRef}
                    className={styles.circularSlider}
                    viewBox="0 0 220 220"
                    width="80"
                    height="80"
                >
                    <circle
                        className={styles.sliderTrack}
                        cx="110"
                        cy="110"
                        r="80"
                    />
                    <line
                        className={styles.sliderLine}
                        x1="110"
                        y1="110"
                        x2={110 + 80 * Math.cos((angle - 90) * (Math.PI / 180))}
                        y2={110 + 80 * Math.sin((angle - 90) * (Math.PI / 180))}
                    />
                    <circle
                        className={styles.sliderHandle}
                        cx={110 + 80 * Math.cos((angle - 90) * (Math.PI / 180))}
                        cy={110 + 80 * Math.sin((angle - 90) * (Math.PI / 180))}
                        r="15"
                    />

                    <text x="110" y="16" textAnchor="middle" className={styles.cardinalText}>N</text>
                    <text x="110" y="220" textAnchor="middle" className={styles.cardinalText}>S</text>
                    <text x="210" y="115" textAnchor="middle" className={styles.cardinalText}>E</text>
                    <text x="10" y="115" textAnchor="middle" className={styles.cardinalText}>W</text>
                </svg>
                <div className={styles.angleDisplay}>Direction: {Math.round(angle)} °</div>
            </div>
        </div>
    );
};

export default CircularSlider;