import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ivs2.module.css";
import IVSMapModal2 from "./ivsmapmodal2";
import SelectAudience2 from "./selectaudience2";
import CircularSlider from "./circularslider";
import IVSScenarioModal2 from "./ivsscenariomodal2";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Matrix2 from "./matrix2";
import IVSMessage from "./ivsmessage";
import { firestore } from "../../../firesbase.js";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const getImageUrlForType = (type) => {
  console.log(type);
  switch (type?.toLowerCase()) {
    case "info":
      return "info.png";
    case "hazard":
      return "hazard.png";
    case "alert":
      return "alert.png";
    default:
      return "";
  }
};

function IVS2() {
  const location = useLocation();
  const navigate = useNavigate();
  const editMessage = location.state?.editMessage;

  const [angle, setAngle] = useState(editMessage?.bearing || 0);
  const markerRef = useRef(null);
  const [title, setTitle] = useState(editMessage?.messageTitle || "");
  const [imageUrl, setImageUrl] = useState(
    editMessage ? getImageUrlForType(editMessage.messageType) : "",
  );
  const [message, setMessage] = useState(editMessage?.messageText || "");
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isMatrixModalOpen, setIsMatrixModalOpen] = useState(false);
  const [isLocationBased, setIsLocationBased] = useState(
    editMessage?.location ? true : null,
  );
  const [dropdownValue, setDropdownValue] = useState(
    editMessage?.messageType || "Select",
  );
  const [selectedScenarios, setSelectedScenarios] = useState(
    editMessage?.scenarioIds || [],
  );
  const [selectedCoordinates, setSelectedCoordinates] = useState(
    editMessage?.location
      ? { position: editMessage.location, bearing: editMessage.bearing }
      : null,
  );
  const [selectedCells, setSelectedCells] = useState(
    editMessage?.scenarioTransitions || [],
  );
  const [messageName, setMessageName] = useState(
    editMessage?.name || editMessage?.messageName || "",
  );
  const [sendToEveryone, setSendToEveryone] = useState(
    editMessage?.sendToEveryone || false,
  );
  const [includeAudienceIds, setIncludeAudienceIds] = useState(
    editMessage?.includeAudienceIds || [],
  );
  const [excludeAudienceIds, setExcludeAudienceIds] = useState(
    editMessage?.excludeAudienceIds || [],
  );

  useEffect(() => {
    if (editMessage) {
      setMessageName(editMessage.name || editMessage.messageName || "");
      setTitle(editMessage.messageTitle || "");
      setMessage(editMessage.messageText || "");
      setDropdownValue(editMessage.messageType || "Select");
      setSelectedScenarios(editMessage.scenarioIds || []);
      setSelectedCells(editMessage.scenarioTransitions || []);
      if (editMessage.location) {
        setSelectedCoordinates({
          position: editMessage.location,
          bearing: editMessage.bearing,
        });
        setAngle(editMessage.bearing);
        setIsLocationBased(true);
      }
    }
  }, [editMessage]);

  const handleOpenScenarioModal = () => setIsScenarioModalOpen(true);
  const handleCloseScenarioModal = () => setIsScenarioModalOpen(false);
  const handleOpenMatrixModal = () => setIsMatrixModalOpen(true);
  const handleCloseMatrixModal = () => setIsMatrixModalOpen(false);
  const handleUpdateSelectedScenarios = (scenarios) =>
    setSelectedScenarios(scenarios);
  const handleOpenMapModal = () => setIsMapModalOpen(true);
  const handleCloseMapModal = (values) => {
    setIsMapModalOpen(false);
    if (values) {
      setSelectedCoordinates(values);
      setAngle(values.bearing);
    }
  };
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleDropdownClick = (value, url) => {
    setDropdownValue(value);
    setImageUrl(url);
  };
  const handleLocationMessageClick = (isLocationBased) => {
    setIsLocationBased(isLocationBased);
    if (isLocationBased === false) {
      setIsMapModalOpen(true);
    }
  };
  const handleRemoveCoordinate = () => {
    setSelectedCoordinates(null);
    setAngle(0);
  };
  const handleCellSelect = (cells) => setSelectedCells(cells);
  const handleMessageNameChange = (e) => setMessageName(e.target.value);

  const handleAngleChange = (newAngle) => {
    setAngle(newAngle);
    if (selectedCoordinates) {
      setSelectedCoordinates({
        ...selectedCoordinates,
        bearing: newAngle,
      });
    }
  };

  const handleSendToEveryoneChange = (e) => {
    setSendToEveryone(e.target.checked);
  };

  const handleIncludeAudienceChange = (audienceIds) => {
    setIncludeAudienceIds(audienceIds);
  };

  const handleExcludeAudienceChange = (audienceIds) => {
    setExcludeAudienceIds(audienceIds);
  };

  const submitForm = async () => {
    const ivsData = {
      type: selectedCoordinates ? "ivs" : "push",
      messageName: messageName,
      messageType: dropdownValue,
      messageTitle: title,
      messageText: message,
      scenarioIds: selectedScenarios,
      scenarioTransitions: selectedCells,
      location: selectedCoordinates ? selectedCoordinates.position : null,
      bearing: angle,
      sendToEveryone: sendToEveryone,
      includeAudienceIds: includeAudienceIds,
      excludeAudienceIds: excludeAudienceIds,
    };

    try {
      if (editMessage) {
        const docRef = doc(firestore, "messages", editMessage.id);
        await updateDoc(docRef, ivsData);
        console.log("Document updated with ID: ", editMessage.id);
      } else {
        ivsData.timestamp = serverTimestamp(); // Add createdAt only for new messages
        const docRef = await addDoc(collection(firestore, "messages"), ivsData);
        console.log("Document written with ID: ", docRef.id);
      }
      navigate("/schedule");
    } catch (e) {
      console.error("Error adding/updating document: ", e);
    }
  };

  return (
    <row>
      <row>
        <h1>{editMessage ? "Edit Message" : "New Message"}</h1>
        <row>
          <column>
            <label htmlFor="messageName">Message name</label>
            <input
              type="text"
              id="messageName"
              name="messageName"
              value={messageName}
              onChange={handleMessageNameChange}
            />
          </column>
          <column></column>
          <column></column>
        </row>
      </row>

      <double-column>
        <row>
          <row>
            <dashboard-container>
              <SelectAudience2
                sendToEveryone={sendToEveryone}
                onSendToEveryoneChange={handleSendToEveryoneChange}
                includeAudienceIds={includeAudienceIds}
                excludeAudienceIds={excludeAudienceIds}
                onIncludeAudienceChange={handleIncludeAudienceChange}
                onExcludeAudienceChange={handleExcludeAudienceChange}
              />
            </dashboard-container>
          </row>
          <row>
            <IVSMessage
              title={title}
              message={message}
              DropdownValue={dropdownValue}
              handleTitleChange={handleTitleChange}
              handleMessageChange={handleMessageChange}
              handleDropdownClick={handleDropdownClick}
            />
          </row>
          <row>
            <dashboard-container>
              <row>
                <column>
                  <h3>IVS criteria</h3>
                  <row>
                    <row>
                      <p>Is this a location based message?</p>
                    </row>
                    <row>
                      <button
                        className="button bluebutton"
                        onClick={handleOpenMapModal}
                        type="button"
                      >
                        Yes
                      </button>
                    </row>
                    <row>
                      {selectedCoordinates && (
                        <div className={styles.listItem}>
                          Coordinates:{" "}
                          {selectedCoordinates.position[0].toFixed(5)},{" "}
                          {selectedCoordinates.position[1].toFixed(5)}
                          Bearing: {angle.toFixed(2)}°
                          <CloseOutlinedIcon onClick={handleRemoveCoordinate} />
                        </div>
                      )}
                    </row>
                    <row>
                      <p>Do you want to add this message to a scenario?</p>
                    </row>
                    <row>
                      <button
                        className="button bluebutton"
                        onClick={handleOpenScenarioModal}
                        type="button"
                      >
                        Yes
                      </button>
                    </row>
                    <row>
                      <div>
                        {selectedScenarios.length > 0 &&
                          selectedScenarios.map((scenario, index) => (
                            <span key={index} className={styles.listItem}>
                              {scenario}
                              <CloseOutlinedIcon
                                onClick={() =>
                                  handleUpdateSelectedScenarios(
                                    selectedScenarios.filter(
                                      (s) => s !== scenario,
                                    ),
                                  )
                                }
                                className={styles.closeScenarioIcon}
                              />
                            </span>
                          ))}
                      </div>
                    </row>
                    <row>
                      <p>
                        Do you want to add this message to a scenario
                        transition?
                      </p>
                    </row>
                    <row>
                      <button
                        className="button bluebutton"
                        onClick={handleOpenMatrixModal}
                        type="button"
                      >
                        Yes
                      </button>
                    </row>
                    <div>
                      {selectedCells.length > 0 &&
                        selectedCells.map((cell, index) => (
                          <span key={index} className={styles.listItem}>
                            {cell}
                            <CloseOutlinedIcon
                              onClick={() =>
                                setSelectedCells(
                                  selectedCells.filter((c) => c !== cell),
                                )
                              }
                              className={styles.closeScenarioIcon}
                            />
                          </span>
                        ))}
                    </div>
                  </row>
                </column>
              </row>
            </dashboard-container>
          </row>
        </row>
        <bottom-row>
          <button
            id="openPopup"
            className="button bluebutton"
            type="button"
            onClick={submitForm}
          >
            {editMessage ? "Update" : "Save"}
          </button>
          <button
            className="button whitebutton"
            type="button"
            onClick={() => navigate("/schedule")}
          >
            Cancel
          </button>
        </bottom-row>
      </double-column>

      <sticky-column>
        <row>
          <dashboard-container>
            <column>
              <row>
                <row>
                  <div className={styles.ivsCard}>
                    <div className={styles.imageCircle}>
                      <row>
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            className={styles.ivsImage}
                            alt="Message type icon"
                          />
                        )}
                      </row>
                    </div>
                    <row>
                      <div className={styles.titleDisplay}>
                        {title || "Title"}
                      </div>
                    </row>
                    <row>
                      <div className={styles.messageDisplay}>
                        {message || "Message"}
                      </div>
                    </row>
                  </div>
                </row>
                <row>
                  <audio controls></audio>
                </row>
                <row>
                  <column>
                    <h3>Audience</h3>
                    <div>
                      Destination is Dover
                      <br />
                      And
                      <br />
                      Fuel type is petrol
                      <br />
                      Or
                      <br />
                      Language is French
                    </div>
                  </column>
                </row>
                <row>
                  <column>
                    <h3>Location</h3>
                    {selectedCoordinates && (
                      <div>
                        Coordinates:{" "}
                        {selectedCoordinates.position[0].toFixed(5)},{" "}
                        {selectedCoordinates.position[1].toFixed(5)}
                        Bearing: {angle.toFixed(2)}°
                      </div>
                    )}
                  </column>
                </row>
                <row>
                  <column>
                    <h3>Scenarios</h3>
                    <div>
                      {selectedScenarios.length > 0 &&
                        selectedScenarios.map((scenario, index) => (
                          <div key={index}>{scenario}</div>
                        ))}
                    </div>
                  </column>
                </row>
                <row>
                  <column>
                    <h3>Transitions</h3>
                    <div>
                      {selectedCells.length > 0 &&
                        selectedCells.map((cell, index) => (
                          <div key={index}>{cell}</div>
                        ))}
                    </div>
                  </column>
                </row>
                <row>
                  This is just here for reference to show how the slider moves
                  smoothly compared the map
                  <CircularSlider
                    initialAngle={angle}
                    onChange={handleAngleChange}
                  />
                </row>
              </row>
            </column>
          </dashboard-container>
        </row>
      </sticky-column>

      <IVSScenarioModal2
        isOpen={isScenarioModalOpen}
        onClose={handleCloseScenarioModal}
        selectedScenarios={selectedScenarios}
        onUpdateSelectedScenarios={handleUpdateSelectedScenarios}
      />

      <IVSMapModal2
        isOpen={isMapModalOpen}
        onClose={handleCloseMapModal}
        initialLocation={selectedCoordinates?.position}
        initialBearing={angle}
        onAngleChange={handleAngleChange}
      />

      <Matrix2
        isOpen={isMatrixModalOpen}
        onClose={handleCloseMatrixModal}
        callback={handleCellSelect}
        initialSelectedCells={selectedCells}
      />
    </row>
  );
}

export default IVS2;

