import React, { useState, useEffect } from 'react';
import styles from './scenario-road-blocks.module.css';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import LandslideOutlinedIcon from '@mui/icons-material/LandslideOutlined';
import FloodOutlinedIcon from '@mui/icons-material/FloodOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { RouteService } from '../../../api/route-service.js';
import { firestore } from '../../../firesbase.js';
import { collection, doc, deleteDoc, onSnapshot, where, query, addDoc } from 'firebase/firestore';
import { GlobalVariables } from '../../utils/global-variables.js';
import DropPin from './drop-pin.js';

function ScenarioRoadBlocks({ isOpen, setIsScenarioRoadBlocksOpen, accidentMarkers, activeMarker, setActiveMarker, openMarker }) {

    const editAccident = (accident) => {
        openMarker(accident);
    }

    const deleteMarker = async (marker) => {
        const accidentDocRef = doc(firestore, 'geopoints', marker.id);
        try {
            await deleteDoc(accidentDocRef);
            setActiveMarker(null); // Assuming this function is used to unset the active marker in the parent component
            console.log('Marker deleted successfully');
        } catch (error) {
            console.error('Error deleting marker:', error);
        }
    }

    if (!isOpen) return null;

    return (
        <div className={styles.roadBloxksListContainer}>
            <settings-row>
                <h2>Scenario Road Blocks</h2>
                <CloseOutlinedIcon onClick={() => setIsScenarioRoadBlocksOpen(false)} className={styles.closeIcon} />
            </settings-row>
            <ul className={styles.roadBlocksContainer}>
                {accidentMarkers.map((accident) => (
                    <li key={accident.id} 
                    className={`${styles.roadBlocksRow} ${activeMarker && activeMarker.id === accident.id ? styles.active : ''}`}
                    onClick={() => editAccident(accident)}>
                        <CarCrashOutlinedIcon className={styles.icon} />
                        <div className={styles.roadBlockContent}>
                            {accident.name}
                        </div>
                        <DeleteOutlineOutlinedIcon className={styles.icon} onClick={() => deleteMarker(accident)} />
                        {/* <EditOutlinedIcon className={styles.icon} /> */}
                    </li>

                ))}
            </ul>

        </div>
    );
}

export default ScenarioRoadBlocks;
