import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DirectionsIcon from '@mui/icons-material/Directions';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../../../firesbase.js';
import styles from './add-route-panel.module.css';
import { RouteService } from '../../../api/route-service.js';
import { GlobalVariables } from '../../utils/global-variables.js';
import { SiteConfig } from '../../../site-config.js';

function AddRoutePanel({ toggleAddingRoute, addingRoute, scenarioId, createRoute, routingWaypoints, setRoutingWaypoints, onRouteFinish, routingControlRef }) {
    const [savingRoute, setSavingRoute] = useState(false);
    const [routeStarted, setRouteStarted] = useState(false);

    const handleStartAddingRoute = () => {
        toggleAddingRoute();
        setRouteStarted(true);
    };

    const handleSaveRoute = async () => {
        const routingControl = routingControlRef.current;
        let waypoints = routingControl.getWaypoints();

        if (waypoints.length >= 2) {
            setSavingRoute(true);

            const routeService = new RouteService(SiteConfig.routeServerBaseUrl);
            const routePoints = routingControl.getRoutePoints();
            // routePoints.map(x => [x.lat, x.lng]);
            const edgeIds = await routeService.fetchEdgeIds(routePoints.map(x => [x.lng, x.lat]));

            try {
                const routesCollection = collection(firestore, 'routes');

                // const formattedWaypoints = waypoints.map((waypoint) => ({
                //     lat: waypoint.latLng.lat,
                //     lng: waypoint.latLng.lng,
                //   }));

                const formattedWaypoints = routePoints.map(x => ({ lat: x.lat, lng: x.lng }));

                const routeData = {
                    scenarioId: scenarioId,
                    waypoints: formattedWaypoints,
                    edgeIds: edgeIds
                    // Add any other relevant route data
                };

                await addDoc(routesCollection, routeData);
                console.log('Route saved successfully');
                setRouteStarted(false);
                onRouteFinish();
            } catch (error) {
                console.error('Error saving route:', error);
            } finally {
                setSavingRoute(false);
            }
        }
    };

    const handleCancelRoute = () => {
        setRouteStarted(false);
        onRouteFinish();
    };

    const isDisabled = scenarioId === null;
    const tooltipTitle = isDisabled ? "Please select a scenario to add a route" : "";
    const showSaveCancel = routeStarted;

    return (
        <div className={`${styles.addRoutePanelContainer}`}>
            <Tooltip title={tooltipTitle} arrow>
                <div>
                    <IconButton
                        style={{ fontSize: '16px' }}
                        disableRipple
                        className={styles.addRouteButton}
                        onClick={handleStartAddingRoute}
                        disabled={isDisabled || routeStarted}
                    >
                        {isDisabled ? (
                            <DirectionsIcon style={{ color: 'grey' }} />
                        ) : (
                            <DirectionsIcon style={{ color: '#024761' }} />
                        )}
                        {routeStarted ? "Adding Route" : "Add Route"}
                    </IconButton>
                </div>
            </Tooltip>
            {showSaveCancel && (
                <>
                    <IconButton
                        style={{ fontSize: '16px' }}
                        disableRipple
                        className={styles.addRouteButton}
                        onClick={handleSaveRoute}
                        disabled={savingRoute}
                    >
                        <SaveIcon style={{ color: '#024761' }} />
                        Save Route
                    </IconButton>
                    <IconButton
                        style={{ fontSize: '16px' }}
                        disableRipple
                        className={styles.addRouteButton}
                        onClick={handleCancelRoute}
                    >
                        <CancelIcon style={{ color: '#024761' }} />
                        Cancel
                    </IconButton>
                </>
            )}
        </div>
    );
}

export { AddRoutePanel };



// function AddRoutePanel({ toggleAddingRoute, addingRoute, scenarioId, routingWaypoints }) {
// //   const [routeMarkers, setRouteMarkers] = useState([]);
//   const [savingRoute, setSavingRoute] = useState(false);
//   const [routeStarted, setRouteStarted] = useState(false);
//   const isDisabled = scenarioId === null;
//   const tooltipTitle = isDisabled ? "Please select a scenario to add a route" : "";
//   const showSaveCancel = routeStarted && !addingRoute;

//   const handleSaveRoute = async () => {
//     if (routingWaypoints.length >= 2) {
//       setSavingRoute(true);

//       try {
//         const routesCollection = collection(firestore, 'routes');
//         const routeData = {
//           scenarioId: scenarioId,
//           waypoints: routingWaypoints,
//           // Add any other relevant route data
//         };

//         await addDoc(routesCollection, routeData);
//         console.log('Route saved successfully');
//         setRouteStarted(false);
//         toggleAddingRoute();
//       } catch (error) {
//         console.error('Error saving route:', error);
//       } finally {
//         setSavingRoute(false);
//       }
//     }
//   };

//   const handleCancelRoute = () => {
//     setRouteStarted(false);
//     toggleAddingRoute();
//   };

//   return (
//     // <div className={`${styles.addRoutePanelContainer}`}>
//     //   <Tooltip title={tooltipTitle} arrow>
//     //     <div>
//     //       <IconButton
//     //         style={{ fontSize: '16px' }}
//     //         disableRipple
//     //         className={styles.addRouteButton}
//     //         onClick={toggleAddingRoute}
//     //         disabled={isDisabled}
//     //       >
//     //         {isDisabled ? (
//     //           <DirectionsIcon style={{ color: 'grey' }} />
//     //         ) : (
//     //           <DirectionsIcon style={{ color: '#024761' }} />
//     //         )}
//     //         {addingRoute ? "Cancel" : "Add Route"}
//     //       </IconButton>
//     //     </div>
//     //   </Tooltip>
//     // </div>

//     <div className={`${styles.addRoutePanelContainer}`}>
//       <Tooltip title={tooltipTitle} arrow>
//         <div>
//           <IconButton
//             style={{ fontSize: '16px' }}
//             disableRipple
//             className={styles.addRouteButton}
//             onClick={toggleAddingRoute}
//             disabled={isDisabled}
//           >
//             {isDisabled ? (
//               <DirectionsIcon style={{ color: 'grey' }} />
//             ) : (
//               <DirectionsIcon style={{ color: '#024761' }} />
//             )}
//             {addingRoute ? "Cancel" : "Add Route"}
//           </IconButton>
//         </div>
//       </Tooltip>
//       {showSaveCancel && (
//         <>
//           <IconButton
//             style={{ fontSize: '16px' }}
//             disableRipple
//             className={styles.saveRouteButton}
//             onClick={handleSaveRoute}
//             disabled={savingRoute}
//           >
//             <SaveIcon style={{ color: '#024761' }} />
//             Save Route
//           </IconButton>
//           <IconButton
//             style={{ fontSize: '16px' }}
//             disableRipple
//             className={styles.cancelRouteButton}
//             onClick={handleCancelRoute}
//           >
//             <CancelIcon style={{ color: '#024761' }} />
//             Cancel
//           </IconButton>
//         </>
//       )}
//     </div>
//   );
// }

// export { AddRoutePanel };