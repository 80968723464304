import React, { useState, useEffect } from "react";
import styles from "./manageaudiences.module.css";
import { Link } from "react-router-dom";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CreateAudienceModal from "../../audience/createaudience/createaudiencepopup.js";
import {
  dbGetAllAudiences,
  dbGetAudienceCountByMessageType,
} from "../../../utils/db";

function ManageAudiences() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    getAudiences();
  }, []);

  const getAudiences = async () => {
    let newAudiences = await dbGetAllAudiences();
    newAudiences = await Promise.all(
      newAudiences.map(async (current) => ({
        ...current,
        counts: {
          ivs: await dbGetAudienceCountByMessageType(current.id, "ivs"),
          inapp: await dbGetAudienceCountByMessageType(current.id, "inapp"),
          push: await dbGetAudienceCountByMessageType(current.id, "push"),
        },
      })),
    );
    setAudiences(newAudiences);
  };

  return (
    <body>
      <row>
        <row>
          <h1>Manage audiences</h1>
        </row>
        <double-column>
          <dashboard-container>
            <row>
              <column>
                <label for="truckstopName">Search audiences</label>
                <input type="text" placeholder="Search by name or keyword" />
              </column>
              <column></column>
              <column></column>
            </row>

            <table
              id="events"
              className="events-table"
              cellSpacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th className="type">Name</th>
                  <th className="name">Status</th>
                  <th className="date">Push</th>
                  <th className="date">In-app</th>
                  <th className="date">SMS</th>
                  <th className="date">IVS</th>
                  <th className="date">Actions</th>
                </tr>
              </thead>
              <tbody>
                {audiences.map(({ name, counts }, index) => (
                  <tr>
                    <td>
                      <row>{name}</row>
                    </td>
                    <td>Active</td>

                    <td>{counts.push}</td>
                    <td>{counts.inapp}</td>
                    <td>0</td>
                    <td>{counts.ivs}</td>

                    <td className="actions">
                      <EditOutlinedIcon
                        onClick={() => {
                          setIsModalOpen(true);
                          setSelectedIndex(index);
                        }}
                      />
                      <DeleteOutlineOutlinedIcon />
                      <ArchiveOutlinedIcon />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </dashboard-container>
        </double-column>
      </row>

      {isModalOpen && (
        <CreateAudienceModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          existingAudience={(() => {
            // 🤮
            const {
              name: _,
              counts: __,
              id,
              ...constraints
            } = audiences[selectedIndex];
            console.log({ constraints, id });
            return { constraints, id };
          })()}
        ></CreateAudienceModal>
      )}
    </body>
  );
}

export default ManageAudiences;

