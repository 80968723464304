import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import Reports from '../reports/reports.js';
import Live from '../live/live.js';
import Sidebar from '../sidebar/sidebar.js';
import Dashboard from '../dashboard/dashboard.js';
import Login from '../login/login.js'; // Ensure this component is correctly imported
import { PrivateRoute } from '../private-route/private-route.js';
import TAS from '../tas/tas.js';
import TestingTAS from '../tas/testing-tas.js';
import TopNavBar from '../top-navbar/top-navbar.js';
import Admin from '../admin/admin.js';
import TruckStopsList from '../truckstops/truckstops.js';
import Schedule from '../schedule/schedule.js';
import InAppMessages from '../messages/inappmessages/inappmessages.js';
import SMSMessages from '../messages/smsmessages/smsmessages.js';
import IVS from '../messages/ivs/ivs.js';
import ManageAudiences from '../messages/audience/manageaudiences/manageaudiences.js';
import PushMessages from '../messages/pushmessages/pushmessages.js';
import NewTruckStop from '../newtruckstop/newtruckstop.js';
import Settings from '../settings/settings.js';
import IVS2 from '../messages/ivs2/ivs2.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateAudience from '../messages/audience/createaudience/createaudiencepopup.js';

function PortsRoutes({ currentUser = currentUser, siteConfig = siteConfig }) {

  let menuConfig = siteConfig.currentPreset.menuConfig;

  return (
    <Routes>

      <Route path="/login" element={currentUser ? (
        <Navigate to="/" replace />
      ) : (
        <Login />
      )
      } />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Dashboard />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />



      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Dashboard />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/testing-tas"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">

                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>

                  <TestingTAS siteConfig={siteConfig} />

                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/tas"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">

                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>

                  <TAS siteConfig={siteConfig} />

                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/live"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">

                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <Live />
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/admin"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Admin />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Reports />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/truckstops"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <TruckStopsList />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/newtruckstop"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <NewTruckStop />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/schedule"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Schedule />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/inappmessages"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <InAppMessages />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />




      <Route
        path="/ivs"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <IVS />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />



      <Route
        path="/smsmessages"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <SMSMessages />
                  </div>
                </div>

              </div>
            </div>
          </PrivateRoute>
        } />

      <Route
        path="/pushmessages"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <PushMessages />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />




      <Route
        path="/manageaudiences"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <ManageAudiences />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />


      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <Settings />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />


<Route
        path="/ivs2"
        element={
          <PrivateRoute>
            <div className="App">
              <div class="wrapper">
                <div class="section">
                  <TopNavBar />
                  <div class="sidebar">
                    <Sidebar menuConfig={menuConfig} currentUser={currentUser} />
                  </div>
                  <div class="container">
                    <IVS2 />
                  </div>
                </div>
              </div>
            </div>
          </PrivateRoute>
        } />

    </Routes>

    




  );
}

export default PortsRoutes;